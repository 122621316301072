import axiosInstance from "./axios-instance";

const searchDraperyOrderDesignEmbellishments = async (
    draperyOrderDesignId: number
) => {
    try {
        const response = await axiosInstance.get(
            `/draperyOrderDesignEmbellishment/search?draperyOrderDesignId=${draperyOrderDesignId}`
        );

        return response.data;
    } catch (error) {
        console.error(
            "Error with drapery Order Design Embellishment search:",
            error
        );
        throw error;
    }
};

const addDraperyOrderDesingEmbellishment = async (payload: any) => {
    try {
        const response = await axiosInstance.post(
            `/draperyOrderDesignEmbellishment/add`,
            payload
        );

        return response.data;
    } catch (error) {
        console.error(
            "Error with drapery Order Design Embellishment add:",
            error
        );
        throw error;
    }
};

const editDraperyOrderDesignEmbellishment = async (payload: any) => {
    try {
        const response = await axiosInstance.put(
            `/draperyOrderDesignEmbellishment/edit`,
            payload
        );

        return response.data;
    } catch (error) {
        console.error(
            "Error with drapery Order Design Embellishment edit:",
            error
        );
        throw error;
    }
};

const deleteDraperyOrderDesignEmbellishment = async (id: number) => {
    try {
        const response = await axiosInstance.delete(
            `/draperyOrderDesignEmbellishment/delete?id=${id}`
        );

        return response.data;
    } catch (error) {
        console.error(
            "Error with drapery Order Design Embellishment delete:",
            error
        );
        throw error;
    }
};

export {
    searchDraperyOrderDesignEmbellishments,
    addDraperyOrderDesingEmbellishment,
    editDraperyOrderDesignEmbellishment,
    deleteDraperyOrderDesignEmbellishment,
};
