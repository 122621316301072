import React, { FC } from "react";

interface DetailsPreviewProps {
    title: string;
}

const DetailsPreview: FC<DetailsPreviewProps> = ({ title }) => {
    const labelClassName = "font-bold mr-2";
    const previewRow = "mb-1";
    
    const getTitle = (title: string) => {
        return `${title.replace("Description", "")}Preview`;
    };

    return (
        <div className="w-full lg:w-1/2 mr-5 flex flex-col items-center">
            <span>{getTitle(title)}</span>
            <div className="mt-2 border max-w-[500px]">
                <div className="flex border-b bg-twBackDropColor">
                    <div className="p-2 flex flex-col justify-center">
                        ITEM DESCRIPTION
                    </div>
                </div>
                {/* <div className="flex border-b">
                    <div className="p-2 flex flex-col justify-center text-twBlue">
                        MASTER BEDROOM
                    </div>
                </div> */}
                <div className="p-2">
                    <div className={previewRow}>
                        <span className={labelClassName}>LIVING ROOM /
                        WINDOW
                        A</span>
                    </div>
                    <div className={previewRow}>
                        <span>
                            Functional / Flat / 40"W x 60"L
                        </span>
                    </div>
                    <div className={previewRow}>
                        <span>
                            Inside Mount / Waterfall / Back Valance
                        </span>
                    </div>
                    <div className={previewRow}>
                        <span>Front Valance / Returns / Bottom Skirt</span>
                    </div>
                    <div className={previewRow}>
                        <span >Lining: </span> Standard Ivory
                    </div>
                    <div className={previewRow}>
                        <span >Interlining: </span>
                        None
                    </div>
                    <div className={previewRow}>
                        <span >Embellishments: </span>
                        Trim (Pre-made) on sides and bottom edge of 
                        <span>Shade and sides and bottom edge of Valance.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsPreview;
