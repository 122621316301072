import axiosInstance from "./axios-instance";

const addDraperyOrderHardware = async (payload: any) => {
  try {
    const response = await axiosInstance.post<any>('draperyOrderHardware/new', payload)

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
const editDraperyOrderHardware = async (payload: any) => {
  try {
    const response = await axiosInstance.post<any>('draperyOrderHardware/edit', payload)

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export {
  addDraperyOrderHardware,
  editDraperyOrderHardware,
}