import {
    ModalContent,
    TextArea,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import { TabPanel, Tabs } from "react-tabs";
import styled, { theme } from "../../theme";

import HelpPopup from "../content/help_popup";

export const ItemModalContent = styled(ModalContent)`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: inherit;

    .react-tabs__tab-list {
        border-bottom: 6px solid ${theme.colors.blue};
        margin-bottom: 0;
    }

    .react-tabs__tab {
        margin-right: 10px;
        border-radius: 5px 5px 0 0;
        border: 1px solid ${theme.colors.gray1};
        border-bottom: none;
        bottom: 0;
        background-color: white;
        color: ${theme.colors.grayRGBA};

        &:last-child {
            margin-right: 0;
        }
    }

    .react-tabs__tab-panel {
        flex: 1;
    }

    .react-tabs__tab--selected {
        background-color: ${theme.colors.blue};
        color: ${theme.colors.white};
        border-color: transparent;

        &:focus {
            outline: none;
        }
    }

    .react-tabs__tab-panel--selected {
        margin-top: 0;
        display: flex;
        flex-direction: column;
    }

    .react-tabs__tab:focus {
        box-shadow: none;
    }

    .react-tabs__tab:focus::after {
        position: relative;
    }
`;

export const ItemTabs = styled(Tabs)`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: inherit;
`;

export const TabContainer = styled(TabPanel).attrs({
    className: "react-tabs__tab-panel",
})`
    height: 600px;
    overflow: auto;
    flex: 1 auto !important;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
`;

export const FormLabel = styled.label`
    color: ${theme.colors.grayRGBA};
    margin: 0;
    padding: 0;

    .red-asterisk {
        color: ${theme.colors.red};
    }

    .category-required {
        position: absolute;
        inset: 203.75px auto auto 128.516px;
        visibility: visible;
        display: block;
        font-weight: 400;
        font-style: normal;
        font-size: 0.85rem;
        min-width: -webkit-min-content;
        min-width: -moz-min-content;
        min-width: min-content;
        z-index: 2000;
        border: 1px solid #3B97B166;
        line-height: 1.4;
        max-width: 250px;
        padding: 0.83em 1em;
        border-radius: 0.28rem;
        background: #fff;
        color: #1B1C1D;
        will-change: top, left, right, bottom, transform, opacity;
        box-shadow: 0 2px 4px 0 rgba(59, 151, 177, 0.12), 0 2px 10px 0 rgba(59, 151, 177, 0.15);
        margin: 0 0 0.71em;
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
    .name-required {
        position: absolute;
        inset: 273.75px auto auto 128.516px;
        visibility: visible;
        display: block;
        font-weight: 400;
        font-style: normal;
        font-size: 0.85rem;
        min-width: -webkit-min-content;
        min-width: -moz-min-content;
        min-width: min-content;
        z-index: 2000;
        border: 1px solid #3B97B166;
        line-height: 1.4;
        max-width: 250px;
        padding: 0.83em 1em;
        border-radius: 0.28rem;
        background: #fff;
        color: #1B1C1D;
        will-change: top, left, right, bottom, transform, opacity;
        box-shadow: 0 2px 4px 0 rgba(59, 151, 177, 0.12), 0 2px 10px 0 rgba(59, 151, 177, 0.15);
        margin: 0 0 0.71em;
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
    }
`;

export const FormInput = styled(ValidationPopupInput)`
    margin: 0;
    padding: 0;
    min-width: 90px !important;
    &.error-pleatType {
        border: 1px solid hsl(0, 71.3%, 76.2%); 
        background-color: #fcf0f0; 
    }
    &.required{
        border: 1px solid hsl(0, 71.3%, 76.2%);
        background-color: #fcf0f0;
    }
    &.notRequired{
        background-color: #fff;
        color: #1B1C1DEE;
        border: 1px solid rgba(59, 151, 177, 0.15)
    }
    input::placeholder {
        color: #C2C2C2;
    }
`;

export const RadioLabel = styled.label`
    display: inline-flex;
    align-items: center;
    margin-right: 10px !important;

    > label:hover,
    &:hover {
        cursor: pointer;
    }
`;

export const RadioInput = styled(ValidationPopupInput)`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 0;
    margin-right: 5px;
    border: 1px solid ${theme.colors.grayRGBA};
    border: ${(props) =>
        props.checked ? `5px solid ${theme.colors.blue}` : ""};

    input {
        visibility: hidden;
    }
`;

export const TextAreaContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    span {
        margin-top: 5px;
        margin-right: 10px;
    }

    ${TextArea} {
        width: 100%;
        border: solid ${theme.colors.primary} 1px;
        height: 120px;
    }
`;

export const RightCell = styled.div`
    width: 50%;
    margin-bottom: 20px;
    padding-right: 1.25rem;
    padding-right: 0;
    padding-left: 1.25rem;
`;

export const RightCellWithBorder = styled(RightCell)`
    border-left: 1px solid ${theme.colors.blue};
`;

export const SectionTitle = styled.span`
    color: ${theme.colors.primary};
    font-weight: 600;
`;

export const TooltipPopup = styled(HelpPopup)`
    max-width: 300px;
    max-height: 50%;
    display: flex !important;

    > svg {
        color: ${theme.colors.red1};
    }
`;

export const TooltipWidePopup = styled(HelpPopup)`
max-width: ${(props) =>
    props.className && props.className.includes("wide") ? "500px" : "400px"};
    max-height: 50%;
    display: flex !important;

    > svg {
        color: ${theme.colors.red1};
    }
`;

export const TooltipExtraWidePopup = styled(TooltipWidePopup)`
    max-width: 400px;
`;

export const TooltipContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 25px);
`;

export const TooltipHeader = styled.div`
    margin: 10px 0;
    font-weight: 600;
`;

export const TooltipText = styled.div`
    margin: 10px 0;
`;

export const TooltipImgContainer = styled.div`
    background: white;
    border: 1px solid ${theme.colors.grayText};
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    max-width: 98%;
    object-fit: contain;
    width: fit-content;
    height: fit-content;

    > img {
        max-height: 100%;
        max-width: 100%;
    }
`;

export const TooltipSvgContainer = styled(TooltipImgContainer)`
    min-width: 300px;
    min-height: 300px;
    width: unset;
`;

export const TooltipRow = styled.div`
    display: flex;
    flex-direction: row;
`;

export const TooltipCell = styled.div`
    flex: 1;

    &:first-child {
        margin-right: 5px;
    }

    &:last-child {
        margin-left: 5px;
    }
`;

export const SubFormHeader = styled.div`
    margin-bottom: 15px;
    width: 80%;
    padding: 10px 0;
    border-bottom: 1px solid ${theme.colors.blackText};
    font-size: 16px;
    font-weight: 600;
    display: flex;
`;