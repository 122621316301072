import axiosInstance from "./axios-instance";
const addDraperyOrderSummaryComponent = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>(
            "/draperyOrderSummaryComponent/add",
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error add drapery order summary component:", error);
        throw error;
    }
};
const editDraperyOrderSummaryComponent = async (payload: any) => {
    try {
        const response = await axiosInstance.put<any>(
            "/draperyOrderSummaryComponent/edit",
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error edit drapery order summary component:", error);
        throw error;
    }
};
const searchDraperyOrderSummaryComponents = async (
    draperyOrderSummaryId: number
) => {
    try {
        const response = await axiosInstance.get<any>(
            `/draperyOrderSummaryComponent/search?draperyOrderSummaryId=${draperyOrderSummaryId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error search drapery order summary component:", error);
        throw error;
    }
};
export {
    addDraperyOrderSummaryComponent,
    editDraperyOrderSummaryComponent,
    searchDraperyOrderSummaryComponents,
};