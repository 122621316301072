import { Api } from "@quipa/api";
import { actionReducer } from "../../utils";
import { defaults, State } from "./model";

export default actionReducer<any, State>(defaults(), {
    [Api.LOGIN]: {
        success: (draft:any) => {
            draft.loaded = true;
        },
    }
});
