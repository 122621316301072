// tslint:disable-next-line:no-reference
// / <reference path="./global.d.ts" />
// import { ApplicationModule, UserModule } from "./redux";
import { createHistory, createStorage } from "./utils";
import { render, setLocale } from "./render";
import configureStore from "./redux/configure_store";
import { setDeployOptions } from "./utils/environment";

setLocale(window.navigator.language);

setDeployOptions((window as any).__DEPLOY_OPTIONS);

const browserHistory = createHistory("browser");
const { engine, loader } = createStorage();

async function load() {
    const store = configureStore(browserHistory, engine);

    const rootEl = document.getElementById("quipa-portal");

    await loader(store);
    render(rootEl, browserHistory, store);
}

load();
