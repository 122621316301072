import axiosInstance from "./axios-instance";
const editDraperyOrderDesign = async (payload: any) => {
    try {
        const response = await axiosInstance.put(
            `/draperyOrderDesign/edit`,
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error edit drapery order design:", error);
        throw error;
    }
};
const addDraperyOrderDesign = async (payload: any) => {
    try {
        const response = await axiosInstance.post(
            `/draperyOrderDesign/add`,
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error add drapery order design:", error);
        throw error;
    }
};
export { editDraperyOrderDesign, addDraperyOrderDesign };
