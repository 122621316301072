import axiosInstance from "./axios-instance";
const addDraperyOrderSummary = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>(
            "/draperyOrderSummary/add",
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error add drapery order summary:", error);
        throw error;
    }
};
const editDraperyOrderSummary = async (payload: any) => {
    try {
        const response = await axiosInstance.put<any>(
            "/draperyOrderSummary/edit",
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error edit drapery order summary:", error);
        throw error;
    }
};
export { addDraperyOrderSummary, editDraperyOrderSummary };