import { SignIn } from "../interfaces/sign-in";

import axiosInstance from "./axios-instance";

const loginHandle = async (payload: SignIn) => {
    try {
        const response = await axiosInstance.post<any>("/auth/login", payload);

        return response.data;
    } catch (error) {
        console.error("Error login:", error);
        throw error;
    }
};

export { loginHandle };
