import { Middleware } from "redux";

import { NotificationsModule, RootState } from "../modules";

export const NOTIFICATION_TIMEOUT = 5000;

/**
* Unique notification id
* @type {number}
*/
let nextNotificationId = 0;

const middleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
    if (typeof action === "function") {
        return next(action);
    }

    const notificationDefinition: NotificationsModule.AddNotificationPayload | undefined =
        action.type === NotificationsModule.ADD_NOTIFICATION
            ? // Explicitly adding notification with action creator
              (action.payload as NotificationsModule.AddNotificationPayload)
            : // Non explicitly notification coming from action, for example from realtime action
              // Look if we should display notification
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              undefined;

    if (notificationDefinition) {
        const notificationId = nextNotificationId++;
        const newPayload: NotificationsModule.AddNotificationInternalPayload = {
            id: notificationId,
            message: notificationDefinition.message,
            subMessage: notificationDefinition.subMessage,
            type: notificationDefinition.type,
        };
        store.dispatch(NotificationsModule.addNotificationInternal(newPayload));
        if (notificationDefinition.autoDismissable || typeof notificationDefinition.autoDismissable === "undefined") {
            setTimeout(
                () => store.dispatch(NotificationsModule.removeNotification(notificationId)),
                NOTIFICATION_TIMEOUT,
            );
        }
    }
    if (action.type !== NotificationsModule.ADD_NOTIFICATION) {
        return next(action);
    }
};

export default middleware;
