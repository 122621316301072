import {
    FormFields,
    FormFieldsAction,
    FormFieldsPartial,
} from "../../redux/modules/my-shop/form-fields-reducer";
import React, { Dispatch, FC, useState } from "react";
import Avatar from "../UI/avatar";
import { AvatarModal } from "../../modals/avatar_modal";
import { RightCellWithBorder } from "./styles";
import { Config } from "../../utils";
import { uploadImage } from "../../api/account";

interface ItemAvatarProps {
    formFields: FormFields;
    dispatch: Dispatch<FormFieldsAction>;
}

const ItemAvatar: FC<ItemAvatarProps> = ({ formFields, dispatch }) => {
    const accountId = parseFloat(localStorage.getItem("accountId") || "0");
    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

    const openAvatarModal = () => {
        setIsImageModalOpen(true);
    };

    const closeAvatarModal = () => {
        setIsImageModalOpen(false);
    };

    const deleteAvatarImage = () => {
        const formFieldUpdates: FormFieldsPartial = {
            avatarId: null,
        };
        dispatch({
            type: "updateFormFields",
            formFieldUpdates,
        });
    };

    const saveAvatarImage = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("accountId", accountId.toString());
        formData.append("fileStoreUrl", Config.fileStoreUrl);
        const res = await uploadImage(formData);
        if (res.error) {
            throw new Error(res.message);
        }
        dispatch({
            type: "updateFormFields",
            formFieldUpdates: { avatarId: res.url },
        });

        setIsImageModalOpen(false);
    };

    return (
        <RightCellWithBorder>
            <Avatar
                avatarId={formFields.avatarId}
                openAvatarModal={openAvatarModal}
                deleteAvatarImage={deleteAvatarImage}
                firstName={formFields.name}
                lastName=""
                isDefaultImgMountain={true}
                isSquareImgHolder={true}
            />
            <AvatarModal
                uiActive={isImageModalOpen}
                uiOnRequestClose={closeAvatarModal}
                onSave={saveAvatarImage}
            />
        </RightCellWithBorder>
    );
};

export default ItemAvatar;
