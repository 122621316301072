import React, { ChangeEvent } from "react";
import styled from "../../theme";
import { CalculatorRadioGroup, RadioInput, RadioLabel } from "./styles";
import { DraperyModule } from "../../redux";
import TrashCan from "../../assets/icons/trash.svg";

export const defaultDraperyOrderHardware =
    (): DraperyModule.DraperyOrderHardware => {
        return {
            id: 0,
            purchaser: 0,
            supplier: "",
            notes: "",
            rods: [
                {
                    newItem: false,
                    item: "Rods",
                    quantity: "",
                    description: "",
                    finish: "",
                },
            ],
            otherItems: [
                {
                    newItem: false,
                    item: "Rings",
                    quantity: "",
                    description: "",
                    finish: "",
                },
                {
                    newItem: false,
                    item: "End brackets",
                    quantity: "",
                    description: "",
                    finish: "",
                },
                {
                    newItem: false,
                    item: "Center brackets",
                    quantity: "",
                    description: "",
                    finish: "",
                },
                {
                    newItem: false,
                    item: "Finials",
                    quantity: "",
                    description: "",
                    finish: "",
                },
                {
                    newItem: false,
                    item: "Endcaps",
                    quantity: "",
                    description: "",
                    finish: "",
                },
                {
                    newItem: false,
                    item: "Elbows",
                    quantity: "",
                    description: "",
                    finish: "",
                },
                {
                    newItem: false,
                    item: "Wands",
                    quantity: "",
                    description: "",
                    finish: "",
                },
            ],
        };
    };

export const duplicateDraperyOrderHardware = (draperyOrderHardware: any) => {
    return {
        ...draperyOrderHardware,
    };
};

type HardwareProps = {
    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    draperyOrderHardware: DraperyModule.DraperyOrderHardware;
    handleRemovePurchaser(key: string, value: number): void;
    updateHardwareState(e: any, newValue?: boolean, id?: number): void;
    disabled: boolean;
    handleDeleteTableRow(index: number, arrayName: string): void;
};

const HardwareContainer = styled.div`
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 48px;
`;

const HardwareHeader = styled.div`
    display: flex;
    justiy-content: space-between;
    gap: 38px;
    flex-wrap: wrap;
`;

const HardwareHeaderColumn = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex: 1;
    min-width: 375px;
    flex-direction: column;
`;

const HardwareHeaderRow = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    & .label {
        min-width: 120px;

        > span {
            font-weight: 500;
        }
    }

    & .textarea {
        flex: 1;

        > textarea {
            display: flex;
            width: 100%;
            height: 100%;
            outline: none;
            border: 1px solid rgba(35, 35, 35, 0.15);
            border-radius: 8px;
            padding: 10px 8px;
            resize: none;

            &:focus {
                border-color: ${({ theme }) => theme.colors.primary};
            }
        }

        &.disabled {
            > textarea {
                opacity: 0.45;
                background-color: white;
            }
        }
    }
`;
const HardwareTable = styled.table`
    width: 100%;
`;
const HardwareTableHead = styled.thead`
    border-bottom: 1px solid #e2e2e2;

    & tr {
        & th {
            text-align: left;
            padding: 0px 24px 24px 0px;
        }

        & > :nth-child(1) {
            width: 150px;
        }

        & > :nth-child(2) {
            text-align: center;
            width: 124px;
        }
    }
`;

const HardwareTableBody = styled.tbody`
    & tr {
        & td {
            text-align: left;
            padding: 12px 24px 12px 0px;

            & input {
                border: 1px solid rgba(35, 35, 35, 0.15);
                border-radius: 8px;
                height: 100%;
                width: 100%;
                outline: none;
                padding: 0px 10px;
                height: 43px;

                &:focus {
                    border-color: ${({ theme }) => theme.colors.primary};
                }

                &:disabled {
                    background: white;
                    opacity: 0.45;
                }
            }
        }

        & > :nth-child(1) {
            width: 150px;
            font-size: 14px;
        }

        & > :nth-child(2) {
            width: 124px;
        }
    }

    & > :nth-child(1) {
        & td {
            padding: 24px 24px 12px 0px;
        }
    }

    & .add_rod_btn {
        height: 40px;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 500;
        font-size: 16px;

        &.disabled {
            color: #bebebe;
        }
    }
`;

const HardwareTab = (props: HardwareProps): JSX.Element => {
    const {
        handleInputChangeForNumber,
        draperyOrderHardware,
        handleRemovePurchaser,
        updateHardwareState,
        disabled,
        handleDeleteTableRow,
    } = props;

    const handleEnterPressed = (
        e: React.KeyboardEvent<HTMLTextAreaElement>
    ): void => {
        const key = e.key;
        if (key === "Enter" || key === "Return") {
            e.preventDefault();

            const textarea = e.currentTarget;
            const value = textarea.value;
            const selectionStart = textarea.selectionStart;
            const selectionEnd = textarea.selectionEnd;

            const part1 = value.slice(0, selectionStart);
            const part2 = value.slice(selectionEnd);

            const newValue = part1 + "\n" + part2;

            textarea.value = newValue;
            textarea.selectionStart = textarea.selectionEnd =
                selectionStart + 1;
        }
    };

    return (
        <HardwareContainer>
            <HardwareHeader>
                <HardwareHeaderColumn>
                    <HardwareHeaderRow>
                        <div className="label">
                            <span style={{ fontSize: "16px" }}>Purchaser:</span>
                        </div>

                        <CalculatorRadioGroup style={{ paddingLeft: "0px" }}>
                            <RadioLabel
                                className={`hardware_purchaser ${
                                    draperyOrderHardware.purchaser === 1
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <RadioInput
                                    type="radio"
                                    id="hardware-workroom"
                                    name="draperyOrderHardware.purchaser"
                                    value={1}
                                    checked={
                                        draperyOrderHardware.purchaser === 1
                                    }
                                    onClick={() =>
                                        handleRemovePurchaser(
                                            "draperyOrderHardware",
                                            1
                                        )
                                    }
                                    onChange={handleInputChangeForNumber}
                                    disabled={disabled}
                                    uiDisabled={disabled}
                                />
                                Workroom
                            </RadioLabel>

                            <RadioLabel
                                className={`hardware_purchaser ${
                                    draperyOrderHardware.purchaser === 2
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <RadioInput
                                    type="radio"
                                    id="hardware-client"
                                    name="draperyOrderHardware.purchaser"
                                    value={2}
                                    checked={
                                        draperyOrderHardware.purchaser === 2
                                    }
                                    onClick={() =>
                                        handleRemovePurchaser(
                                            "draperyOrderHardware",
                                            2
                                        )
                                    }
                                    onChange={handleInputChangeForNumber}
                                    disabled={disabled}
                                    uiDisabled={disabled}
                                />
                                Client (COM)
                            </RadioLabel>
                        </CalculatorRadioGroup>
                    </HardwareHeaderRow>

                    <HardwareHeaderRow>
                        <div className="label">
                            <span>Supplier</span>
                        </div>

                        <div
                            className={`textarea ${disabled ? "disabled" : ""}`}
                        >
                            <textarea
                                name="draperyOrderHardware.supplier"
                                value={draperyOrderHardware.supplier}
                                onChange={(e) => updateHardwareState(e)}
                                onKeyDown={handleEnterPressed}
                                disabled={disabled}
                            />
                        </div>
                    </HardwareHeaderRow>
                </HardwareHeaderColumn>

                <HardwareHeaderColumn>
                    <HardwareHeaderRow>
                        <div className="label">
                            <span>Hardware notes</span>
                        </div>
                    </HardwareHeaderRow>

                    <HardwareHeaderRow>
                        <div
                            className={`textarea ${disabled ? "disabled" : ""}`}
                        >
                            <textarea
                                name="draperyOrderHardware.notes"
                                value={draperyOrderHardware.notes}
                                onChange={(e) => updateHardwareState(e)}
                                onKeyDown={handleEnterPressed}
                                rows={4}
                                disabled={disabled}
                            />
                        </div>
                    </HardwareHeaderRow>
                </HardwareHeaderColumn>
            </HardwareHeader>

            <HardwareTable>
                <HardwareTableHead>
                    <tr>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Description</th>
                        <th>Finish</th>
                        <th></th>
                    </tr>
                </HardwareTableHead>

                <HardwareTableBody>
                    {draperyOrderHardware.rods &&
                        draperyOrderHardware.rods.length > 0 &&
                        draperyOrderHardware.rods.map((rod, index) => {
                            return (
                                <tr key={index}>
                                    <td>{rod.item}</td>
                                    <td>
                                        <input
                                            name="draperyOrderHardware.rods.quantity"
                                            value={rod.quantity}
                                            // style={{ textAlign: "center" }}
                                            type="text"
                                            onChange={(e) =>
                                                updateHardwareState(
                                                    e,
                                                    false,
                                                    index
                                                )
                                            }
                                            disabled={disabled}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            name="draperyOrderHardware.rods.description"
                                            value={rod.description}
                                            type="text"
                                            onChange={(e) =>
                                                updateHardwareState(
                                                    e,
                                                    false,
                                                    index
                                                )
                                            }
                                            disabled={disabled}
                                        />
                                    </td>
                                    <td style={{ paddingRight: "0" }}>
                                        <input
                                            name="draperyOrderHardware.rods.finish"
                                            value={rod.finish}
                                            type="text"
                                            onChange={(e) =>
                                                updateHardwareState(
                                                    e,
                                                    false,
                                                    index
                                                )
                                            }
                                            disabled={disabled}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            padding: "0",
                                            textAlign: "center",
                                            width: "50px",
                                        }}
                                    >
                                        {index > 0 && (
                                            <button
                                                style={{
                                                    opacity: disabled
                                                        ? ".4"
                                                        : "1",
                                                }}
                                                disabled={disabled}
                                                type="button"
                                                onClick={() =>
                                                    handleDeleteTableRow(
                                                        index,
                                                        "rods"
                                                    )
                                                }
                                            >
                                                <TrashCan
                                                    style={{
                                                        width: "25px",
                                                        height: "25px",
                                                    }}
                                                />
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}

                    <tr>
                        <td style={{ textAlign: "end" }} colSpan={4}>
                            <button
                                name="draperyOrderHardware.rods"
                                className={`add_rod_btn ${
                                    disabled ? "disabled" : ""
                                }`}
                                type="button"
                                onClick={(e) => updateHardwareState(e, true)}
                                disabled={disabled}
                            >
                                + Add new rod
                            </button>
                        </td>
                    </tr>

                    {draperyOrderHardware.otherItems &&
                        draperyOrderHardware.otherItems.length > 0 &&
                        draperyOrderHardware.otherItems.map((other, index) => {
                            return (
                                <tr key={index}>
                                    {other.newItem ? (
                                        <td>
                                            <input
                                                name="draperyOrderHardware.otherItems.item"
                                                value={other.item}
                                                onChange={(e) =>
                                                    updateHardwareState(
                                                        e,
                                                        false,
                                                        index
                                                    )
                                                }
                                                disabled={disabled}
                                                type="text"
                                            />
                                        </td>
                                    ) : (
                                        <td>{other.item}</td>
                                    )}
                                    <td>
                                        <input
                                            name="draperyOrderHardware.otherItems.quantity"
                                            value={other.quantity}
                                            onChange={(e) =>
                                                updateHardwareState(
                                                    e,
                                                    false,
                                                    index
                                                )
                                            }
                                            disabled={disabled}
                                            type='text' />
                                    </td>
                                    <td>
                                        <input
                                            name="draperyOrderHardware.otherItems.description"
                                            value={other.description}
                                            onChange={(e) =>
                                                updateHardwareState(
                                                    e,
                                                    false,
                                                    index
                                                )
                                            }
                                            disabled={disabled}
                                            type="text"
                                        />
                                    </td>
                                    <td style={{ paddingRight: "0" }}>
                                        <input
                                            name="draperyOrderHardware.otherItems.finish"
                                            value={other.finish}
                                            onChange={(e) =>
                                                updateHardwareState(
                                                    e,
                                                    false,
                                                    index
                                                )
                                            }
                                            disabled={disabled}
                                            type="text"
                                        />
                                    </td>
                                    <td
                                        style={{
                                            padding: "0",
                                            textAlign: "center",
                                            width: "50px",
                                        }}
                                    >
                                        {index > 6 && (
                                            <button
                                                style={{
                                                    opacity: disabled
                                                        ? ".4"
                                                        : "1",
                                                }}
                                                disabled={disabled}
                                                type="button"
                                                onClick={() =>
                                                    handleDeleteTableRow(
                                                        index,
                                                        "otherItems"
                                                    )
                                                }
                                            >
                                                <TrashCan
                                                    style={{
                                                        width: "25px",
                                                        height: "25px",
                                                    }}
                                                />
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}

                    <tr>
                        <td style={{ textAlign: "end" }} colSpan={4}>
                            <button
                                className={`add_rod_btn ${
                                    disabled ? "disabled" : ""
                                }`}
                                type="button"
                                name="draperyOrderHardware.otherItems"
                                onClick={(e) => updateHardwareState(e, true)}
                                disabled={disabled}
                            >
                                + Add other
                            </button>
                        </td>
                    </tr>
                </HardwareTableBody>
            </HardwareTable>
        </HardwareContainer>
    );
};

export default HardwareTab;
