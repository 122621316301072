import axiosInstance from "./axios-instance";

const getRooms = async (clientId?: number, search?: string) => {
    try {
        const response = await axiosInstance.get(
            `/room/getAll?clientId=${clientId}&searchStr=${search}`
        );

        return response.data;
    } catch (error) {
        console.log("Error getting rooms", error);
    }
};

const addRoom = async (payload: {
    accountId: number;
    name: string;
    clientId: number;
}) => {
    try {
        const response = await axiosInstance.post(`/room/add`, payload);
        return response.data;
    } catch (error) {
        console.log("Error adding room", error);
    }
};

export { getRooms, addRoom };
