import axiosInstance from "./axios-instance";

const requestPasswordReset = async (email: string) => {
    try {
        const response = await axiosInstance.post(
            "auth/request-password-reset",
            { email }
        );
        return response.data;
    } catch (error) {
        console.log("Error request password reset: ", error);
    }
};

const passwordReset = async (code: string, password: string) => {
    try {
        const response = await axiosInstance.post("auth/password_reset", {
            code,
            password,
        });
        return response.data;
    } catch (error) {
        console.log("Error with password reset: ", error);
    }
};

export { requestPasswordReset, passwordReset };
