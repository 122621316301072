import axiosInstance from "./axios-instance";
const getWorkroomSetting = async (payload: { id: number }) => {
    try {
        const response = await axiosInstance.post<any>(
            "/workroomSetting/get",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error while getting workroom setting:", error);
        throw error;
    }
};

const searchWorkroomSetting = async (accountId: number, type?: number) => {
    try {
        const response = await axiosInstance.post<any>(
            "/workroomSetting/search",
            { accountId, type }
        );
        return response.data;
    } catch (error) {
        console.error("Error while getting workroom setting:", error);
        throw error;
    }
};

const addWorkroomSetting = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>("/workroomSetting/add", {
            ...payload,
        });
        return response.data;
    } catch (error) {
        console.error("Error while creating workroom setting:", error);
        throw error;
    }
};

const editWorkroomSetting = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>(
            "/workroomSetting/update",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error while updating workroom setting:", error);
        throw error;
    }
};

export {
    getWorkroomSetting,
    searchWorkroomSetting,
    addWorkroomSetting,
    editWorkroomSetting,
};
