import axiosInstance from "./axios-instance";

const addDraperyOrderUpload = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>(
            `/draperyOrderUpload/add`,
            payload
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const editDraperyOrderUpload = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>(
            `/draperyOrderUpload/edit`,
            payload
        );

        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export { addDraperyOrderUpload, editDraperyOrderUpload };
