import React, { FC, memo, CSSProperties } from "react";
import styled, { theme } from "../../theme";

const SectionContainer = styled.div`
    flex: 1 1 auto;
    background-color: ${theme.colors.contentBackground};
    margin: 0;
`;

interface ContentSectionProps {
    style?: CSSProperties;
}

/**
 * Page Content
 */
export const ContentSection: FC<ContentSectionProps> = memo(
    ({ style, children }) => {
        return <SectionContainer style={style}>{children}</SectionContainer>;
    }
);
