import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import {
    DraperyOrderHardware,
    ChangeableDraperyOrderHardware,
} from "./interfaces";

export const ADD_DRAPERY_ORDER_HARDWARE = "draperyOrderHardware/ADD";
export type AddDraperyOrderHardwareAction = ActionDescriptor<
    typeof ADD_DRAPERY_ORDER_HARDWARE,
    Omit<DraperyOrderHardware, "id"> & { accountId: number },
    DraperyOrderHardware
>;

export const EDIT_DRAPERY_ORDER_HARDWARE = "draperyOrderHardware/EDIT";
export type EditDraperyOrderHardwareAction = ActionDescriptor<
    typeof EDIT_DRAPERY_ORDER_HARDWARE,
    ChangeableDraperyOrderHardware & { id: number },
    {}
>;

export interface DraperyOrderHardwareActionsMap {
    [ADD_DRAPERY_ORDER_HARDWARE]: AddDraperyOrderHardwareAction;
    [EDIT_DRAPERY_ORDER_HARDWARE]: EditDraperyOrderHardwareAction;
}
