import axiosInstance from "./axios-instance";

const searchDraperyOrderDesignLining = async (draperyOrderDesignId: number) => {
    try {
        const response = await axiosInstance.get(
            `/draperyOrderDesignLining/search?draperyOrderDesignId=${draperyOrderDesignId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error with drapery Order Design Lining search:", error);
        throw error;
    }
};

const addDraperyOrderDesingLining = async (payload: any) => {
    try {
        const response = await axiosInstance.post(
            `/draperyOrderDesignLining/add`,
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error with drapery Order Design Lining add:", error);
        throw error;
    }
};

const editDraperyOrderDesignLining = async (payload: any) => {
    try {
        const response = await axiosInstance.put(
            `/draperyOrderDesignLining/edit`,
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error with drapery Order Design Lining edit:", error);
        throw error;
    }
};

export {
    searchDraperyOrderDesignLining,
    addDraperyOrderDesingLining,
    editDraperyOrderDesignLining,
};
