import { PageDimmer } from "@ramble/ramble-ui";
import { Omit } from "@ramble/types";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import { ConfirmationModal, ConfirmationModalProps } from "./modals/confirmation_modal";
import { theme } from "./theme";

const noop = () => { /* ignore */ };

type Options = Pick<ConfirmationModalProps, "uiHeader" | "uiDeclineLabel" | "uiAcceptLabel" | "uiCloseOnEsc" | "uiCloseOnOutsideClick" | "className" | "workOrderEditMode" | "cancelEditing">;

export type RequiredConfirmationModalProps = Pick<ConfirmationModalProps, "uiActive" | "uiOnRequestClose" | "uiOnConfirm" | "uiOnClose" | "uiCloseOnEsc" | "uiCloseOnOutsideClick" | "workOrderEditMode" | "cancelEditing">;

type ConfirmationResult<T> = T extends (arg: infer K) => void ? K extends object ? K : boolean : boolean;

/**
 * Show confirmation by using custom modal
 * @param ModalComponent Modal to show
 */
export async function confirmation<TProps extends RequiredConfirmationModalProps>(
    ModalComponent: React.ComponentType<TProps>,
    modalProps: Omit<TProps, keyof RequiredConfirmationModalProps>,
): Promise<ConfirmationResult<TProps["uiOnConfirm"]> | false>;

/**
 * Show confirmation dialog and wait for accepting/declining
 * @param content Confirmation conent. May be string or jsx element
 * @param options Options
 * @returns True if confirmed, false otherwise
 */
export async function confirmation(
    content: string | JSX.Element,
    options?: Options,
): Promise<boolean>;

export async function confirmation<TResult = object>(
    contentOrModal: string | JSX.Element | React.ComponentType<RequiredConfirmationModalProps>,
): Promise<TResult>;
export async function confirmation<TResult = boolean>(
    contentOrModal: string | JSX.Element | React.ComponentType<RequiredConfirmationModalProps>,
    options: Options = {},
): Promise<TResult | false> {
    const node = document.body.appendChild(document.createElement("div"));
    return new Promise<TResult | false>(resolve => {
        const unmount = () => {
            ReactDOM.unmountComponentAtNode(node);
            document.body.removeChild(node);
        };
        const accept = (arg?: unknown) => {
            close();
            if (!options.workOrderEditMode) {
                resolve(arg ? arg as TResult : true as any);
            }
        };
        const decline = async () => {
            close();
            if (options.workOrderEditMode && options.cancelEditing) {
                await options.cancelEditing()
                await resolve(false)
            } else {
                resolve(false);
            }
        };
        const ModalComponent: React.SFC<RequiredConfirmationModalProps> = typeof contentOrModal === "function"
            ? contentOrModal as any
            : ConfirmationModal;
        const element = (active: boolean) => {
            return (
                <ThemeProvider theme={theme}>
                    <>
                        <PageDimmer uiActive={active} />
                        <ModalComponent
                            {...options}
                            uiActive={active}
                            uiOnRequestClose={active ? decline : noop}
                            uiOnConfirm={active ? accept : noop}
                            uiOnClose={active ? noop : unmount}
                        >
                            {typeof contentOrModal !== "function" ? contentOrModal : null}
                        </ModalComponent>
                    </>
                </ThemeProvider>
            );
        };
        const close = () => {
            ReactDOM.render(element(false), node);
        };
        ReactDOM.render(element(true), node);
    });
}
