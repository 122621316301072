import {
    Button,
    FormValidationError,
    Modal,
    ModalActions,
    ModalHeader,
    PageDimmer,
    PopoverScrollContext,
    TextArea,
    ValidationForm,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import { ClientPricingAction, PricingType } from "../types";
import {
    DraperyItemDetailsFieldsPartial,
    draperyItemDetailsFieldsInitialState,
    draperyItemDetailsFieldsReducer,
} from "../../../redux/modules/my-shop/drapery-item-details-fields-reducer";
// import { ConfirmationCloseModal } from "../../../modals/confirmation_close_modal";
import {
    FormFieldsPartial,
    formFieldsInitialState,
    formFieldsReducer,
} from "../../../redux/modules/my-shop/form-fields-reducer";
import { ItemModalContent, ItemTabs, TabContainer } from "../styles";
import React, {
    FC,
    FormEvent,
    KeyboardEvent,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import { Tab, TabList } from "react-tabs";
import {
    addDraperyRecord,
    getDraperyRecord,
    updateDraperyRecord,
} from "../../../api/item";
import { draperyPricingTypes, draperyWidthRoundUpTypes } from "../constants";
import styled, { theme } from "../../../theme";
import { useDispatch } from "react-redux";
import { confirmation } from "../../../confirmation";
import { Category } from "../../../interfaces/category";
import { ConstructionSettings } from "./construction-settings/main";
import Details from "./details";
import { HelpPopup } from "../../content/help_popup";
import Pricing from "./pricing";
import { Supplier } from "../../../interfaces/supplier";
import { detect } from "detect-browser";
import { getSelectedName } from "../helpers";
import PricingAlert from "../../../assets/icons/pricingalert.svg";
import { Prompt } from "react-router-dom";

const CancelButton = styled(Button)`
    background-color: #f6f6f6;
    border-radius: 0.5em;
    border: 1px solid #cdcdcd;
    color: #222222;

    &:hover {
        background-color: #eeeeee;
    }
`;

export const FormLabel = styled.label`
    color: ${theme.colors.grayRGBA};
    margin-bottom: 2px;

    .red-asterisk {
        color: ${theme.colors.red};
    }
`;

export const FormInput = styled(ValidationPopupInput)`
    padding: 0.35em 0.5em;
`;

export const RadioLabel = styled.label`
    display: inline-flex;
    align-items: center;
    margin-right: 30px !important;

    > label:hover,
    &:hover {
        cursor: pointer;
    }
`;

export const RadioInput = styled(ValidationPopupInput)`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 0;
    margin-right: 5px;
    border: 1px solid ${theme.colors.grayRGBA};
    border: ${(props) =>
        props.checked ? `5px solid ${theme.colors.blue}` : ""};

    input {
        visibility: hidden;
    }
`;

export const TextAreaContainer = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    span {
        margin-top: 5px;
        margin-right: 10px;
    }

    ${TextArea} {
        width: 100%;
        border: solid ${theme.colors.primary} 1px;
        height: 120px;
    }
`;

export const RightCell = styled.div`
    width: 50%;
    margin-bottom: 20px;
    padding-right: 1.25rem;
    padding-right: 0;
    padding-left: 1.25rem;
`;

export const RightCellWithBorder = styled(RightCell)`
    border-left: 1px solid ${theme.colors.blue};
`;

export const TooltipPopup = styled(HelpPopup)`
    max-width: 300px;
    max-height: 50%;
    display: flex !important;

    > svg {
        color: ${theme.colors.red1};
    }
`;
const ModalItem = styled(Modal)`
    width: 90%;
    min-height: 300px;
    bottom: 0px;
`;
const ValidationFormDrapery = styled(ValidationForm)`
    min-height: inherit;
`;
interface DraperyItemModalProps {
    isModalOpen: boolean;
    categories: Category[];
    suppliers: Supplier[];
    itemId: number | null;
    onModalClose(): void;
    reloadGrid(): void;
    validateAll?(mode: string): boolean;
    updateStateByName?(
        name: string,
        value: string | number | boolean | number[] | undefined | object,
        isSaving?: boolean
    ): void;
    isPleatModalOpen?: boolean;
    showDraperyModal?: boolean;
    history?: any;
    fetchAll(): void;
    tabIndex?: number;
    reloadOnSave?(): void;
    highLightInput?: string[];
}

interface ScrollInfo {
    scrollX: number;
    scrollY: number;
}

const DraperyItemModal: FC<DraperyItemModalProps> = ({
    isModalOpen,
    categories,
    suppliers,
    itemId,
    onModalClose,
    reloadGrid,
    fetchAll,
    updateStateByName,
    isPleatModalOpen,
    showDraperyModal,
    history,
    tabIndex,
    reloadOnSave,
    highLightInput,
}) => {
    const constructionDispatch = useDispatch();
    const constructionRef = useRef<any>(null);

    const [isChanged, setIsChanged] = useState<boolean>(false);

    const accountId = parseFloat(localStorage.getItem("accountId") || "0");
    const browser = detect();
    // const [isCloseModalActive, setIsCloseModalActive] =
    //     useState<boolean>(false);
    const [activeTabIndex, setActiveTabIndex] = useState<number>(tabIndex || 0);
    const [areaScrollInfo, setAreaScrollInfo] = useState<ScrollInfo>({
        scrollX: 0,
        scrollY: 0,
    });
    const [copyFormFields, copyDispatch] = useReducer(
        formFieldsReducer,
        formFieldsInitialState
    );
    const [formFields, dispatch] = useReducer(
        formFieldsReducer,
        formFieldsInitialState
    );
    const [copyDraperyItemDetailsFields, copyDispatchItemDetails] = useReducer(
        draperyItemDetailsFieldsReducer,
        draperyItemDetailsFieldsInitialState
    );
    const [draperyItemDetailsFields, dispatchItemDetails] = useReducer(
        draperyItemDetailsFieldsReducer,
        draperyItemDetailsFieldsInitialState
    );

    const [errorElementId, setErrorElementId] = useState<string | null>(null);

    const handleInvalidElem = (elem: HTMLElement): void => {
        const elemId = elem.id;

        if (
            [
                "name",
                "itemCategory",
                "itemTypeProduct",
                "itemTypeService",
            ].includes(elemId)
        ) {
            setActiveTabIndex(0);
        } else {
            setActiveTabIndex(1);
        }

        setErrorElementId(elemId);
    };

    const saveConstructionSettings = async () => {
        if (!constructionRef.current) return false;

        const result = await constructionRef.current.saveCurrentSetting();
        return result;
    };

    const handleSubmitForm = async (
        e: FormEvent<HTMLFormElement>
    ): Promise<void> => {
        e.preventDefault();
        setErrorElementId(null);
        if (!isPleatModalOpen) {
            const {
                name,
                itemNumber,
                avatarId,
                itemTypeId,
                supplierId,
                categoryId,
                notes,
                isActive,
            } = formFields;

            let payload: FormFieldsPartial = {
                name,
                itemNumber,
                avatarId,
                itemTypeId,
                supplierId,
                categoryId,
                notes,
                isActive,
            };

            const {
                pricingTypeId,
                isClientPricingActionOn,
                clientPricingAction,
                clientPricingValue,
                isPartialWidthRoundingOn,
                partialWidthRoundUpTypeId,
                pleatTypesWithPricing,
                liningAndInterliningWithPricing,
                cafeCurtainsWidthInches,
                cafeCurtainsWidthPricing,
                extraLargePanelsLengthInches,
                extraLargePanelsLengthPricing,
                extraLargePanelsWidthInches,
                extraLargePanelsWidthPricing,
                draperyPinningWidthPricing,
                specialityFabricsMarkUpPercentage,
                embellishmentsWithPricing,
                addOnsWithPricing,
                salesDescriptionSettings,
                orderingDescriptionSettings,
            } = draperyItemDetailsFields;

            let detailsPayload: DraperyItemDetailsFieldsPartial = {
                pricingTypeId,
                isClientPricingActionOn,
                clientPricingAction,
                clientPricingValue,
                isPartialWidthRoundingOn,
                partialWidthRoundUpTypeId,
                pleatTypesWithPricing,
                liningAndInterliningWithPricing,
                cafeCurtainsWidthInches,
                cafeCurtainsWidthPricing,
                extraLargePanelsLengthInches,
                extraLargePanelsLengthPricing,
                extraLargePanelsWidthInches,
                extraLargePanelsWidthPricing,
                draperyPinningWidthPricing,
                specialityFabricsMarkUpPercentage,
                embellishmentsWithPricing,
                addOnsWithPricing,
                salesDescriptionSettings,
                orderingDescriptionSettings,
            };
            let comboPayload = {
                ...payload,
                ...detailsPayload,
            };
            if (updateStateByName) {
                for (const key in detailsPayload) {
                    if (
                        [
                            "pricingTypeId",
                            "clientPricingAction",
                            "clientPricingValue",
                            "partialWidthRoundUpTypeId",
                            "pleatTypesWithPricing",
                            "liningAndInterliningWithPricing",
                            "cafeCurtainsWidthInches",
                            "cafeCurtainsWidthPricing",
                            "extraLargePanelsLengthInches",
                            "extraLargePanelsLengthPricing",
                            "extraLargePanelsWidthInches",
                            "extraLargePanelsWidthPricing",
                            "draperyPinningWidthPricing",
                            "specialityFabricsMarkUpPercentage",
                            "embellishmentsWithPricing",
                            "addOnsWithPricing",
                            "salesDescriptionSettings",
                            "orderingDescriptionSettings",
                        ].includes(key)
                    ) {
                        updateStateByName(
                            `draperyItemDetail.0.${key}`,
                            detailsPayload[key]
                        );
                    }
                }
            }

            if (updateStateByName) {
                for (const key in detailsPayload) {
                    if (
                        [
                            "pricingTypeId",
                            "clientPricingAction",
                            "clientPricingValue",
                            "partialWidthRoundUpTypeId",
                            "pleatTypesWithPricing",
                            "liningAndInterliningWithPricing",
                            "cafeCurtainsWidthInches",
                            "cafeCurtainsWidthPricing",
                            "extraLargePanelsLengthInches",
                            "extraLargePanelsLengthPricing",
                            "extraLargePanelsWidthInches",
                            "extraLargePanelsWidthPricing",
                            "draperyPinningWidthPricing",
                            "specialityFabricsMarkUpPercentage",
                            "embellishmentsWithPricing",
                            "addOnsWithPricing",
                            "salesDescriptionSettings",
                            "orderingDescriptionSettings",
                        ].includes(key)
                    ) {
                        updateStateByName(
                            `draperyItemDetail.0.${key}`,
                            detailsPayload[key]
                        );
                    }
                }
            }

            let result: any;

            if (itemId) {
                comboPayload = {
                    ...comboPayload,
                    id: itemId,
                };
                result = await updateDraperyRecord(comboPayload);
            } else {
                result = await addDraperyRecord(comboPayload);
            }

            const constructionSaved = await saveConstructionSettings();

            await  fetchAll()

            const errors = result.data.errors;
            const status = result.status;

            if (errors) {
                setAreaScrollInfo({
                    scrollX: 0,
                    scrollY: 0,
                });
                throw FormValidationError.fromErrorDictionary(errors);
            } else if (!constructionSaved) {
                setActiveTabIndex(2);
                setErrorElementId("errorMessage");
            } else if ([201, 200].includes(status)) {
                if (reloadOnSave) {
                    reloadOnSave();
                }
                onSaveClose();
                if (showDraperyModal && history) {
                    localStorage.removeItem("draperyItem");
                    history.push("/calculator");
                }
                reloadGrid();
            }
        }
    };

    const deepEqual = (obj1: any, obj2: any): boolean => {
        if (obj1 === obj2) return true;

        if (obj1 == null || obj2 == null) return false;

        if (typeof obj1 !== "object" || typeof obj2 !== "object") return false;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key]))
                return false;
        }

        return true;
    };

    const handleKeyPressForm = (e: KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter" || e.keyCode === 13) {
            e.preventDefault();
        }
    };

    const selectTab = (index: number) => {
        setActiveTabIndex(index);
        setAreaScrollInfo({
            scrollX: 0,
            scrollY: 0,
        });
    };

    // const onClose = () => {
    //     dispatch({ type: "resetFormFields" });
    //     setActiveTabIndex(0);
    //     onModalClose();
    // };

    const showEditWorkOrderConfirm = (): Promise<boolean> => {
        return confirmation(<div>Would you like to cancel your edits?</div>, {
            uiHeader: "Confirmation",
            uiAcceptLabel: "No, continue editing",
            uiDeclineLabel: "Yes, cancel edits",
            workOrderEditMode: true,
            cancelEditing: () => {
                onModalClose();
            },
        });
    };

    const onClose = () => {
        if (
            deepEqual(formFields, copyFormFields) &&
            deepEqual(draperyItemDetailsFields, copyDraperyItemDetailsFields) &&
            !isChanged
        ) {
            // setIsCloseModalActive(false);
            dispatch({ type: "resetFormFields" });
            setActiveTabIndex(0);
            onModalClose();
        } else {
            // setIsCloseModalActive(true);
            showEditWorkOrderConfirm();
        }
    };

    const onSaveClose = () => {
        dispatch({ type: "resetFormFields" });
        setActiveTabIndex(0);
        onModalClose();
        history.push("/calculator");
    };

    const renderDetails = (): JSX.Element => {
        return (
            <TabContainer>
                <Details
                    categories={categories}
                    suppliers={suppliers}
                    browser={browser}
                    formFields={formFields}
                    dispatch={dispatch}
                    copyDispatch={copyDispatch}
                    draperyItemDetailsFields={draperyItemDetailsFields}
                    dispatchItemDetails={dispatchItemDetails}
                />
            </TabContainer>
        );
    };

    const renderPricing = (): JSX.Element => {
        return (
            <TabContainer>
                <Pricing
                    browser={browser}
                    draperyItemDetailsFields={draperyItemDetailsFields}
                    dispatch={dispatchItemDetails}
                    itemId={itemId}
                    formFields={formFields}
                    highLightInput={highLightInput}
                />
            </TabContainer>
        );
    };

    const renderConstructionSettings = (): JSX.Element => {
        return (
            <TabContainer>
                <ConstructionSettings
                    dispatch={constructionDispatch}
                    setIsChanged={setIsChanged}
                    accountId={accountId}
                    ref={constructionRef}
                />
            </TabContainer>
        );
    };

    const getModalTitle = (): JSX.Element | "" => {
        const { categoryName, name } = formFields;

        if (!categoryName && !name) {
            return (
                <span className="text-twPrimary font-medium">Add New Item</span>
            );
        }

        return (
            <div>
                {categoryName && (
                    <>
                        <span className="text-twBlue">{categoryName}</span>
                        <span className="p-1">|</span>
                    </>
                )}
                <span className="text-twBlue">{`${name || ""}`}</span>
            </div>
        );
    };

    useEffect(() => {
        if (itemId) {
            getDraperyRecord({ recordId: itemId })
                .then((res) => {
                    const { pleatTypesWithPricing } = res;
                    const itemUpdates: FormFieldsPartial = {
                        ...res,
                        pleatTypesWithPricing: pleatTypesWithPricing || [],
                    };
                    const draperyItemDetailsUpdates: DraperyItemDetailsFieldsPartial =
                        {
                            ...res,
                        };

                    if (pleatTypesWithPricing) {
                        const ripplefoldPleat = pleatTypesWithPricing.find(
                            (pleat) =>
                                pleat.id === "ripplefold" && pleat.price === ""
                        );
                        const currentPath = window.location.pathname;
                        if (ripplefoldPleat && currentPath !== "/myshop") {
                            setActiveTabIndex(1);
                        }
                    }

                    const supplierName = getSelectedName({
                        list: suppliers as [],
                        findByKey: "id",
                        findValue: itemUpdates.supplierId,
                        nameField: "displayName",
                    });

                    const pricingTypeName = getSelectedName({
                        list: draperyPricingTypes as [],
                        findByKey: "key",
                        findValue: draperyItemDetailsUpdates.pricingTypeId,
                        nameField: "label",
                    });

                    const partialWidthRoundUpTypeName = getSelectedName({
                        list: draperyWidthRoundUpTypes as [],
                        findByKey: "key",
                        findValue:
                            draperyItemDetailsUpdates.partialWidthRoundUpTypeId,
                        nameField: "label",
                    });
                    copyDispatch({
                        type: "updateFormFields",
                        formFieldUpdates: {
                            ...itemUpdates,
                            supplierName,
                        },
                    });
                    dispatch({
                        type: "updateFormFields",
                        formFieldUpdates: {
                            ...itemUpdates,
                            supplierName,
                        },
                    });
                    copyDispatchItemDetails({
                        type: "updateFormFields",
                        formFieldUpdates: {
                            ...draperyItemDetailsUpdates,
                            pricingTypeName,
                            partialWidthRoundUpTypeName,
                        },
                    });
                    dispatchItemDetails({
                        type: "updateFormFields",
                        formFieldUpdates: {
                            ...draperyItemDetailsUpdates,
                            pricingTypeName,
                            partialWidthRoundUpTypeName,
                        },
                    });
                })
                .catch((err) => {
                    console.log(`error fetching drapery item: ${err}`);
                });
        } else {
            const pricingTypeName = getSelectedName({
                list: draperyPricingTypes as [],
                findByKey: "key",
                findValue: draperyItemDetailsFields.pricingTypeId,
                nameField: "label",
            });
            const clientPricingAction =
                pricingTypeName === PricingType.Retail
                    ? ClientPricingAction.Discount
                    : ClientPricingAction.MarkUp;
            copyDispatchItemDetails({
                type: "updateFormFields",
                formFieldUpdates: {
                    pricingTypeName,
                    clientPricingAction,
                },
            });
            dispatchItemDetails({
                type: "updateFormFields",
                formFieldUpdates: {
                    pricingTypeName,
                    clientPricingAction,
                },
            });
        }
    }, [itemId]);

    useEffect(() => {
        if (errorElementId) {
            const errorElement = document.getElementById(errorElementId);
            if (errorElement) {
                errorElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
                errorElement.focus();
            }
        }
    }, [errorElementId]);
    const handleNextChange = () => {
        if (activeTabIndex < 2) {
            setActiveTabIndex(activeTabIndex! + 1);
        }
    };

    return (
        <>
          <Prompt
                message={(location: any): string | boolean => {
                    if (isModalOpen) {
                        showEditWorkOrderConfirm();
                        return false;
                    } else {
                        window.location.replace('/myshop')
                        return true
                    }
                }}
            />
            <PageDimmer uiActive={isModalOpen} />
            <ModalItem
                uiActive={isModalOpen}
                uiOnRequestClose={() => {
                    return;
                }}
                uiDefaultSizes={false}
            >
                <ValidationFormDrapery
                    uiOnSubmit={handleSubmitForm}
                    uiReportValidityMode="firstInvalid"
                    uiOnInvalidElem={handleInvalidElem}
                    onKeyPress={handleKeyPressForm}
                    uiPadding={false}
                    className="h-full"
                >
                    <ModalHeader className="border-0">
                        <div className="flex justify-between w-full pb-2 border-b-8 border-twSecondary">
                            {getModalTitle()}
                        </div>
                    </ModalHeader>

                    <ItemModalContent className="mb-8">
                        <PopoverScrollContext.Provider value={areaScrollInfo}>
                            <ItemTabs
                                selectedIndex={activeTabIndex}
                                onSelect={selectTab}
                                forceRenderTabPanel
                                className="flex flex-col flex-1 h-full"
                            >
                                <TabList>
                                    <Tab>Details</Tab>
                                    <Tab>Pricing</Tab>
                                    <Tab>Construction Settings</Tab>
                                    {activeTabIndex === 1 && (
                                        <Tab
                                            disabled
                                            style={{
                                                border: "none",
                                                background: "#FDF2DC",
                                                float: "right",
                                                marginTop: "-15px",
                                                borderRadius: "8px",
                                                gap: "25px",
                                                color: "#9C6B08",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                                lineHeight: "25px",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "11px 15px",
                                            }}
                                        >
                                            <PricingAlert
                                                style={{
                                                    padding: "3.24px",
                                                    width: "22px",
                                                    height: "22px",
                                                    top: "11px",
                                                    left: "15px",
                                                    gap: "0px",
                                                    borderRadius: "6px",
                                                    opacity: "0px",
                                                    backgroundColor: "#FFC043",
                                                }}
                                            />
                                            All pricing fields are REQUIRED
                                        </Tab>
                                    )}
                                </TabList>
                                {renderDetails()}
                                {renderPricing()}
                                {renderConstructionSettings()}
                            </ItemTabs>
                        </PopoverScrollContext.Provider>
                    </ItemModalContent>

                    <ModalActions>
                        <CancelButton
                            type="button"
                            uiColor="secondary"
                            onClick={onClose}
                            className="rounded-lg mr-auto block shadow-none"
                        >
                            Cancel
                        </CancelButton>
                        {activeTabIndex >= 2 ? (
                            <Button
                                className="rounded-lg border border-twBlue2 block shadow-none"
                                uiColor="primary"
                            >
                                Save
                            </Button>
                        ) : (
                            <CancelButton
                                type="button"
                                className="rounded-lg block shadow-none"
                                uiColor="secondary"
                                onClick={handleNextChange}
                            >
                                Next
                            </CancelButton>
                        )}
                    </ModalActions>
                </ValidationFormDrapery>
            </ModalItem>
           {/*  <ConfirmationCloseModal
                uiAcceptLabel="Yes"
                uiDeclineLabel="No"
                uiHeader="Confirmation"
                uiActive={isCloseModalActive}
                uiOnConfirm={() => {
                    dispatch({ type: "resetFormFields" });
                    setActiveTabIndex(0);
                    onModalClose();
                }}
                uiOnClose={() => setIsCloseModalActive(false)}
                uiOnRequestClose={() => setIsCloseModalActive(false)}
                uiCloseOnOutsideClick={true}
                uiCloseOnEsc={true}
            >
                Are you sure you want to exit without saving the changes?
            </ConfirmationCloseModal>*/}
        </>
    );
};

export default DraperyItemModal;
