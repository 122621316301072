import { flagRoot } from "../../utils";

/**
 * Application state
 */
export interface State {
    /**
     * Is application loaded
     */
    loaded: boolean;
}

/**
 * State default attributes
 */
export function defaults(): State {
    const st: State = {
        loaded: false,
    };
    return flagRoot(st);
}
