import {
    DescriptorMapToActionTuples,
    REALTIME_ACTION_REQUEST,
    RequestKey,
} from "../common";

import { AccountActionsMap } from "./account";
import { AdminActionsMap } from "./admin";
import { AuthActionsMap } from "./auth";
import { ClientActionsMap } from "./client";
import { ClientDashboardActionsMap } from "./client_dashboard";
import { DraperyOrderActionsMap } from "./drapery_order";
import { DraperyOrderDesignActionsMap } from "./drapery_order_design";
import { DraperyOrderDesignEmbellishmentActionsMap } from "./drapery_order_design_embellishment";
import { DraperyOrderDesignLiningActionsMap } from "./drapery_order_design_lining";
import { DraperyOrderFabricActionsMap } from "./drapery_order_fabric";
import { DraperyOrderItemActionsMap } from "./drapery_order_item";
import { DraperyOrderHardwareActionsMap } from "./drapery_order_hardware";
import { DraperyOrderSummaryActionsMap } from "./drapery_order_summary";
import { DraperyOrderSummaryComponentActionsMap } from "./drapery_order_summary_component";
import { FeedbackActionsMap } from "./feedback";
import { PromotionActionsMap } from "./promotion";
import { QuickbookActionsMap } from "./quickbook";
import { RoomActionsMap } from "./room";
import { SuppliersActionsMap } from "./supplier";
import { UILogActionsMap } from "./ui-logger";
import { UserActionsMap } from "./user";
import { WorkOrderActionsMap } from "./work_order";
import { WorkroomSettingActionsMap } from "./workroom_setting";
import { DraperyOrderPriceActionsMap } from "./drapery_order_price/actions";
import { DraperyOrderUploadActionsMap } from "./drapery_order_upload";

export type RealtimeActionsDescriptors = AuthActionsMap &
    UserActionsMap &
    DraperyOrderItemActionsMap &
    AccountActionsMap &
    ClientActionsMap &
    SuppliersActionsMap &
    QuickbookActionsMap &
    AdminActionsMap &
    RoomActionsMap &
    WorkOrderActionsMap &
    DraperyOrderActionsMap &
    DraperyOrderDesignActionsMap &
    DraperyOrderDesignEmbellishmentActionsMap &
    DraperyOrderDesignLiningActionsMap &
    DraperyOrderFabricActionsMap &
    DraperyOrderHardwareActionsMap &
    DraperyOrderSummaryActionsMap &
    DraperyOrderSummaryComponentActionsMap &
    WorkroomSettingActionsMap &
    ClientDashboardActionsMap &
    PromotionActionsMap &
    FeedbackActionsMap &
    UILogActionsMap &
    DraperyOrderPriceActionsMap &
    DraperyOrderUploadActionsMap;

// Map key -> tuple
export type RealtimeActionsMap =
    DescriptorMapToActionTuples<RealtimeActionsDescriptors>;

// All actions as tuple
export type RealtimeActions = RealtimeActionsMap[keyof RealtimeActionsMap];

// Action descriptors

export * from "./auth";
export * from "./account";
export * from "./user";
export * from "./client";
export * from "./quickbook";
export * from "./supplier";
export * from "./admin";
export * from "./room";
export * from "./work_order";
export * from "./workroom_setting";
export * from "./drapery_order";
export * from "./drapery_order_item";
export * from "./drapery_order_design";
export * from "./drapery_order_design_lining";
export * from "./drapery_order_design_embellishment";
export * from "./drapery_order_fabric";
export * from "./drapery_order_hardware";
export * from "./drapery_order_summary";
export * from "./drapery_order_summary_component";
export * from "./client_dashboard";
export * from "./promotion";
export * from "./feedback";
export * from "./ui-logger";
export * from "./drapery_order_price";
export * from "./drapery_order_upload";

/**
 * API version
 */
export const API_VERSION = 1.0;

/**
 * Helper to create request action
 * @param type
 * @param payload
 * @param [meta]
 * @returns action
 */
export function createAction<K extends keyof RealtimeActionsDescriptors>(
    type: K,
    payload: RealtimeActionsDescriptors[K][RequestKey]["payload"],
    meta: Partial<RealtimeActionsDescriptors[K][RequestKey]["meta"]> = {}
): RealtimeActionsDescriptors[K][RequestKey] {
    // tslint:disable-next-line:no-object-literal-type-assertion
    return {
        type,
        error: false,
        flowType: REALTIME_ACTION_REQUEST,
        meta: {
            realtime: true,
            ...(meta as any),
        },
        payload,
    } as RealtimeActionsDescriptors[K][RequestKey];
}

/**
 * Create action payload for given type
 * @param type Action type
 * @param payload Action request payload
 */
export function createActionPayload<K extends keyof RealtimeActionsDescriptors>(
    type: K,
    payload: RealtimeActionsDescriptors[K][RequestKey]["payload"]
): RealtimeActionsDescriptors[K][RequestKey]["payload"] {
    return payload;
}
