import Bind from "lodash-decorators/bind";
import React, {
    ChangeEvent,
    ComponentClass,
    ComponentType,
    FocusEvent,
    FormEvent,
    PureComponent,
    SFC,
} from "react";
import { forwardRef, WithForwardedRef } from "../utils/ref";

type Validatable = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
export interface ValidationRequiredProps {
    onChange?(e: ChangeEvent<any>): void;
    onBlur?(e: FocusEvent<any>): void;
    onInvalid?(e: FormEvent<any>): void;
}

type DefaultValidityMessages = {
    [K in Exclude<keyof ValidityState, "valid">]?: string;
};

export type ValidityMessages = DefaultValidityMessages & {
    [key: string]: string;
};

export interface WithValidationProps {
    /**
     * Should errors be reported element blur
     * @default true
     */
    uiReportOnBlur?: boolean;
    /**
     * Use default validation UI for reporting
     * @default false
     */
    uiUseDefaultReporting?: boolean;
    /**
     * Custom error messages for validity errors
     */
    uiValidityMessages?:
        | ValidityMessages
        | ((val: string, elem: HTMLElement) => ValidityMessages);
    /**
     * Additional custom validator
     * @param val Element value
     * @param elem Element
     */
    uiCustomValidator?(
        val: string,
        elem: HTMLElement
    ): { [key: string]: boolean };
    /**
     * Additional async validator. Will be performed on element blur
     * @param val Element value
     * @param elem Element
     */
    uiCustomAsyncValidator?(
        val: string,
        elem: HTMLElement
    ): Promise<{ [key: string]: boolean } | undefined>;
    /**
     * Callback called when element valid
     * @param elem Element
     */
    uiOnValid?(elem: HTMLElement): void;
    /**
     * Callback called when element is not valid
     * @param msg Error message
     * @param elem Element
     */
    uiOnInvalid?(msg: string, elem: HTMLElement, valid?: any): void;
    /**
     * Callback called when async validation started
     * @param elem Element
     */
    uiOnStartAsyncValidation?(elem: HTMLElement): void;
    /**
     * Callback called when async validation completes (either success or error)
     * @param valid Validity result
     * @param msg Error message if not valid
     * @param elem Element
     */
    uiOnFinishAsyncValidation?(
        valid: boolean,
        msg: string | undefined,
        elem: HTMLElement
    ): void;
    name?: string;
    value?: string | number;
    isValid?: boolean;
    id?: string;
    required?: boolean;
}

/**
 * Wrap component to provide input validation status based on HTML5 validation
 * @param Component Component to wrap
 */
function WithValidation<T extends ValidationRequiredProps>(
    Component: ComponentType<T>
): SFC<T & WithValidationProps> {
    class WithValidationComponent extends PureComponent<
        WithValidationProps & ValidationRequiredProps
    > {
        public static displayName = `WithValidation(${
            Component.displayName || Component.name || "WrappedComponent"
        })`;
        public static defaultProps: Partial<
            WithValidationProps & ValidationRequiredProps
        > = {
            uiReportOnBlur: true,
        };

        componentDidUpdate(newProps: Partial<WithValidationProps>): void {
            if (newProps.name === "billingType") {
                const elem = document.getElementById("billingType-selectList")!;
                if (this.props.uiOnValid && newProps.value) {
                    this.props.uiOnValid(elem);
                }
            }
            if (this.props.name === "selectedSearchNameOwner") {
                const elem = document.getElementById("itemCategory")!;
                if (this.props.uiOnValid && this.props.value) {
                    this.props.uiOnValid(elem);
                }
            }
            if (newProps.name === "displayName") {
                const elem = document.getElementById("displayNameOwner")!;
                if (
                    this.props.uiOnValid &&
                    newProps.value !== this.props.value
                ) {
                    this.props.uiOnValid(elem);
                }
            }
            if (
                this.props.name &&
                this.props.id &&
                [
                    "Markup Cost Trade Input",
                    "Discount MSRP Trade Input",
                    "Markup Cost Retail Input",
                    "Discount MSRP Retail Input",
                    "Rate Retail Input",
                    "Rate Trade Input",
                    "Item Cost",
                    "Item MSRP",
                    "Shipping Cost Input",
                    "Handling Fee MarkUp Product Input",
                    "Handling Fee MarkUp Shipping Cost Input",
                    "Handling Fee Rate Input",
                    "Shipping Cost Input",
                    "Cost Plus Retail Input",
                    "Cost Plus Trade Input",
                    "handlingFee",
                ].includes(this.props.name)
            ) {
                const elem = document.getElementById(this.props.id)!;
                if (this.props.uiOnValid && !this.props.required) {
                    this.props.uiOnValid(elem);
                }
            }
        }

        public render(): JSX.Element {
            const {
                uiCustomAsyncValidator,
                uiCustomValidator,
                uiOnFinishAsyncValidation,
                uiOnInvalid,
                forwardedRef,
                uiOnStartAsyncValidation,
                uiOnValid,
                uiReportOnBlur,
                uiValidityMessages,
                uiUseDefaultReporting,
                ...other
            } = this.props as WithForwardedRef<
                WithValidationProps & ValidationRequiredProps
            >;

            const Comp =
                Component as unknown as ComponentClass<ValidationRequiredProps>;
            return (
                <Comp
                    {...other}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onInvalid={this.onInvalid}
                    ref={forwardedRef}
                />
            );
        }

        /**
         * Assigns custom message mapping if non valid
         * @param e
         */
        @Bind()
        protected onChange(e: React.ChangeEvent<Validatable>): void {
            const {
                onChange,
                uiOnValid,
                uiValidityMessages,
                uiCustomValidator,
            } = this.props;
            const elem = e.currentTarget;
            const val: string = elem.value;
            const defaultValidity = elem.validity;
            const finalValidity = {
                badInput: defaultValidity.badInput,
                patternMismatch: defaultValidity.patternMismatch,
                rangeOverflow: defaultValidity.rangeOverflow,
                rangeUnderflow: defaultValidity.rangeUnderflow,
                stepMismatch: defaultValidity.stepMismatch,
                tooLong: defaultValidity.tooLong,
                tooShort: defaultValidity.tooShort,
                typeMismatch: defaultValidity.typeMismatch,
                valueMissing: defaultValidity.valueMissing,
            };
            const defaultValidatorsValid = Object.entries(finalValidity).every(
                ([, e]) => !e
            );
            // run custom validator only whn default validators are valid
            const customValidity =
                defaultValidatorsValid && uiCustomValidator
                    ? uiCustomValidator(val, elem)
                    : {};
            // Note: spreading over validity doesnt work
            Object.assign(finalValidity, customValidity);
            // final validty is valid if default validators are valid(false) + custom ones if provided
            const valid = Object.entries(finalValidity).every(([, e]) => !e);

            // need to always set validity, if custom messages weren't specified
            // the consumer of uiOnInvalid() may still think that there is an error
            if (valid) {
                elem.setCustomValidity("");
                if (uiOnValid) {
                    uiOnValid(elem);
                }
            } else if (uiValidityMessages) {
                const messages =
                    typeof uiValidityMessages === "function"
                        ? uiValidityMessages(val, elem)
                        : uiValidityMessages;
                for (const k of Object.keys(finalValidity)) {
                    // set first invalid message
                    if (finalValidity[k]) {
                        // overwrite message if provided
                        if (messages[k]) {
                            elem.setCustomValidity(messages[k]);
                        } else {
                            // Not valid and not provided -> reset to default message?
                            elem.setCustomValidity("");
                        }
                        break;
                    }
                }
            }
            if (onChange) {
                onChange(e);
            }
        }

        /**
         * Providers async validation and reports validation error (if any) if uiReportOnBlur=true
         * @param e
         */
        @Bind()
        protected async onBlur(
            e: React.FocusEvent<Validatable>
        ): Promise<void> {
            const {
                onBlur,
                uiReportOnBlur,
                uiCustomAsyncValidator,
                uiValidityMessages,
                uiOnStartAsyncValidation,
                uiOnFinishAsyncValidation,
            } = this.props;
            e.persist();
            const currentTarget = e.currentTarget;
            let valid = currentTarget.validity.valid;
            const val = currentTarget.value;
            if (valid && uiCustomAsyncValidator) {
                if (uiOnStartAsyncValidation) {
                    uiOnStartAsyncValidation(currentTarget);
                }
                const res = await uiCustomAsyncValidator(val, currentTarget);
                let message: string | undefined;
                if (res && typeof res === "object") {
                    const asyncNoValid = Object.entries(res).find(
                        ([_key, nonValid]) => nonValid
                    );
                    if (asyncNoValid) {
                        valid = false;
                        const messages =
                            typeof uiValidityMessages === "function"
                                ? uiValidityMessages(val, currentTarget)
                                : uiValidityMessages;
                        message =
                            messages && messages[asyncNoValid[0]]
                                ? messages[asyncNoValid[0]]
                                : "Validation Failed";
                        currentTarget.setCustomValidity(message);
                    }
                }
                if (uiOnFinishAsyncValidation) {
                    uiOnFinishAsyncValidation(valid, message, currentTarget);
                }
            }
            if (uiReportOnBlur) {
                // will trigger onInvalid()
                currentTarget.reportValidity();
            }
            if (onBlur) {
                await onBlur(e);
            }
        }

        /**
         * Reports error. This can be triggered by form submitting too
         * @param e
         */
        @Bind()
        protected onInvalid(e: React.FormEvent<Validatable>): void {
            const { onInvalid, uiUseDefaultReporting, uiOnInvalid } =
                this.props;
            if (onInvalid) {
                onInvalid(e);
            }
            if (!uiUseDefaultReporting) {
                e.preventDefault();
            }
            if (uiOnInvalid) {
                if (e.currentTarget.name !== "billingType" && !e.currentTarget.validationMessage.includes("The two nearest valid values are")) {
                    uiOnInvalid(
                        e.currentTarget.validationMessage,
                        e.currentTarget
                    );
                } else if (
                    e.currentTarget.name === "billingType" &&
                    e.currentTarget.value === ""
                ) {
                    uiOnInvalid(
                        "Please select billing type for client.",
                        e.currentTarget
                    );
                }
            }
        }
    }

    return forwardRef(WithValidationComponent as any) as any;
}

export default WithValidation;
