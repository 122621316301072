import {
    percentageRegEx,
    percentageWithZeroRegEx,
    priceRegEx,
} from "./constants";

import { ChangeEvent } from "react";

interface HandlePricingInputParams {
    event: ChangeEvent<HTMLInputElement>;
    keyParam?: string;
    type: string;
    dollarMin?: number;
    dollarMax?: number;

    allowZero?: boolean;
}

const validatePricingInput = ({
    event,
    keyParam,
    type = "dollarAmount",
    dollarMin = 0,
    dollarMax = 999.99,
    allowZero,
}: HandlePricingInputParams) => {
    const key = keyParam || event.currentTarget.id;
    let value = event.currentTarget.value;

    if (type === "percentage") {
        if (allowZero) {
            if (!percentageWithZeroRegEx.test(value)) {
                value = value.slice(0, -1); // do not allow new input value if it doesn't match the pattern
            }
        } else {
            if (!percentageRegEx.test(value)) {
                value = value.slice(0, -1); // do not allow new input value if it doesn't match the pattern
            }
        }
    } else {
        value = value.replace(/,/g, "");
        const parsedFloatValue = parseFloat(value);
        if (
            !priceRegEx.test(value) ||
            parsedFloatValue < dollarMin ||
            parsedFloatValue > dollarMax
        ) {
            value = value.slice(0, -1);
        }
    }
    return {
        [key]: value,
    };
};

const checkIfSimpleItem = (obj: any) => {
    return "id" in obj;
};

const checkIfGroupItem = (obj: any) => {
    return "group" in obj;
};

const allowOnlyPositiveNum = (value: string | number | undefined) => {
    if (!value) return undefined;
    let res = typeof value === "string" ? value : value.toString();
    if (!/^\d+(\.\d{1,2})?$/.test(res)) {
        res = res.slice(0, -1);
    }
    return res && res.length ? +res : undefined;
};

const getSelectedName = ({
    list,
    findByKey,
    findValue,
    nameField,
}: {
    list: [];
    findByKey: string;
    findValue: string | number | undefined | null;
    nameField: string;
}) => {
    if (!findValue) return "";
    const found = list.find((pt) => pt[findByKey] === findValue);
    return found ? found[nameField] : "";
};

const formatPrice = (val: string | number) => {
    if (!val) {
        return "";
    }
    const stringVal = typeof val !== "string" ? val.toString() : val;

    let formattedValue;
    if (stringVal.includes(".")) {
        const [integerPart, decimalPart] = stringVal.split(".");
        const integerValue = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        formattedValue = `${integerValue}.${decimalPart}`;
    } else {
        const integerValue = stringVal.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        formattedValue = integerValue;
    }
    return formattedValue.replace(",", "");
};

const formatSectionTotalPrice = (val: number) => {
    return `$${formatPrice(val.toFixed(2))}`;
};

export {
    validatePricingInput,
    checkIfSimpleItem,
    checkIfGroupItem,
    getSelectedName,
    allowOnlyPositiveNum,
    formatPrice,
    formatSectionTotalPrice,
};
