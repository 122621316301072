import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";

interface InchesInputProps {
    id: string;
    value?: string | number;
    onChange: (params: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    isBottom?: boolean;
    className?: string;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    placeholder?: string | undefined;
}
const StyledInput = styled.input`
    ::placeholder {
        color: #C2C2C2; 
    }
`;

const SingleInchInput: FC<InchesInputProps> = ({
    id,
    value,
    onChange,
    handleKeyDown,
    isBottom = false,
    className = "",
    onFocus,
    placeholder
}) => {
    return (
        <div
            className={`grid grid-cols-1 divide-x divide-black/25 ${
                isBottom ? "" : "border-b border-black/25"
            } h-14`}
        >
            {/* Finished Size */}
            <div className="flex flex-row items-center justify-center p-0">
                <StyledInput
                    id={id}
                    name={id}
                    value={value}
                    className="mr-2 rounded items-center border w-12 h-6 p-1 text-sm"
                    type="number"
                    step={0.001}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    data-name={value}
                    onFocus={onFocus}
                    placeholder={placeholder}
                />
                <p className="text-sm">in.</p>
            </div>
        </div>
    );
};

export default SingleInchInput;
