import styled, { css } from "../../theme";

import { Config } from "../../utils";
import DefaultMountainImgE from "../../assets/icons/default_mountain.svg";
import React from "react";
import defaultAvatarImg from "../../assets/images/default_avatar.png";
import TrashIcon from "./../../assets/icons/trash.svg";
import UploadNewIcon from './../../assets/icons/photograph_upload_icon.svg'
import DefaultAccountImgE from "./../../assets/icons/account_settings_profile.svg";

interface AvatarImageProps {
    avatarId?: string | null | undefined;
    firstName?: string;
    lastName?: string;
    isDefaultImgMountain?: boolean;
    isSquareImgHolder?: boolean;
    width: number;
    height?: number;
    fontSize: number;
    className?: string;
    companyName?: string;
    uiType?: "account" | "client";
}

const AvatarImageContainer = styled.div<AvatarImageProps>`
    > img {
        border-radius: ${(props) => (props.isSquareImgHolder ? "50%" : "12px")};
        width: ${(props) => props.width}px;
        ${(props) => props.height && `height: ${props.height}px;`}
        display: block;
    }

    > span {
        border-radius: 50%;
        width: ${(props) => props.width}px;
        height: ${(props) => props.height}px;
        background: #306f82;
        color: white;
        text-align: center;
        line-height: ${(props) => props.height}px;
        font-size: ${(props) => props.fontSize}px;
        display: block;
        font-weight: normal;
        ${(props) =>
            props.uiType === "account" &&
            css`
                background: white;
                color: #306f82;
            `}
    }
`;
const TrashDeleteWrapper = styled.div`
    cursor: pointer;
`;

const TrashDelete = styled(TrashIcon)`
    width: 40px;
    height: 38px;
    margin-bottom: 1em;
    background-size: cover;
    background-repeat: no-repeat;
`;

const getDefaultImage = (isDefaultImgMountain: boolean | undefined) => {
    if (isDefaultImgMountain) {
        return <DefaultMountainImg />;
    }
    return <img src={defaultAvatarImg} alt="" />;
};

export const AvatarImage: React.FunctionComponent<AvatarImageProps> = (
    props
) => (
    <AvatarImageContainer
        width={props.width}
        height={props.height}
        isSquareImgHolder={props.isSquareImgHolder}
        fontSize={props.fontSize}
        className={props.className}
        uiType={props.uiType}
    >
        {props.avatarId ? (
            <img src={Config.fileStoreUrl + props.avatarId} alt="" />
        ) : props.firstName || props.lastName ? (
            <span>
                {props.firstName ? props.firstName.charAt(0).toUpperCase() : ""}
                {props.lastName ? props.lastName.charAt(0).toUpperCase() : ""}
            </span>
        ) : props.companyName ? (
            <span>{props.companyName.charAt(0).toUpperCase()}</span>
        ) : (
            getDefaultImage(props.isDefaultImgMountain)
        )}
    </AvatarImageContainer>
);

interface AvatarProps {
    avatarId: string | null | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    isDefaultImgMountain?: boolean;
    isSquareImgHolder?: boolean;
    deleteAvatarImage(): void;
    openAvatarModal(): void;
}

interface AccountAvatarProps {
    avatarId: string | undefined | null;
    deleteAvatarImage(): void;
    openAvatarModal(): void;
    isSquareImgHolder?: boolean;
}
interface UserAvatarProps {
    avatarId: string | undefined | null;
    deleteAvatarImage(): void;
    openAvatarModal(): void;
    isSquareImgHolder?: boolean;
}

const AvatarContainer = styled.div`
    flex-basis: 40%;
    flex-direction: column;
    margin-right: 0 !important;
    display: flex;
    align-items: center;

    .avatar-block {
        position: relative;
        margin-bottom: 15px;
        width: 175px;

        &:hover .avatar-decorative-hover {
            display: flex;
        }
    }

    .avatar-decorative-hover {
        position: absolute;
        left: 30%;
        right: 0;
        top: 40%;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        display: none;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        transition: all linear 1s;
    }

    .del-avatar-btn {
        background-color: transparent;
        color: #fff;
        border: none;
        font-weight: bold;

        &:hover {
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }
    }

    .upload-button {
        border-radius: 8px;
        background-color: #3b97b1;
        min-width: 100px;
        border: 0;
        color: #fff;
        font-size: 14px;
        padding: 0px 16px;
        height: 40px;
        cursor: pointer;
        outline: none;
        &:hover {
            background-color: #44aecc;
        }
    }
`;

const Avatar: React.FunctionComponent<AvatarProps> = ({
    avatarId,
    firstName,
    lastName,
    isDefaultImgMountain,
    isSquareImgHolder,
    openAvatarModal,
    deleteAvatarImage,
}) => (
    <AvatarContainer>
        <div className="avatar-block">
            <AvatarImage
                avatarId={avatarId}
                firstName={firstName}
                lastName={lastName}
                width={175}
                height={175}
                fontSize={90}
                isDefaultImgMountain={isDefaultImgMountain}
                isSquareImgHolder={isSquareImgHolder}
            />
            {avatarId && (
                <div className="avatar-decorative-hover">
                    <TrashDeleteWrapper onClick={deleteAvatarImage}>
                        <TrashDelete />
                    </TrashDeleteWrapper>
                </div>
            )}
        </div>
        <button
            className="upload-button"
            type="button"
            onClick={openAvatarModal}
            style={{ marginTop: "20px" }}
        >
            {avatarId ? "Change Image" : "Add Image"}
        </button>
    </AvatarContainer>
);

const EmptyAvatar = styled.div`
    width: 175px;
    height: 175px;
    border-radius: 50%;
`;

const AccountAvatarContainer = styled.div`
    margin-right: 0 !important;
    display: flex;
    align-items: center;

    .avatar-block {
        position: relative;
        margin-bottom: 15px;
        width: 200px;
        height: 100px;

        &:hover .avatar-decorative.change-avatar {
            display: flex;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }

    .avatar-block-user {
        position: relative;
        margin-bottom: 15px;
        width: 100px;
        height: 100px;

        &:hover .avatar-decorative.change-avatar {
            display: flex;
            background-color: rgba(255, 255, 255, 0.8);
        }
    }

    .avatar-decorative {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: transparent;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        transition: all linear 1s;
    }

    .avatar-decorative.add-avatar {
        display: flex;
    }

    .avatar-decorative.change-avatar {
        display: none;
    }

    .upload-avatar-btn {
        background-color: transparent;
        color: #3b97b1;
        border: none;
        font-weight: 400;

        &:hover {
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }
    }

    .delete-button {
        border-radius: 0.5em;
        background-color: #3b87a0;
        border: 0;
        color: #fff;
        font-size: 15px;
        padding: 8px 25px;
        cursor: pointer;
        outline: none;
    }
`;

const DefaultAccountImg = styled(DefaultAccountImgE)`
    width: 100px;
    height: 100px;
    fill: #969696;
`;

const DefaultMountainImg = styled(DefaultMountainImgE)`
    width: 175px;
    height: 175px;
`;

export const AccountAvatar: React.FunctionComponent<AccountAvatarProps> = ({
    avatarId,
    openAvatarModal,
    deleteAvatarImage,
    isSquareImgHolder = false,
}) => (
    <AccountAvatarContainer>
        <div className="avatar-block">
            {avatarId ? (
                <AvatarImage
                    avatarId={avatarId}
                    width={200}
                    fontSize={90}
                    isSquareImgHolder={isSquareImgHolder}
                />
            ) : (
                <EmptyAvatar>
                    <div
                        style={{
                            border: "1px solid #E2E2E2",
                            padding: "24px, 0px, 0px, 0px",
                                gap: "10px", borderRadius: "12px", justifyContent: 'center', display: 'flex'
                            }}>
                            <UploadNewIcon />
                    </div>
                </EmptyAvatar>
            )}
        </div>
    </AccountAvatarContainer>
);

export const UserAvatar: React.FunctionComponent<UserAvatarProps> = ({
    avatarId,
    openAvatarModal,
    deleteAvatarImage,
    isSquareImgHolder = false,
}) => (
    <AccountAvatarContainer>
        <div className="avatar-block-user">
            {avatarId ? (
                <AvatarImage
                    avatarId={avatarId}
                    width={200}
                    height={100}
                    fontSize={90}
                    isSquareImgHolder={isSquareImgHolder}
                />
            ) : (
                <EmptyAvatar>
                    <DefaultAccountImg />
                </EmptyAvatar>
            )}
        </div>
    </AccountAvatarContainer>
);

export default Avatar;
