import React, {
    FC,
    FormEvent,
    useState,
    Dispatch,
    useEffect,
    ChangeEvent,
    useRef,
} from "react";
import {
    Button,
    Checkbox,
    Modal,
    ModalActions,
    ModalHeader,
    PageDimmer,
    ValidationForm,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import styled, { theme } from "../../../theme";
import Avatar from "../../UI/avatar";
import {
    FormFields,
    FormFieldsAction,
} from "../../../redux/modules/my-shop/form-fields-reducer";
import { AvatarModal } from "../../../modals/avatar_modal";
import { updateDraperyRecord } from "../../../api/item";
import { DraperyItemDetailsFields } from "../../../redux/modules/my-shop/drapery-item-details-fields-reducer";
import { uploadImage } from "../../../api/account";
import { Config } from "../../../utils";

const ModalContainer = styled(Modal)`
    min-widht: 50%;
    max-width: 760px;
    min-height: 300px;
    bottom: 0;
    height: fit-content !important;
`;

const ModalBottom = styled(ModalActions)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

const CancelButton = styled(Button)`
    background-color: ${theme.colors.contentBackground};
    border-radius: 0.5em;
    border: 1px solid ${theme.colors.blue};

    &:hover {
        background-color: ${theme.colors.blue1};
        color: ${theme.colors.blue};
    }
`;

const SaveButton = styled(Button)`
    border-radius: 0.5em;
    border: 1px solid ${theme.colors.blue};
`;

const FormLabel = styled.label`
    color: ${theme.colors.grayRGBA};
    margin-bottom: 2px;

    .red-asterisk {
        color: ${theme.colors.red};
    }
    font-weight: 600;
`;

const PleatInput = styled(ValidationPopupInput)`
    padding: 0.35em 0.5em;
    border: solid ${theme.colors.primary} 1px;
    &.pleatPrice {
        min-width: 20%;
        max-width: 30%;
    }
`;

const FormContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const LeftSection = styled.div`
    width: 48%;
    margin-left: 20px;
`;

const RightSection = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AvatarWrapper = styled.div`
    margin-top: 20px;
`;

interface PleatType {
    image: string;
    type: string;
    title: string;
    value: string;
    active: boolean;
    isDeleted?: boolean;
}

interface PleatModalProps {
    isPleatModalOpen: boolean;
    onPleatModalClose(): void;
    formFields: FormFields;
    dispatch: Dispatch<FormFieldsAction>;
    itemId?: number | null;
    newPleatId?: any;
    draperyItemDetailsFields?: DraperyItemDetailsFields;
    pleatTypeListNew?: PleatType[];
}

const PleatModal: FC<PleatModalProps> = ({
    isPleatModalOpen,
    onPleatModalClose,
    dispatch,
    itemId,
    newPleatId,
    draperyItemDetailsFields,
    pleatTypeListNew,
}) => {
    const accountId = parseFloat(localStorage.getItem("accountId") || "0");
    const [, setIsFormValid] = useState(true);
    const [pleatTypesWithPricing, setPleatTypesWithPricing] = useState<any[]>(
        (draperyItemDetailsFields &&
            draperyItemDetailsFields.pleatTypesWithPricing) ||
            []
    );
    const [comboPayload] = useState<any>({});
    const modalTitle = newPleatId ? "Edit Pleat" : "Add New Pleat";
    const pleatNameInputRef = useRef<HTMLInputElement>(null);
    const pleatPriceInputRef = useRef<HTMLInputElement>(null);

    const existingPleat = newPleatId
        ? pleatTypesWithPricing.find((pleat) => pleat.newPleatId === newPleatId)
        : null;

    const [pleatName, setPleatName] = useState(
        newPleatId && existingPleat ? existingPleat.name : ""
    );
    const [pleatPrice, setPleatPrice] = useState(
        newPleatId && existingPleat ? existingPleat.price : ""
    );
    const [pleatImage, setPleatImage] = useState<string | null | undefined>(
        newPleatId && existingPleat ? existingPleat.image : null
    );
    const [isActive, setIsActive] = useState(
        newPleatId && existingPleat ? existingPleat.isActive : ""
    );

    useEffect(() => {
        if (isActive === "") {
            setIsActive(true);
        }
    }, [isActive]);

    const [pleatCount, setPleatCount] = useState<number>(0);
    const [isDuplicateName, setIsDuplicateName] = useState(false);

    useEffect(() => {
        if (isPleatModalOpen) {
            setPleatCount(pleatTypesWithPricing.length);
        }
    }, [isPleatModalOpen]);

    const generatePleatId = (): number => {
        const paddedId = Number(pleatCount + 1);
        return paddedId;
    };

    const generatePleatIdFromName = (name: string): string => {
        const idName = name.replace(/\s+/g, "_").toLowerCase();
        const randomSuffix = Math.floor(1000 + Math.random() * 9000);
        return `${idName}${randomSuffix}`;
    };

    const saveAvatarImage = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("accountId", accountId.toString());
            formData.append("fileStoreUrl", Config.fileStoreUrl);
            const res = await uploadImage(formData);

            if (res.error) {
                console.error("Error in upload response:", res.error);
                throw new Error(res.message);
            }

            setPleatImage(res.url);

            setIsImageModalOpen(false);
        } catch (error) {
            console.error("Error while saving avatar image:", error);
        }
    };

    const deleteAvatarImage = async () => {
        try {
            setPleatImage(null);
        } catch (error) {
            console.error("Error while deleting avatar image:", error);
        }
    };

    const [isNameRequired, setIsNameRequired] = useState(false);
    const [isPriceRequired, setIsPriceRequired] = useState(false);

    const handleSubmit = async (
        e: FormEvent<HTMLFormElement>,
        isActive: boolean,
        existingPleatId?: any
    ): Promise<void> => {
        e.preventDefault();

        const isNameValid = !!pleatName;
        const isPriceValid = !!pleatPrice;
        setIsFormValid(isNameValid && isPriceValid);
        setIsNameRequired(!isNameValid);
        setIsPriceRequired(!isPriceValid);
        if (!isNameValid || !isPriceValid) {
            return;
        }
        if (isNameValid && isPriceValid) {
            const isDuplicateName = pleatTypesWithPricing.some(
                (pleat) =>
                    pleat.name === pleatName &&
                    pleat.newPleatId !== existingPleatId &&
                    !pleat.isDeleted
            );
            if (isDuplicateName) {
                setIsDuplicateName(true);
                return;
            }
            const newPleatId = generatePleatIdFromName(pleatName);
            const generatedNewPleatId = newPleatId
                ? newPleatId
                : generatePleatId();
            console.log("New generated pleat id", generatedNewPleatId);
            const newPleatType = {
                id: String(newPleatId),
                newPleatId: String(newPleatId),
                name: pleatName,
                price: pleatPrice,
                isActive,
                image: pleatImage,
            };
            if (existingPleatId) {
                const existingPleatIndex = pleatTypesWithPricing.findIndex(
                    (pleat) => pleat.newPleatId === existingPleatId
                );

                if (existingPleatIndex !== -1) {
                    const existingPleat =
                        pleatTypesWithPricing[existingPleatIndex];
                    const updatedPleat = {
                        ...existingPleat,
                        name: pleatName,
                        price: pleatPrice,
                        isActive,
                        image: pleatImage,
                        newPleatId: String(newPleatId),
                    };

                    const updatedPleatTypes = [...pleatTypesWithPricing];
                    updatedPleatTypes[existingPleatIndex] = updatedPleat;
                    setPleatTypesWithPricing(updatedPleatTypes);
                    if (itemId) {
                        const updatedComboPayload = {
                            ...comboPayload,
                            pleatTypesWithPricing: updatedPleatTypes,
                            id: itemId,
                        };
                        await updateDraperyRecord(updatedComboPayload);

                        const updatedFormFieldUpdates: Partial<FormFields> = {
                            ...draperyItemDetailsFields,
                            pleatTypesWithPricing:
                                draperyItemDetailsFields &&
                                draperyItemDetailsFields.pleatTypesWithPricing.map(
                                    (pleat) => {
                                        if (
                                            pleat.newPleatId ===
                                            updatedPleat.newPleatId
                                        ) {
                                            return updatedPleat;
                                        }
                                        return pleat;
                                    }
                                ),
                        };
                        window.location.reload();

                        onPleatClose();
                        dispatch({
                            type: "updateFormFields",
                            formFieldUpdates: updatedFormFieldUpdates,
                        });
                    }
                } else {
                    setPleatTypesWithPricing((prevPleats) => [
                        ...prevPleats,
                        newPleatType,
                    ]);
                }
            } else {
                setPleatTypesWithPricing((prevPleats) => [
                    ...prevPleats,
                    newPleatType,
                ]);

                if (itemId) {
                    const updatedComboPayload = {
                        ...comboPayload,
                        pleatTypesWithPricing: [
                            ...pleatTypesWithPricing,
                            newPleatType,
                        ],
                        id: itemId,
                    };
                    await updateDraperyRecord(updatedComboPayload);
                }
            }
            onPleatClose();
            dispatch({
                type: "updateFormFields",
                formFieldUpdates: {
                    ...draperyItemDetailsFields,
                    pleatTypesWithPricing: [
                        ...pleatTypesWithPricing,
                        newPleatType,
                    ],
                },
            });
            const updatedPleatTypeList = [
                ...pleatTypesWithPricing,
                newPleatType,
            ];
            dispatch({
                type: "updateFormFields",
                formFieldUpdates: { pleatTypeListNew: updatedPleatTypeList },
            });
            // window.location.reload();
        }
    };

    const onPleatClose = () => {
        setPleatName("");
        setPleatPrice("");
        setIsActive(false);
        onPleatModalClose();
    };

    const handleNameInputChange = () => {
        setIsNameRequired(false);
        setIsDuplicateName(false);
    };

    const handlePriceInputChange = () => {
        setIsPriceRequired(false);
    };

    const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

    const openAvatarModal = () => {
        setIsImageModalOpen(true);
    };

    const closeAvatarModal = () => {
        setIsImageModalOpen(false);
    };

    const handlePricingInputChange = (
        e: ChangeEvent<HTMLInputElement>,
        type: string = "pleatPrice"
    ) => {
        let value = e.target.value;

        const numericValue = parseFloat(value);

        if (isNaN(numericValue) || (value.length === 1 && value[0] === ".")) {
            value = "";
        }

        if (value === "00") {
            value = "";
        }

        if (value.startsWith("0") && value.length > 1) {
            value = `0.${value.slice(1)}`;
        }

        value = value.replace(/[^\d.]/g, "");

        value = value.replace(/\.{2,}/g, ".");

        const firstDotIndex = value.indexOf(".");
        if (firstDotIndex !== -1) {
            value = `${value.slice(0, firstDotIndex + 1)}${value
                .slice(firstDotIndex + 1)
                .replace(/\./g, "")}`;
        }

        const decimalParts = value.split(".");
        if (decimalParts.length > 1) {
            value = `${decimalParts[0].slice(0, 3)}.${decimalParts[1].slice(
                0,
                2
            )}`;
        } else {
            value = value.slice(0, 3);
        }

        const keyboardEvent = e.nativeEvent as any;
        const isBackspaceAction =
            keyboardEvent.inputType === "deleteContentForward";

        if (isBackspaceAction && value === "0" && numericValue === 0) {
            value = "";
        }

        setPleatPrice(value);
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (isDuplicateName) {
            timeout = setTimeout(() => {
                setIsDuplicateName(false);
            }, 3000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [isDuplicateName]);

    return (
        <>
            <PageDimmer uiActive={isPleatModalOpen} />
            <ModalContainer
                uiActive={isPleatModalOpen}
                uiOnRequestClose={() => {
                    return;
                }}
                uiDefaultSizes={false}
            >
                <ValidationForm
                    uiOnSubmit={(e) => handleSubmit(e, isActive, newPleatId)}
                    uiReportValidityMode="firstInvalid"
                    uiPadding={false}
                    className="h-full"
                >
                    <ModalHeader className="border-0">
                        <div className="flex justify-between w-full pb-2 border-b-8 border-twSecondary">
                            <span className="text-twPrimary font-medium">
                                {modalTitle}
                            </span>
                            <Checkbox
                                type="checkbox"
                                checked={!isActive}
                                onChange={() => setIsActive(!isActive)}
                            >
                                <label className="makeInactiveLabel">
                                    Make Inactive
                                </label>
                            </Checkbox>
                        </div>
                    </ModalHeader>

                    <div className="p-4">
                        <FormContainer>
                            <LeftSection>
                                <div className="mb-4">
                                    <FormLabel>Name</FormLabel>
                                    <br />
                                    <PleatInput
                                        type="text"
                                        value={pleatName}
                                        onChange={(e) => {
                                            setPleatName(e.target.value);
                                            handleNameInputChange();
                                        }}
                                        id="pleatName"
                                        ref={pleatNameInputRef}
                                        autoComplete="off"
                                        maxLength={13}
                                    />
                                    <br></br>
                                    {isNameRequired && (
                                        <span className="text-red-500">
                                            Pleat name is required.
                                        </span>
                                    )}
                                    {isDuplicateName && (
                                        <span className="text-red-500">
                                            This pleat name is already in use.
                                        </span>
                                    )}
                                </div>

                                <div>
                                    <FormLabel>
                                        Fabrication Price{" "}
                                        <span
                                            style={{
                                                fontWeight: "400",
                                                fontStyle: "italic",
                                            }}
                                        >
                                            (per width, unlined)
                                        </span>
                                    </FormLabel>
                                    <br />
                                    <span style={{ marginRight: "5px" }}>
                                        $
                                    </span>
                                    <PleatInput
                                        className="pleatPrice"
                                        type="text"
                                        value={pleatPrice}
                                        onChange={(e) => {
                                            handlePriceInputChange();
                                            handlePricingInputChange(
                                                e,
                                                "pleatPrice"
                                            );
                                        }}
                                        id="pleatPrice"
                                        ref={pleatPriceInputRef}
                                        autoComplete="off"
                                    />
                                    <br></br>
                                    {isPriceRequired && (
                                        <span className="text-red-500">
                                            Pleat price is required.
                                        </span>
                                    )}
                                </div>
                            </LeftSection>

                            <RightSection>
                                <AvatarWrapper>
                                    <Avatar
                                        avatarId={
                                            pleatImage !== null
                                                ? pleatImage
                                                : undefined
                                        }
                                        openAvatarModal={openAvatarModal}
                                        deleteAvatarImage={deleteAvatarImage}
                                        firstName=""
                                        lastName=""
                                        isDefaultImgMountain={true}
                                        isSquareImgHolder={true}
                                    />
                                </AvatarWrapper>
                                <AvatarModal
                                    uiActive={isImageModalOpen}
                                    uiOnRequestClose={closeAvatarModal}
                                    onSave={saveAvatarImage}
                                />
                            </RightSection>
                        </FormContainer>
                    </div>
                    <ModalBottom>
                        <CancelButton
                            type="button"
                            uiColor="secondary"
                            onClick={onPleatClose}
                        >
                            Cancel
                        </CancelButton>

                        <SaveButton type="submit" uiColor="primary">
                            Save
                        </SaveButton>
                    </ModalBottom>
                </ValidationForm>
            </ModalContainer>
        </>
    );
};

export default PleatModal;
