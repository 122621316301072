// tslint:disable-next-line:no-submodule-imports
import "react-tabs/style/react-tabs.css";

import * as ClientModule from "../../redux/modules/client";

import { RootState } from "../../redux/modules";
import AdditionalContacts, {
    AdditionalContatctsProps,
} from "../../components/UI/additional-contact/additioal-contact-form";

import {
    Button,
    Checkbox,
    FormValidationError,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
    PageDimmer,
    PopoverScrollContext,
    TextArea,
    ValidationForm,
    ValidationPopupInput,
} from "@ramble/ramble-ui";
import { DispatchProp, connect } from "react-redux";
import MainInfo, { MainInfoProps } from "./client-main-info";
import React, {
    ChangeEvent,
    FormEvent,
    KeyboardEvent,
    PureComponent,
    UIEvent,
} from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import styled, { theme } from "../../theme";
import { AvatarModal } from "../../modals/avatar_modal";
import BillingInfo from "./client-billing-info";
import Bind from "lodash-decorators/bind";
import { Omit } from "@ramble/types";
import { ValidationError } from "@quipa/api";
import { detect } from "detect-browser";
import { formatPhoneNumber } from "../../utils/phone";
import { ClientAddressDetails } from "@quipa/api/build-ts/client";
import {
    addClient,
    editClient,
    getClientApi,
    searchClients,
} from "../../api/client";
import { Config } from "../../utils";
import { uploadImage } from "../../api/account";

const noop = () => {
    return;
};

const AdditionalContactsMessage = styled.div`
    font-size: 16px;
    color: red;
    text-align: center;
`;

export const TwoItemsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    > div {
        width: 48%;
    }
`;

export const ThreeItemsWrapper = styled(TwoItemsWrapper)`
    > div:nth-child(1) {
        width: 48%;
    }

    > div:nth-child(2) {
        width: 17%;
        min-width: 80px;
    }

    > div:nth-child(3) {
        width: 27%;
    }
`;

// this shit is losing the original classname when using styled()
const TabContainer = styled(TabPanel).attrs({
    className: "react-tabs__tab-panel",
})`
    height: 518px;
    overflow: auto;
    flex: 1 auto !important;
`;

export const LeftCell = styled.div`
    width: 50%;
    margin-bottom: 20px;
    padding-right: 1.25rem;
`;

export const RightCell = styled(LeftCell)`
    padding-right: 0;
    padding-left: 1.25rem;
`;

export const RightCellWithBorder = styled(RightCell)`
    border-left: 1px solid #338daa;
`;

export const ClientBlock = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    // height: 100%;
    min-height: inherit;
    // flex: 1;

    > div {
        margin-bottom: 11px;
        margin-top: 20px;
    }

    .label {
        width: 100%;
        border-radius: 0.5em;
    }

    ${TextArea} {
        width: 600px;
        height: 200px;
        margin-top: 15px;
        border: 1px solid #d2d8dd;
        border-radius: 0;
        box-shadow: 4px 4px 4px 1px #c1c2c3;
        padding: 2.5em 1.5em;

        &::placeholder {
            color: #338daa;
        }
    }
`;

export const ClientFormInput = styled(ValidationPopupInput)`
    padding: 0.35em 0.5em;
`;

export const ValidationRadioInput = styled(ClientFormInput)`
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 0;
    margin-right: 5px;
    border: 1px solid ${theme.colors.grayRGBA};
    border: ${(props) => (props.checked ? "5px solid #338daa" : "")};

    input {
        visibility: hidden;
    }
`;

export const SalesTaxInput = styled(ClientFormInput)`
    flex: 1;
`;

export const ClientFormSubtitle = styled.h4`
    color: #338daa;
    font-weight: bold;
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
    place-content: space-between;
`;

export const ClientFormFooter = styled.div`
    border-top: 1px solid #bebebe;
    display: flex;
    width: 100%;
    margin-bottom: 0 !important;
    margin-top: 1em;
    padding: 1.25em 0;

    > div {
        width: 50%;
    }

    > div:last-child {
        justify-content: flex-start;
        padding-left: 1.25rem;
    }

    .project-manager-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > label:first-child {
            margin-right: 15px;
            width: auto;
        }

        > label {
            width: 55%;
        }
    }
`;

export const ClientFormLabel = styled.label`
    color: ${theme.colors.grayRGBA};

    .red-asterisk {
        color: #ff6084;
    }
`;

const FormErrors = styled.div`
    color: #82848b;
    overflow: hidden;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin-bottom: 5px;
`;

const FormError = styled.div`
    margin: 5px;
`;

export const CancelButton = styled(Button)`
    margin-right: auto !important;
    background-color: #f6f6f6;
    border-radius: 0.5em;
    border: 1px solid #cdcdcd;
    box-shadow: none;
    // width: 130px;
    color: #222222;
    display: block;

    &:hover {
        background-color: #eeeeee;
    }
`;

export const SaveButton = styled(Button)`
    // width: 130px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
`;

export const AddButton = styled(SaveButton)`
    width: 115px;
    margin: auto auto 0 auto;
`;

const ModalContainer = styled(Modal)`
    width: 90%;
    min-height: 300px;
    bottom: 0;
`;

const ModalForm = styled(ValidationForm)`
    height: 100%;
    min-height: inherit;
`;

const ModalHeaderClient = styled(ModalHeader)`
    color: #306f82;
    border-bottom: none;
    padding-bottom: 0;

    div {
        width: 100%;
        border-bottom: 10px solid #e6f1f4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.5rem;
    }

    .modal-title {
        font-size: 26px;
    }
`;

const ModalContentClient = styled(ModalContent)`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: inherit;

    .react-tabs__tab-list {
        border-bottom: 6px solid #338daa;
        margin-bottom: 0;
    }

    .react-tabs__tab {
        margin-right: 10px;
        border-radius: 5px 5px 0 0;
        border: 1px solid #aaa;
        border-bottom: none;
        bottom: 0;
        background-color: white;
        color: ${theme.colors.grayRGBA};

        &:last-child {
            margin-right: 0;
        }
    }

    .react-tabs__tab-panel {
        flex: 1;
    }

    .react-tabs__tab--selected {
        background-color: #338daa;
        color: #fff;
        border-color: transparent;

        &:focus {
            outline: none;
        }
    }

    .react-tabs__tab-panel--selected {
        margin-top: 0;
        display: flex;
        flex-direction: column;
    }

    .react-tabs__tab:focus {
        box-shadow: none;
    }

    .react-tabs__tab:focus::after {
        position: relative;
    }
`;

export const ModalTabs = styled(Tabs)`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: inherit;
`;

export const ClientCheckbox = styled(Checkbox)`
    color: ${theme.colors.grayRGBA};
    font-size: 0.85em;

    > svg {
        width: 1.25em;
        height: 1.25em;
    }
`;

export const DisableAutocompleteInput = styled.input`
    display: none;
`;

const NameSeperator = styled.span`
    font-size: 24px;
    font-weight: 400;
    color: #9e999f;
    padding: 0 10px;
`;

const ParentName = styled.a`
    color: #9e999f;
    font-size: 20px;
`;

const Name = styled.a`
    color: #51a6c5;
    font-size: 20px;
    flex: 1;
`;

const defaultBillingFieldValues = (): ClientModule.ClientAddressDetails => ({
    firstName: "",
    lastName: "",
    streetAddress: "",
    unitSuite: "",
    cityTown: "",
    country: "",
    stateProvince: "",
    zipCode: "",
    cellPhone: "",
    homePhone: "",
    fax: "",
    company: "",
    officePhone: "",
    email: "",
    website: "",
    billingType: "",
});

const defaultFieldValues = (): ClientFields => ({
    active: true,
    id: 0,
    avatarId: "",
    firstName: "",
    lastName: "",
    displayName: "",
    email: "",
    streetAddress: "",
    unitSuite: "",
    cityTown: "",
    country: "",
    stateProvince: "",
    zipCode: "",
    cellPhone: "",
    homePhone: "",
    fax: "",
    company: "",
    officePhone: "",
    website: "",
    projectManager: "",
    parentClientId: 0,
    notes: "",
    billingSalesTax: "",
    billingType: "",
    billToParent: false,
    billingDetails: defaultBillingFieldValues(),
    billingDetailsDirectly: defaultBillingFieldValues(),
    additionalContactDetails: [],
    customDisplayName: false,
});

interface ClientModalOwnProps {
    onUpdateModalKey?(): void;
    isModalOpen: boolean;
    client?: ClientModule.Client;
    resetOnClose: boolean;
    onModalClose(): void;
    onClientSaved(client: ClientModule.Client): void;
    setFocusFirstField(fn: any): void;
    getRooms?(id: number): void;
    getClient?(): Promise<void>;

    highlightedSection?: string | null | undefined;
    tabIndex?: number;
    updateStateByName?: (
        name: string,
        value: string | number | boolean | object | number[] | undefined,
        isSaving?: boolean | undefined
    ) => void;
    clearHighlightSection?(name: string): void;
    addSubClient?: boolean;
    parentClients?: any[];
}

export interface ClientModalProps extends ClientModalOwnProps {
    accountId: number;
}

export interface ClientFields
    extends Omit<ClientModule.Client, "subClients" | "subClientsIds"> {}

interface State {
    showSubClientMenuList: boolean;
    formFields: ClientFields;
    /**
     * Only used for displaying
     */
    isOpen: boolean;
    parentClientName: string;
    parentClientId: number;
    formErrors: string[];
    imageModalOpen: boolean;
    isCustomDisplayName: boolean;
    tabIndex: number;
    areaScrollInfo: {
        scrollX: number;
        scrollY: number;
    };
    stateList: string[];
    additionalFormOpen: boolean;
    clientBillingDetails: ClientAddressDetails;
    canChange: boolean | string | undefined;
    billingType: string | undefined;
    isParentChanged: boolean;
    parentClients: number;
}

// asvetl:
// 1. Consider moving each section to own component, especially additional contact info form since it's isolated
// 2. Validation, need to have better story for validation, most errors for fields are displayed through popup, but few ones are through message block
// Thoughts:
//
// 2.1 If you want to have validation popups for all, there is a problem: hidden tabs aren't being rendered by dom (and thus aren't being validated). But it can fixed through <Tabs forceRenderTab> or so
// But if you enable it, the popup may appear for non-active tab and thus won't be visible
// To fix the UX, you need:
// 2.1.1 make Tabs as controlled component (with index/onSelect with index being the state variable) to switch tab to the correct validation
// 2.1.2 use uiOnInvalidElem/uiOnInvalidForm to obtain failed html element, then get it's index (you can put id="page" data-index={1} on page, then use elem.closest("#page").dataset to get the page index) and switch page to failed element
//
// 2.2 You can display all errors in the message list, like:
// - A is required
// - B should be URL
// 2.2.1 For this use ValidationInput instead ValidationPopupInput and pass uiOnValid/uiOnInvalid, then you can store/delete error from the state
export class ClientModal extends PureComponent<
    ClientModalProps & DispatchProp,
    State
> {
    private firstFieldRef = React.createRef<HTMLInputElement>();

    public constructor(props: ClientModalProps & DispatchProp) {
        super(props);
        const { client } = props;
        const stateList: string[] = [
            "AL",
            "AK",
            "AZ",
            "AR",
            "CA",
            "CO",
            "CT",
            "DE",
            "DC",
            "DR",
            "FL",
            "GA",
            "HI",
            "ID",
            "IL",
            "IN",
            "IA",
            "KS",
            "KY",
            "LA",
            "ME",
            "MT",
            "NE",
            "NV",
            "NH",
            "NJ",
            "NM",
            "NY",
            "NC",
            "ND",
            "OH",
            "OK",
            "OR",
            "MD",
            "MA",
            "MI",
            "MN",
            "MS",
            "MO",
            "PA",
            "RI",
            "SC",
            "SD",
            "TN",
            "TX",
            "UT",
            "VT",
            "VA",
            "WA",
            "WV",
            "WI",
            "WY",
        ];
        let sortedStateList = [...stateList];
        sortedStateList.sort((a: string, b: string) => (a > b ? 1 : -1));
        this.state = {
            areaScrollInfo: {
                scrollX: 0,
                scrollY: 0,
            },
            isParentChanged: false,
            isOpen: false,
            showSubClientMenuList: !!(client && client.parentClientId),
            imageModalOpen: false,
            isCustomDisplayName: !!client,
            tabIndex: this.props.tabIndex ? this.props.tabIndex : 0,
            formErrors: [],
            formFields: client
                ? {
                      ...client,
                      billToParent: client.billToParent,
                      billingDetails: {
                          ...client.billingDetails,
                      },
                      additionalContactDetails: [
                          ...client.additionalContactDetails,
                      ],
                  }
                : defaultFieldValues(),
            parentClientName:
                client && client.parent ? client.parent.displayName : "",
            parentClientId: client && client.parent ? client.parent.id : 0,
            clientBillingDetails: client
                ? client.billingDetails
                : defaultFieldValues(),
            stateList: sortedStateList,
            additionalFormOpen: false,
            canChange: client ? client.customDisplayName : false,
            billingType:
                client && client.billToParent
                    ? client.billingDetails.billingType
                    : client && client.billingDetailsDirectly
                    ? client.billingDetailsDirectly.billingType
                    : undefined,
            parentClients: 0,
        };
    }

    public componentDidMount() {
        if (this.props.client) {
            this.setState({
                clientBillingDetails: this.props.client.billingDetails,
            });
        }
        this.props.setFocusFirstField(this.setFocusOnFirstField);
    }
    componentDidUpdate(prevProps: any) {
        if (prevProps.client !== this.props.client) {
            const { client } = this.props;

            this.setState({
                parentClientName:
                    client && client.parent ? client.parent.displayName : "",
                parentClientId: client && client.parent ? client.parent.id : 0,
                formErrors: [],
                showSubClientMenuList: client && client.parent ? true : false,
                isCustomDisplayName: false,
                tabIndex: this.props.tabIndex ? this.props.tabIndex : 0,
                canChange: client ? client.customDisplayName : false,
            });
            // Update state or perform other actions if needed
        }
        if (prevProps.addSubClient !== this.props.addSubClient) {
            const { addSubClient, client } = this.props;
            if (addSubClient) {
                this.setState((prev) => {
                    return {
                        ...prev,
                        formFields: {
                            active: true,
                            id: addSubClient && client ? client.id : 0,
                            avatarId: "",
                            firstName: "",
                            lastName: "",
                            displayName: "",
                            email: "",
                            streetAddress: "",
                            unitSuite: "",
                            cityTown: "",
                            country: "",
                            stateProvince: "",
                            zipCode: "",
                            cellPhone: "",
                            homePhone: "",
                            fax: "",
                            company: "",
                            officePhone: "",
                            website: "",
                            projectManager: "",
                            parentClientId:
                                addSubClient && client ? client.id : 0,
                            notes: "",
                            billingSalesTax: "",
                            billingType: "",
                            billToParent: true,
                            billingDetailsDirectly: defaultBillingFieldValues(),
                            customDisplayName: false,
                            billingDetails:
                                client && !client.billToParent
                                    ? {
                                          ...client.billingDetailsDirectly,
                                      }
                                    : defaultBillingFieldValues(),
                            additionalContactDetails: client
                                ? [...client.additionalContactDetails]
                                : [],
                        },
                    };
                });
            }
            this.setState({
                showSubClientMenuList: addSubClient
                    ? true
                    : client && client.parent
                    ? true
                    : false,
                parentClientName:
                    addSubClient && client
                        ? client.displayName
                        : client && client.parent
                        ? client.parent.displayName
                        : "",
                parentClientId:
                    addSubClient && client
                        ? client.id
                        : client && client.parent
                        ? client.parent.id
                        : 0,
                isCustomDisplayName: false,
            });
        }
    }

    public setFocusOnFirstField = () => {
        if (this.firstFieldRef.current) {
            this.firstFieldRef.current.focus();
        }
    };

    public render(): JSX.Element {
        return (
            <>
                <PageDimmer uiActive={this.props.isModalOpen} />
                <AvatarModal
                    uiActive={this.state.imageModalOpen}
                    uiOnRequestClose={this.closeAvatarModal}
                    onSave={this.saveAvatarImage}
                />
                <ModalContainer
                    uiActive={this.props.isModalOpen}
                    uiOnRequestClose={noop}
                    uiDefaultSizes={false}
                >
                    <ModalForm
                        uiOnSubmit={this.handleSubmitForm}
                        uiOnInvalidElem={this.handleInvalidElem}
                        uiReportValidityMode="firstInvalid"
                        onKeyPress={this.handleKeyPressForm}
                        uiPadding={false}
                    >
                        <DisableAutocompleteInput name="firstName" />
                        <ModalHeaderClient>
                            <div>
                                <span className="modal-title">
                                    Client Profile
                                </span>
                                {(this.state.showSubClientMenuList ||
                                    this.state.formFields.displayName) && (
                                    <NameSeperator> - </NameSeperator>
                                )}
                                {this.state.showSubClientMenuList && (
                                    <>
                                        <ParentName>{`${this.state.parentClientName}`}</ParentName>
                                        <NameSeperator> | </NameSeperator>
                                    </>
                                )}
                                <Name>{`${
                                    this.state.formFields.displayName || ""
                                }`}</Name>
                                <ClientCheckbox
                                    checked={!this.state.formFields.active}
                                    onClick={this.handleChangeInactiveClient}
                                >
                                    Make Inactive
                                </ClientCheckbox>
                            </div>
                        </ModalHeaderClient>

                        <ModalContentClient>
                            <FormErrors>
                                {this.state.formErrors.map((error) => (
                                    <FormError key={error}>{error}</FormError>
                                ))}
                            </FormErrors>

                            <PopoverScrollContext.Provider
                                value={this.state.areaScrollInfo}
                            >
                                <ModalTabs
                                    selectedIndex={this.state.tabIndex}
                                    onSelect={this.selectTab}
                                    forceRenderTabPanel
                                >
                                    <TabList>
                                        <Tab>
                                            {this.state.formFields
                                                .parentClientId
                                                ? "Sub-Client Info"
                                                : "Client Info"}
                                        </Tab>
                                        <Tab>Billing Info</Tab>
                                        <Tab>Notes</Tab>
                                        <Tab>Additional Contacts</Tab>
                                    </TabList>
                                    {this.renderMainInfo()}

                                    {this.renderBillingInfo()}

                                    {this.renderNotes()}

                                    {this.renderAdditionalContactInfo()}
                                    <AdditionalContactsMessage>
                                        {this.state.isOpen
                                            ? 'Please save or close "Additional Contacts" form'
                                            : ""}
                                    </AdditionalContactsMessage>
                                </ModalTabs>
                            </PopoverScrollContext.Provider>
                        </ModalContentClient>

                        <ModalActions>
                            <CancelButton
                                type="button"
                                uiColor="secondary"
                                onClick={() => this.handleClose("cancel")}
                            >
                                Cancel
                            </CancelButton>

                            <SaveButton
                                uiColor="primary"
                                disabled={this.state.additionalFormOpen}
                                uiDisabled={this.state.additionalFormOpen}
                            >
                                Save
                            </SaveButton>
                        </ModalActions>
                    </ModalForm>
                </ModalContainer>
            </>
        );
    }

    public renderMainInfo(): JSX.Element {
        return (
            <TabContainer onScroll={this.onTabAreaScoll}>
                <MainInfo
                    formFields={this.state.formFields}
                    stateList={this.state.stateList}
                    showSubClientMenuList={this.state.showSubClientMenuList}
                    openAvatarModal={this.openAvatarModal}
                    deleteAvatarImage={this.deleteAvatarImage}
                    handleFieldChange={this.handleFieldChange}
                    handleChangeDisplayName={this.handleChangeDisplayName}
                    handleToggleSubClientMenuList={
                        this.handleToggleSubClientMenuList
                    }
                    handleFieldState={this.handleFieldState}
                    handleSelectClient={this.handleSelectClient}
                    handleSearch={this.handleSearch}
                    handleClearKeyword={this.handleClearKeyword}
                    initialParentClientName={this.state.parentClientName}
                    ref={this.firstFieldRef}
                />
            </TabContainer>
        );
    }

    public renderBillingInfo(): JSX.Element {
        return (
            <TabContainer onScroll={this.onTabAreaScoll}>
                <BillingInfo
                    formFields={
                        this.state.formFields.billToParent
                            ? this.state.formFields
                            : {
                                  ...this.state.formFields,
                                  billingDetails:
                                      this.state.formFields
                                          .billingDetailsDirectly || {},
                              }
                    }
                    stateList={this.state.stateList}
                    taxList={["Item 1", "Item 2", "Item 3"]}
                    billingTypeList={["Retail", "Trade"]}
                    showSubClientMenuList={this.state.showSubClientMenuList}
                    handleFieldChange={this.handleFieldChange}
                    handleFieldState={this.handleFieldState}
                    handleFieldFromSelect={this.handleFieldFromSelect}
                    handleInitialBillingInfoWrapper={
                        this.handleInitialBillingInfoWrapper
                    }
                    highlightedSection={this.props.highlightedSection}
                />
            </TabContainer>
        );
    }

    public renderAdditionalContactInfo(): JSX.Element {
        return (
            <TabContainer onScroll={this.onTabAreaScoll}>
                <AdditionalContacts
                    contacts={this.state.formFields.additionalContactDetails}
                    stateList={this.state.stateList}
                    additionalFormOpen={this.state.additionalFormOpen}
                    onAdditionalContactsChanged={this.changeAdditionalContacts}
                    deleteContact={this.deleteAdditionalContact}
                    isOfClient
                    setAdditionalFormStatus={this.setAdditionalFormStatus}
                    isOpen={this.state.isOpen}
                    updateParentState={this.updateParentState}
                />
            </TabContainer>
        );
    }

    public renderNotes(): JSX.Element {
        const browser = detect();
        return (
            <TabContainer onScroll={this.onTabAreaScoll}>
                <ClientBlock>
                    <div>
                        <TextArea
                            name="notes"
                            placeholder="NOTES"
                            onChange={this.handleFieldChange}
                            value={this.state.formFields.notes}
                            autoComplete={
                                browser && browser.name === "chrome"
                                    ? "new-password"
                                    : "off"
                            }
                        />
                    </div>
                </ClientBlock>
            </TabContainer>
        );
    }

    updateParentState = () => {
        this.setState({ isOpen: false });
    };

    public selectTab = (index: number) => {
        const { formFields } = this.state;

        let billingDetailsUpdate = { ...formFields.billingDetails };

        if (
            index === 1 &&
            !formFields.billToParent &&
            !this.isCustomBillingInfo(formFields.billingDetails)
        ) {
            billingDetailsUpdate = this.preFillFields(billingDetailsUpdate); // NOSONAR
        }
        this.setState({
            tabIndex: index,
            areaScrollInfo: {
                scrollX: 0,
                scrollY: 0,
            },
            formFields: {
                ...formFields,
                billingDetails: { ...formFields.billingDetails },
            },
        });
    };

    public onTabAreaScoll = (e: UIEvent<HTMLDivElement>) => {
        const { scrollTop } = e.currentTarget;
        this.setState({
            areaScrollInfo: {
                scrollX: 0,
                scrollY: scrollTop,
            },
        });
    };

    public handleInvalidElem = (e: HTMLElement): void => {
        if (e.dataset.index && +e.dataset.index !== this.state.tabIndex) {
            this.setState({ tabIndex: +e.dataset.index }, () => {
                e.scrollIntoView({ behavior: "smooth", block: "center" });
            });
        } else {
            e.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    public handleToggleSubClientMenuList = () => {
        const show = !this.state.showSubClientMenuList;
        const errors = [...this.state.formErrors];
        this.setState({
            showSubClientMenuList: show,
            parentClientName: "",
            parentClients: 0,
            formErrors: errors.filter((item) => !item.startsWith("In the client's organization system")),
            formFields: {
                ...this.state.formFields,
                parentClientId: show ? this.state.parentClientId : 0,
                billToParent: show ? this.state.formFields.billToParent : false,
                billingDetails: show
                    ? this.state.formFields.billingDetails
                    : this.preFillFields(this.state.formFields),
            },
        });
    };

    private handleClearKeyword = () => {
        this.handleSelectClient(0, "");
    };

    private handleChangeInactiveClient = () => {
        this.setState({
            formFields: {
                ...this.state.formFields,
                active: !this.state.formFields.active,
            },
        });
    };

    private handleSearch: MainInfoProps["handleSearch"] = async (text) => {
        const { accountId, client } = this.props;

        const res = await searchClients({
            text,
            accountId,
            excludeId: client ? client.id : undefined,
        });

        if (res.error) {
            return [];
        }
        return res.filter((item: any) => client ? !item.parentClients.some((parentClient: any) => parentClient.id === client.id) : true).map((r: any) => ({
            id: r.id,
            displayName: r.displayName,
            parentName: r.parent && r.parent.displayName,
        }));
    };

    private getMaxDepthIterative = (rootClient: ClientModule.Client): number => {
        let maxDepth = 0;
        const stack: { client: ClientModule.Client; depth: number }[] = [{ client: rootClient, depth: 1 }];
        if(rootClient) {
            while (stack.length > 0) {
                const {client, depth} = stack.pop()!;
                maxDepth = Math.max(maxDepth, depth);
                for (const subClient of client.subClients) {
                    stack.push({client: subClient, depth: depth + 1});
                }
            }
        }
        return maxDepth;
    }

    private handleSelectClient = async (id: number, name: string) => {
        const updatedForm = {
            ...this.state.formFields,
            billToParent: !!id,
        };
        updatedForm.parentClientId = id;
        const { accountId, client } = this.props;
        let res;
        let res2;

        if (updatedForm.billToParent) {
            res = await getClientApi({ clientId: id, accountId });
            if(client) res2 = await getClientApi({ clientId: client.id, accountId })

            if (!res.error) {
                const parent = res;
                updatedForm.billingDetails = parent.billToParent
                    ? parent.billingDetails
                    : parent.billingDetailsDirectly || {};
            }
        }
        this.setState({
            parentClientName: name,
            parentClientId: id,
            formFields: updatedForm,
            isParentChanged: id !== this.state.parentClientId,
            parentClients: id ? !this.props.client ? res ? res.parentClients.length + 1  : [] : this.getMaxDepthIterative(res2) + res.parentClients.length : 0,
        });
    };

    private handleClose = (type: string) => {
        const { client, addSubClient } = this.props;

        if (this.state.additionalFormOpen) {
            this.setState({ isOpen: true });
            return;
        }

        if (this.props.resetOnClose) {
            let formFields: ClientFields;
            if (client) {
                formFields = {
                    ...client,
                    billingDetails: {
                        ...client.billingDetails,
                    },
                    additionalContactDetails: [
                        ...client.additionalContactDetails,
                    ],
                };
            } else {
                formFields = defaultFieldValues();
            }

            this.setState({
                formFields,
                parentClientName:
                    client && client.parent ? client.parent.displayName : "",
                parentClientId: client && client.parent ? client.parent.id : 0,
                formErrors: [],
                showSubClientMenuList: addSubClient
                    ? true
                    : client && client.parent
                    ? true
                    : false,
                isCustomDisplayName: false,
                tabIndex: this.props.tabIndex ? this.props.tabIndex : 0,
                isParentChanged: false,
            });
        }
        if (
            type === "cancel" &&
            !this.state.billingType &&
            this.props.updateStateByName
        ) {
            this.props.updateStateByName(
                this.state.formFields.billToParent
                    ? "client.billingDetails.billingType"
                    : "client.billingDetailsDirectly.billingType",
                this.state.billingType
            );
        }
        this.setState({ parentClients: 0 });
        this.props.onModalClose();
    };

    private handleBillToParent = async (
        value: string,
        updatedForm: ClientFields
    ) => {
        const val = parseInt(value, 10);
        updatedForm.billToParent = val === 1;

        if (val === 1) {
            if (this.props.addSubClient && !this.state.isParentChanged) {
                if (this.props.client) {
                    if (!this.props.client.billToParent) {
                        updatedForm.billingDetails =
                            this.props.client.billingDetailsDirectly || {};
                    } else {
                        updatedForm.billingDetails = {
                            firstName:
                                this.props.client.billingDetails.firstName,
                            lastName: this.props.client.billingDetails.lastName,
                            company: this.props.client.billingDetails.company,
                            email: this.props.client.billingDetails.email,
                            cellPhone:
                                this.props.client.billingDetails.cellPhone,
                            homePhone:
                                this.props.client.billingDetails.homePhone,
                            officePhone:
                                this.props.client.billingDetails.officePhone,
                            fax: this.props.client.billingDetails.fax,
                            website: this.props.client.billingDetails.website,
                            streetAddress:
                                this.props.client.billingDetails.streetAddress,
                            unitSuite:
                                this.props.client.billingDetails.unitSuite,
                            cityTown: this.props.client.billingDetails.cityTown,
                            stateProvince:
                                this.props.client.billingDetails.stateProvince,
                            zipCode: this.props.client.billingDetails.zipCode,
                            country: this.props.client.billingDetails.country,
                            billingType:
                                this.props.client.billingDetails.billingType,
                        };
                    }
                }
            } else if (this.state.formFields.parentClientId) {
                const res = await getClientApi({
                    clientId: this.state.formFields.parentClientId,
                    accountId: this.props.accountId,
                });

                if (!res.error) {
                    const parent = res;
                    if (this.props.addSubClient && this.state.isParentChanged) {
                        updatedForm.billingDetails = parent.billToParent
                            ? parent.billingDetails
                            : parent.billingDetailsDirectly || {};
                    } else if (
                        (this.props.client &&
                            !this.props.client.parentClientId) ||
                        !this.props.client
                    ) {
                        updatedForm.billingDetails = parent.billToParent
                            ? parent.billingDetails
                            : parent.billingDetailsDirectly || {};
                    } else if (this.props.client) {
                        updatedForm.billingDetails = this.state.isParentChanged
                            ? parent.billingDetails
                            : this.props.client.billingDetails;
                    }
                    updatedForm.billingType = parent.billingType;
                    if (parent.billingType) {
                        this.handleFieldFromSelect(
                            parent.billingType,
                            "client.billingType"
                        );
                    }
                }
            }
        } else {
            if (this.props.addSubClient || this.props.client) {
                updatedForm.billingDetailsDirectly = {
                    firstName: this.state.formFields.firstName,
                    lastName: this.state.formFields.lastName,
                    company: this.state.formFields.company,
                    email: this.state.formFields.email,
                    cellPhone: this.state.formFields.cellPhone,
                    homePhone: this.state.formFields.homePhone,
                    officePhone: this.state.formFields.officePhone,
                    fax: this.state.formFields.fax,
                    website: this.state.formFields.website,
                    streetAddress: this.state.formFields.streetAddress,
                    unitSuite: this.state.formFields.unitSuite,
                    cityTown: this.state.formFields.cityTown,
                    stateProvince: this.state.formFields.stateProvince,
                    zipCode: this.state.formFields.zipCode,
                    country: this.state.formFields.country,
                    billingType: this.props.addSubClient
                        ? ""
                        : (this.state.formFields.billingDetailsDirectly &&
                              this.state.formFields.billingDetailsDirectly
                                  .billingType) ||
                          "",
                };
            } else {
                updatedForm.billingDetailsDirectly = this.preFillFields(
                    updatedForm.billingDetailsDirectly || {}
                );
                updatedForm.billingDetailsDirectly.billingType = this.state
                    .formFields.billingDetailsDirectly
                    ? this.state.formFields.billingDetailsDirectly.billingType
                    : "";
            }
        }
        return updatedForm;
    };

    private handleDisplayName = (key: string, updatedForm: ClientFields) => {
        if (
            ["firstName", "lastName", "company"].includes(key) &&
            !this.state.isCustomDisplayName
        ) {
            const { firstName, lastName, company } = updatedForm;
            let displayNames = "";
            if (this.props.addSubClient) {
                if (firstName && lastName) {
                    displayNames = `${lastName}, ${firstName}`;
                } else {
                    displayNames = lastName || firstName || company || "";
                }
                updatedForm.displayName = displayNames;
            } else if (!this.state.canChange) {
                if (firstName && lastName) {
                    displayNames = `${lastName}, ${firstName}`;
                } else {
                    displayNames = lastName || firstName || company || "";
                }
                updatedForm.displayName = displayNames;
            }
        }

        return updatedForm;
    };

    @Bind()
    private async handleInitialBillingInfo(value: string): Promise<void> {
        let updatedForm = {
            ...this.state.formFields,
        };

        updatedForm = await this.handleBillToParent(value, updatedForm);
        this.setState({ formFields: updatedForm });
    }

    @Bind()
    private handleInitialBillingInfoWrapper(value: string): void {
        this.handleInitialBillingInfo(value).catch((error: Error) => {
            throw Error(error.message);
        });
    }

    @Bind()
    private async handleFieldChange(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        val?: string
    ): Promise<void> {
        let updatedForm = {
            ...this.state.formFields,
        };
        let key = e.currentTarget.name;
        let value = e.currentTarget.value;
        if (key.endsWith("Phone")) {
            value = formatPhoneNumber(value);
        }

        if (
            (key.includes("firstName") || key.includes("lastName")) &&
            val !== "billing"
        ) {
            if (this.props.addSubClient) {
                const fullname =
                    key === "firstName"
                        ? value + ", " + this.state.formFields.lastName
                        : this.state.formFields.firstName + ", " + value;
                updatedForm = {
                    ...this.state.formFields,
                    displayName: fullname,
                };
            } else if (
                (this.state.formFields.firstName ||
                    this.state.formFields.lastName) &&
                !this.state.canChange
            ) {
                const fullName =
                    key === "firstName"
                        ? value + ", " + this.state.formFields.lastName
                        : this.state.formFields.firstName + ", " + value;
                updatedForm = {
                    ...this.state.formFields,
                    displayName: fullName,
                };
            }
        }

        if (key.includes("additionalContactDetails.")) {
            key = key.split(".").pop()!;
            updatedForm.additionalContactDetails[key] = value;
        } else if (key.includes("billingDetails.")) {
            key = key.split(".").pop()!;
            this.state.formFields.billToParent
                ? (updatedForm.billingDetails![key] = value)
                : (updatedForm.billingDetailsDirectly![key] = value);
            if (this.state.formFields.billToParent === false) {
                this.setState((prevState) => ({
                    ...prevState,
                    clientBillingDetails: {
                        ...prevState.clientBillingDetails,
                        [key]: value,
                    },
                }));
            }
        } else {
            if (key === "billToParent") {
                updatedForm = await this.handleBillToParent(value, updatedForm);
            } else {
                if (!this.state.formFields.billToParent) {
                    if (
                        updatedForm.billingDetailsDirectly &&
                        Object.entries(updatedForm.billingDetailsDirectly)
                            .length === 0
                    ) {
                        updatedForm.billingDetailsDirectly =
                            defaultBillingFieldValues();
                        updatedForm.billingDetailsDirectly[key] = value;
                    } else if (
                        updatedForm.billingDetailsDirectly &&
                        ((updatedForm.billingDetailsDirectly.hasOwnProperty(
                            key
                        ) &&
                            !this.isCustomBillingInfo(
                                updatedForm.billingDetailsDirectly,
                                key
                            )) ||
                            !updatedForm.billingDetailsDirectly.hasOwnProperty(
                                key
                            ))
                    ) {
                        updatedForm.billingDetailsDirectly[key] = value;
                    }
                }
                updatedForm[key] = value;
                updatedForm = this.handleDisplayName(key, updatedForm);
            }
        }
        this.setState({ formFields: updatedForm });
    }

    private preFillFields(
        billingDetails: ClientModule.ClientAddressDetails
    ): ClientModule.ClientAddressDetails {
        const updatedForm = {
            ...this.state.formFields,
        };
        billingDetails = defaultBillingFieldValues();
        for (const key in updatedForm) {
            if (billingDetails.hasOwnProperty(key)) {
                billingDetails[key] = updatedForm[key];
            }
        }
        return billingDetails;
    }

    private isCustomBillingInfo(
        billingDetails: ClientModule.ClientAddressDetails,
        key?: string
    ): boolean {
        const updatedForm = {
            ...this.state.formFields,
        };
        for (const k in updatedForm) {
            if (key) {
                if (billingDetails.hasOwnProperty(key) && key === k) {
                    if (
                        billingDetails[key] !== updatedForm[key] &&
                        billingDetails[key]
                    ) {
                        return true;
                    }
                }
            } else {
                if (billingDetails.hasOwnProperty(k)) {
                    if (
                        billingDetails[k] !== updatedForm[k] &&
                        billingDetails[k]
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private handleKeyPressForm(e: KeyboardEvent<HTMLElement>) {
        if (
            e.which === 13 &&
            (e.target as HTMLTextAreaElement).name !== "notes"
        ) {
            e.preventDefault();
            return;
        }
    }

    private async handleClientSubmit() {
        const { client, onClientSaved } = this.props;
        if (client) {
            const res = await editClient({
                client: { ...this.state.formFields },
            });

            if (res.error) {
                if (res.name === "ValidationError") {
                    const errors = (res as Error as ValidationError).errors;
                    if (errors.displayName) {
                        errors.displayName = errors.displayName.replace(
                            "DisplayName",
                            "Display Name"
                        );
                    }
                    throw FormValidationError.fromErrorDictionary(errors);
                } else {
                    throw new Error(res.message);
                }
            } else {
                this.setState({
                    isParentChanged: false,
                });
                onClientSaved({
                    ...this.state.formFields,
                    billingDetails: this.state.clientBillingDetails,
                    subClients: client.subClients,
                    subClientsIds: client.subClientsIds,
                });
            }
        }
    }

    @Bind()
    private async handleSubmitForm(
        e: FormEvent<HTMLFormElement>
    ): Promise<void> {
        e.preventDefault();
        const { accountId, client, onClientSaved, addSubClient } = this.props;
        if(this.state.parentClients <= 3) {
            if (!client || addSubClient) {
                const res = await addClient({
                    client: {...this.state.formFields},
                    accountId,
                });

                if (res.error) {
                    if (res.name === "ValidationError") {
                        const errors = (res as Error as ValidationError).errors;
                        if (errors.displayName) {
                            errors.displayName = errors.displayName.replace(
                                "DisplayName",
                                "Display Name"
                            );
                        }
                        throw FormValidationError.fromErrorDictionary(errors);
                    } else {
                        throw new Error(res.message);
                    }
                   
                }
            

                if(!addSubClient) onClientSaved(res);
                this.setState({
                    isParentChanged: false,
                    formFields: {
                        ...this.state.formFields,
                        canChange: this.state.formFields.customDisplayName
                            ? true
                            : false,
                    },
                });
                if (this.props.getRooms) {
                    this.props.getRooms(res.id);
                }
            } else {
                await this.handleClientSubmit();
            }
            if (this.props.onUpdateModalKey) {
                this.props.onUpdateModalKey();
            }
        if (this.props && this.props.getClient) {
                await this.props.getClient();
            }
            this.handleClose("submit");
        } else {
            const errors = [...this.state.formErrors];
            const message = "In the client's organization system, a maximum of four levels are possible.";
            if(!errors.includes(message)){
               errors.push(message);
            }
            this.setState({ formErrors: errors })
        }
    }

    private openAvatarModal = () => {
        this.setState({
            imageModalOpen: true,
        });
    };

    private closeAvatarModal = () => {
        this.setState({
            imageModalOpen: false,
        });
    };

    private saveAvatarImage = async (file: File) => {
        if (file.size > 2 * 1024 * 1024) {
            try {
                file = await this.compressImage(file);
            } catch (error) {
                throw new Error(
                    "An error occurred while compressing the image."
                );
            }
        }

        const { accountId } = this.props;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("accountId", accountId.toString());
        formData.append("fileStoreUrl", Config.fileStoreUrl);
        const res = await uploadImage(formData);
        if (res.error) {
            throw new Error(res.message);
        }
        this.setState({
            formFields: {
                ...this.state.formFields,
                avatarId: res.url,
            },
            imageModalOpen: false,
        });
    };

    private compressImage = (file: File): Promise<File> => {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const target = event.target as FileReader;
                if (!target || !target.result) return;
                const img = new Image();
                img.src = target.result as string;
                img.onload = () => {
                    const maxWidth = 1024;
                    const maxHeight = 1024;
                    let width = img.width;
                    let height = img.height;

                    if (width > maxWidth || height > maxHeight) {
                        const aspectRatio = width / height;
                        if (width > height) {
                            width = maxWidth;
                            height = width / aspectRatio;
                        } else {
                            height = maxHeight;
                            width = height * aspectRatio;
                        }
                    }

                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    if (!ctx) {
                        resolve(file);
                        return;
                    }

                    canvas.width = width;
                    canvas.height = height;

                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob(
                        (blob) => {
                            if (!blob) {
                                resolve(file);
                                return;
                            }
                            const compressedFile = new File([blob], file.name, {
                                type: file.type,
                                lastModified: file.lastModified,
                            });
                            resolve(compressedFile);
                        },
                        "image/jpeg",
                        0.7
                    );
                };
            };

            reader.readAsDataURL(file);
        });
    };

    private deleteAvatarImage = () => {
        this.setState({
            formFields: {
                ...this.state.formFields,
                avatarId: undefined,
            },
        });
    };

    private handleFieldFromSelect = (value: string, name: string): void => {
        this.setState({
            formFields: {
                ...this.state.formFields,
                [name]: value,
            },
        });

        if (name !== "billingType") {
            this.setState({
                formFields: {
                    ...this.state.formFields,
                    [name]: value,
                },
            });
        } else {
            if (this.state.formFields.billToParent) {
                this.setState({
                    formFields: {
                        ...this.state.formFields,
                        billingDetails: {
                            ...this.state.formFields.billingDetails,
                            [name]: value,
                        },
                    },
                });
            } else {
                this.setState({
                    formFields: {
                        ...this.state.formFields,
                        billingDetailsDirectly: {
                            ...this.state.formFields.billingDetailsDirectly,
                            [name]: value,
                        },
                    },
                });
            }
            if (
                this.props.clearHighlightSection &&
                this.props.highlightedSection === name
            )
                this.props.clearHighlightSection(name);
        }

        if (name === "billingType") {
            this.props.updateStateByName &&
                this.props.updateStateByName(
                    this.state.formFields.billToParent
                        ? "client.billingDetails.billingType"
                        : "client.billingDetailsDirectly.billingType",
                    value
                )!;
        }
    };

    private handleFieldState = (value: string, name: string) => {
        if (
            name === "stateProvince" ||
            name === "billingDetails.stateProvince"
        ) {
            return;
        }
        const updatedForm = {
            ...this.state.formFields,
        };

        let key = name;

        if (key.includes("billingDetails.")) {
            key = key.split(".").pop()!;
            updatedForm.billingDetails![key] = value;
        } else {
            if (!updatedForm.billToParent) {
                updatedForm.billingDetails[key] = value;
            }
            updatedForm[key] = value;
        }

        this.setState({
            formFields: updatedForm,
        });
    };

    private handleChangeDisplayName = (value: string) => {
        const { firstName, lastName } = this.state.formFields;
        const isCustomDisplayName =
            value !== "" &&
            (value !== `${lastName} ${firstName}` ||
                value !== `${firstName} ${lastName}`);
        const newValue = value.includes(",")
            ? value.split(",").join("")
            : value;
        this.setState({
            formFields: {
                ...this.state.formFields,
                displayName: value,
                customDisplayName:
                    newValue !== `${lastName} ${firstName}` &&
                    newValue !== `${firstName} ${lastName}`
                        ? true
                        : false,
            },
            isCustomDisplayName,
        });
    };

    private changeAdditionalContacts: AdditionalContatctsProps["onAdditionalContactsChanged"] =
        (contacts) => {
            this.setState({
                formFields: {
                    ...this.state.formFields,
                    additionalContactDetails: contacts,
                },
                additionalFormOpen: false,
            });
        };

    private deleteAdditionalContact = (index: number): void => {
        const updateAdditionalContacts =
            this.state.formFields.additionalContactDetails.filter(
                (_, i) => i !== index
            );

        this.setState({
            formFields: {
                ...this.state.formFields,
                additionalContactDetails: updateAdditionalContacts,
            },
        });
    };

    private setAdditionalFormStatus = (status: "open" | "closed"): void => {
        this.setState({
            additionalFormOpen: status === "open",
        });
    };
}

function mapStateToProps(
    state: RootState,
    ownProps: ClientModalOwnProps
): ClientModalProps {
    return {
        ...ownProps,
        accountId: parseFloat(localStorage.getItem("accountId") || "0"),
    };
}

export default connect(mapStateToProps)(ClientModal);
