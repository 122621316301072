import { Account, UpdateAccount, UploadImage } from "../interfaces/account";
import axiosInstance from "./axios-instance";

const getAccount = async (accountId: number): Promise<Account> => {
    try {
        const response = await axiosInstance.post<Account>("/account/get", {
            accountId,
        });
        return response.data;
    } catch (error) {
        console.error("Error getting account data:", error);
        throw error;
    }
};

const updateAccount = async (payload: UpdateAccount) => {
    try {
        const response = await axiosInstance.put<UpdateAccount>(
            "/account/update",
            payload
        );
        return response.data;
    } catch (error) {
        console.error("Error with account update:", error);
        throw error;
    }
};

const uploadImage = async (payload: FormData) => {
    try {
        const response = await axiosInstance.put<UploadImage>(
            "account/upload_image",
            payload,
            {
                headers: { "Content-Type": "multipart/form-data" },
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error with account upload image:", error);
        throw error;
    }
};

const getPlans = async (accountId: number) => {
    try {
        const response = await axiosInstance.put<any>("account/get_plans", {
            accountId,
        });
        return response.data;
    } catch (error) {
        console.error("Error with account get plans:", error);
        throw error;
    }
};

const subscribeAccount = async (payload: {
    id: number;
    paymentMethod?: string;
    planType: string;
    couponCode?: string | undefined;
    isFirstSubscribe?: boolean;
}) => {
    try {
        const response = await axiosInstance.put<any>("account/subscribe", {
            payload,
        });
        return response.data;
    } catch (error) {
        console.error("Error with account subscribe:", error);
        throw error;
    }
};

export { getAccount, updateAccount, uploadImage, getPlans, subscribeAccount };
