import axiosInstance from "./axios-instance";
import { SignUp } from "../interfaces/sign-up";

const populateBillingInfo = async (accountId: number) => {
    try {
        const response = await axiosInstance.post(
            "/signup/populate-billing-info",
            { accountId }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const sendVerifyEmail = async (email: string) => {
    try {
        const response = await axiosInstance.post("/signup/send-verify-email", {
            email,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const emailVerification = async (token: string) => {
    try {
        const response = await axiosInstance.post(
            "/signup/email-verification",
            { token }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const checkVerification = async (token: string) => {
    try {
        const response = await axiosInstance.post(
            "/signup/email-verification-check",
            { token }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const signUp = async (payload: SignUp) => {
    try {
        const response = await axiosInstance.post<SignUp>("/auth/sign-up", {
            payload,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const validateEmail = async (email: string) => {
    try {
        const response = await axiosInstance.post("/auth/validate_email", {
            email,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export {
    populateBillingInfo,
    sendVerifyEmail,
    emailVerification,
    checkVerification,
    signUp,
    validateEmail,
};
