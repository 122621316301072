import { isFlowTypeAction } from "@quipa/api";
import { DeepReadonly } from "@ramble/types";
import { Draft, Patch, produce as immerProduce, setAutoFreeze, setUseProxies } from "immer";
import { Reducer } from "redux";

setAutoFreeze(false);
setUseProxies(true);

/**
 * Contains change version of object in the state
 */
export const VERSION: unique symbol = Symbol("Version");

/**
 * Indicating that object containing symbol was constructed from state objects
 */
export const COMPOSED_OBJECT: unique symbol = Symbol("Version");

/**
 * Indicates that state either wasn't modified by reducer or returned fresh new state
 */
export const ROOT: unique symbol = Symbol("Substate fresh or non-modified root flag");

export function flagRoot(o: any) {
    Object.defineProperty(o, ROOT, {
        enumerable: false,
        configurable: false,
        writable: false,
        value: true,
    });
    return o;
}

/**
 * Immer takes a state, and runs a function against it.
 * That function can freely mutate the state, as it will create copies-on-write.
 * This means that the original state will stay unchanged, and once the function finishes, the modified state is returned.
 *
 * If the first argument is a function, this is interpreted as the recipe, and will create a curried function that will execute the recipe
 * any time it is called with the current state.
 *
 * @param currentState - the state to start with
 * @param recipe - function that receives a proxy of the current state as first argument and which can be freely modified
 * @param listener - Called with pathes and result
 * @returns The next state: a new state, or the current state if nothing was modified
 */
export function produce<S = any>(
    currentState: S,
    recipe: (this: Draft<S>, draftState: Draft<S>) => void | S,
    listener?: (patches: Patch[], inversePatches: Patch[], result: S, base: S) => void,
): S {
    let patches: Patch[] = [];
    let inversePatches: Patch[] = [];
    const res = immerProduce(currentState, recipe, (p, invp) => {
        patches = p;
        inversePatches = invp;
    });
    if (listener) {
        listener(patches, inversePatches, res, currentState);
    }
    return res;
}

type ActionHandler<A, S> = (this: Draft<S>, draft: Draft<S>, action: A, original: DeepReadonly<S>) => any;

/**
 * Create short form of reducer
 * @template Actions
 * @template S
 * @param defaultState
 * @param actions
 * @returns reducer
 */
export function actionReducer<Actions extends any, S extends any>(
    defaultState: DeepReadonly<S>,
    actions: Partial<any>,
): Reducer<S> {
    return (state: any = defaultState, action: any) => produce(state, draft => {
        if (action && actions[action.type]) {
            const handler = actions[action.type];
            let handlerFunc: ActionHandler<any, any> | undefined;
            if (typeof handler === "function") {
                handlerFunc = actions[action.type];
            } else if (typeof handler === "object" && isFlowTypeAction(action)) {
                handlerFunc = handler[action.flowType];
            }
            if (handlerFunc) {
                const res = handlerFunc(draft, action, state);
                // return only if root was set
                // many imperative operations return objects/primitives/etc and immer can blow
                // i.g. return state[id] = { a: true }; in draft reducer will return { a: true }
                // and we want to avoid it
                if (res && res[ROOT]) {
                    return res;
                }
            }
        }
    }, versionPatches);
}

/**
 * Sets version symbol for object
 * @param obj
 * @param [version]
 */
function setVersion(obj: object, version: number = 1) {
    if (!obj) {
        return;
    }
    // defineProperty() is much slower than assigning
    // howerver this need to be measured
    // Object.defineProperty(obj, VERSION, {
    //     enumerable: false,
    //     configurable: false,
    //     writable: false,
    //     value: version,
    // });
    obj[VERSION] = version;
}

/**
 * Increase version symbol for object by 1
 * @param obj Object to increase
 * @param [base] Base object to look for version
 */
function incrVersion(obj: object, base?: object): void {
    const prevVersion = base ? base[VERSION] || 0 : obj[VERSION] || 0;
    // will throw if obj has version already - but that we actually want
    setVersion(obj, prevVersion >= Number.MAX_SAFE_INTEGER ? 1 : prevVersion + 1);
    // obj[VERSION] = prevVersion >= Number.MAX_SAFE_INTEGER ? 1 : prevVersion + 1;
}

/**
 * Increment version based on immer patches
 * @param patches
 * @param _inversePatches
 * @param result
 * @returns patches
 */
export function versionPatches(patches: Patch[], _inversePatches: Patch[], result: any, base: any): void {
    if (typeof result !== "object" || !result || !(result[ROOT] || patches.length)) {
        return;
    }

    if (result[ROOT]) {
        assignInitialVersion(result);
        return;
    }

    // Tracking set to increment only single version
    const changes = new WeakSet();
    // always increase general state version
    incrVersion(result, base);
    changes.add(result);

    for (const p of patches) {
        let currObj = result;
        let baseObj = result;
        for (let i = 0; i < p.path.length; i++) {
            const path = p.path[i];
            currObj = currObj[path];
            baseObj = base[path];
            if (!currObj || typeof currObj !== "object") {
                break;
            }
            if (!changes.has(currObj)) {
                incrVersion(currObj, baseObj);
                // for last path if adding/replacing new array/object we need to set initial version for child items
                if (i === p.path.length - 1 && (p.op === "add" || p.op === "replace")) {
                    assignInitialVersion(currObj, getVersion(currObj) || 1, true);
                }
                changes.add(currObj);
            }
        }
    }
}

/**
 * Assigns initial version to the state object
 * @param state
 */
export function assignInitialVersion(state: any, initialVersion: number = 1, skipRoot: boolean = false) {
    if (!state || typeof state !== "object") {
        return;
    }
    for (const k of Object.keys(state)) {
        const val = state[k];
        if (typeof val === "object" && val) {
            assignInitialVersion(val, initialVersion);
        }
    }
    if (!skipRoot) {
        setVersion(state, initialVersion);
    }
}

/**
 * Returns version of object/array
 * @param obj
 * @returns version
 */
export function getVersion(obj: object | any[]): number {
    return (obj && obj[VERSION]) || 0;
}

const DEFAULT_ARRAY: any[] = [];

// const cacheMap = new Map<string, any>();
const objCacheMap: { [key: string]: any } = {};
// const arrayCacheMap = new Map<string, any>();
const arrayCacheMap: { [key: string]: any } = {};

/**
 * Get cache in path
 * @param path Path
 */
function getCache(path: string, type: "obj" | "arr"): any {
    const finalPath = path.split(".");
    let obj = type === "obj" ? objCacheMap : arrayCacheMap;
    for (const p of finalPath) {
        if (typeof obj[p] === "undefined") {
            return undefined;
        }
        obj = obj[p];
    }
    return obj;
}

/**
 * Set cache in path
 * @param path Path
 * @param val Value
 */
function setCache(path: string, val: any, type: "obj" | "arr"): void {
    const paths = path.split(".");
    const lastPath = paths.pop();
    if (!lastPath) {
        throw new Error("Path is empty");
    }
    let obj = type === "obj" ? objCacheMap : arrayCacheMap;
    for (const p of paths) {
        if (typeof obj[p] !== "object") {
            obj[p] = {};
        }
        obj = obj[p];
    }
    obj[lastPath] = val;
}

/**
 * Clear cache from path
 * @param path Path to clear
 */
export function clearCache(path: string): void {
    const paths = path.split(".");
    const lastPath = paths.pop();
    if (!lastPath) {
        return;
    }
    let objExists = true;
    let arrExists = true;
    let obj = objCacheMap;
    let arrObj = arrayCacheMap;
    for (const p of paths) {
        if (objExists && typeof obj[p] !== "undefined") {
            obj = obj[p];
        } else {
            objExists = false;
        }
        if (arrExists && typeof arrObj[p] !== "undefined") {
            arrObj = arrObj[p];
        } else {
            arrExists = false;
        }
    }
    if (obj && objExists) {
        obj[lastPath] = undefined;
    }
    if (arrObj && arrExists) {
        arrObj[lastPath] = undefined;
    }
}

/**
 * Get combined version of object or array of objects
 * @param obj Object or array of objects
 */
function getCombinedVersion(obj: object | any[]): number {
    if (!obj) {
        return 0;
    }
    const finalObj: any[] = Array.isArray(obj) ? obj : [obj];
    return finalObj.reduce((v, o) => v + getVersion(o), 0);
}

/**
 * Create cached objected based on object versions and cacheKey-id. When requesting object with same cacheKey-id
 * And same versions, the cached object will be returned instead and it will have same object reference
 * @param target Target object. May come directly from store or new constructed object
 * @param baseObj Base object(s) which target object was constructed from
 * @param cacheKey Cache key. Must be unique
 * @param id Cache id to use with cache key to differentiate various objects with same key
 */
export function cacheObj<T extends { [key: string]: any }>(target: T, baseObj: object | object[], cacheKey: string, id?: string | number): T {
    // versioned object -> probably comes from store directly, skip
    // Note: commented since spreading with state object may stole version
    // if (getVersion(target)) {
    //     return target;
    // }
    const version = getCombinedVersion(baseObj);
    // no version in base obj -> no cache
    // if (!version) {
    //     return target;
    // }
    let finalId = cacheKey;
    if (typeof id !== "undefined") {
        finalId += `.${id}`;
    }
    const cached = getCache(finalId, "obj");
    // cached version equals to base version, return cached
    if (cached && getVersion(cached) === version) {
        return cached;
    }
    // no cache or version differents
    // set version on target
    setVersion(target, version);
    // store into cache
    setCache(finalId, target, "obj");
    // cacheMap.set(finalId, target);
    return target;
}

/**
 * Create cached array based on array items. If all items are in given array are reference equally to cached version,
 * then cached version will be returned
 * @param arr Array
 * @param cacheKey Array cache key. Must be unique
 */
export function cacheArray<T extends any[]>(arr: T, cacheKey: string): T {
    // No array of empty - return default array reference
    if (!arr || !arr.length) {
        return DEFAULT_ARRAY as T;
    }
    // versioned array -> probably comes from store directly, skip
    if (getVersion(arr)) {
        return arr;
    }
    const cached: any[] | undefined = getCache(cacheKey, "arr");
    if (!cached) {
        // if no cache set initially and return
        setCache(cacheKey, arr, "arr");
        return arr;
    }
    // else compare object references in the array
    if (arr.length !== cached.length) {
        setCache(cacheKey, arr, "arr");
        return arr;
    }
    let equals = true;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== cached[i]) {
            equals = false;
            break;
        }
    }
    if (equals) {
        return cached as T;
    } else {
        setCache(cacheKey, arr, "arr");
        return arr;
    }
}

/**
 * Sets version symbol from other objects
 * @template T
 * @param target
 * @param args
 * @returns original object
 */
export function ofVersion<T extends object>(target: T, ...args: Array<object | any[] | undefined | null | false | 0>): T {
    // // already versioned, bail
    // since VERSION is leaking when spreading like this { ...user, profile: state.profile } this will may store wrong version
    // actually it's not problem i think since it's new object
    // if (getVersion(target)) {
    //     return target;
    // }
    let finalVersion = 0;
    // arr: ofVersion(items)
    if (!args.length && Array.isArray(target)) {
        if (!target.length) {
            return DEFAULT_ARRAY as T;
        }
        // if already has version then it's either array from the state used directly or
        // already processed by ofVersion array - no need to calculate // accumulated version
        finalVersion = getVersion(target);
        if (finalVersion) {
            return target;
        }
        // otherwise accumulated version is the sum versions of array items
        for (const i of target) {
            const itemVersion = getVersion(i);
            if (!itemVersion) {
                if (process.env.NODE_ENV !== "production") {
                    // tslint:disable-next-line:no-console
                    console.warn("Item in array is not versioned, probably you constructed it without ofVersion()");
                }
            }
            finalVersion += itemVersion;
        }
        setVersion(target, finalVersion);
        target[COMPOSED_OBJECT] = true;
        return target;
    }
    // ofVersion({ user: state.user, profile: state.profile, favorites: ofVersion(state.favorites.filter(a => a === id)) }, state.user, state.profile)
    for (const a of args) {
        if (!a) {
            continue;
        }
        if (Array.isArray(a) && !getVersion(a)) {
            // something like favorites: state.favorites.filter(a => a === id), warn
            if (process.env.NODE_ENV !== "production") {
                // tslint:disable-next-line:no-console
                console.warn("Encountered non versioned array in the object literal, wrap it with ofVersion()");
            }
            // process array, may be created new array inside of object literal.
            ofVersion(a);
        }
        const itemVersion = getVersion(a);
        if (!itemVersion) {
            if (process.env.NODE_ENV !== "production") {
                // tslint:disable-next-line:no-console
                console.warn("Item is not versioned, probably you constructed it without ofVersion()");
            }
        }
        finalVersion += itemVersion;
    }
    setVersion(target, finalVersion);
    target[COMPOSED_OBJECT] = true;
    return target;
}

/**
 * Shallow equality check + versioning check if shallow equality fails
 * * Note: This almost same performance as standard shallow equality check, only 2-3% slower
 * @param [prev]
 * @param [next]
 * @returns true if equals
 */
export function versionsEquals(prev: object = {}, next: object = {}): boolean {
    if (Object.is(prev, next)) {
        return true;
    }
    if (typeof prev !== "object" || prev === null || typeof next !== "object" && next === null) {
        return false;
    }
    const prevKeys = Object.keys(prev);
    const nextKeys = Object.keys(next);
    if (nextKeys.length !== prevKeys.length) {
        return false;
    }
    if (!nextKeys.length) {
        return true;
    }
    for (const key of nextKeys) {
        // const key = nextKeys[i];
        const nextVal = next[key];
        const prevVal = prev[key];
        // values reference equals, continue
        if (nextVal === prevVal) {
            continue;
        }
        // object references (or primitive values) are differ and it's not composed object?
        if ((typeof nextVal === "object" && nextVal != null && !nextVal[COMPOSED_OBJECT]) || (nextVal == null || typeof nextVal !== "object")) {
            return false;
        }
        const nextVer = nextVal ? nextVal[VERSION] : undefined;
        const prevVer = prevVal ? prevVal[VERSION] : undefined;

        // composed object and versions are differ?
        if (nextVer !== prevVer) {
            return false;
        }
    }
    return true;
}

function memoizedArgsEquals(prev: IArguments, next: IArguments): boolean {
    if (prev === null || next === null || prev.length !== next.length) {
        return false;
    }
    for (let i = 0; i < next.length; i++) {
        if (prev[i] !== next[i]) {
            return false;
        }
    }
    return true;
}

/**
 * Simple selector memoizing. If provided arguments are referenced equally then skip selector execution and returns last selector result
 * @template T
 * @param selector
 * @returns selector
 */
export function memoizeSelector<T extends (...args: any[]) => any>(selector: T): T {
    let lastArgs: any = null;
    let lastResult: any = null;

    // tslint:disable-next-line:only-arrow-functions
    return function() {
        if (!memoizedArgsEquals(lastArgs, arguments)) {
            lastResult = selector.apply(null, arguments as any);
        }
        lastArgs = arguments;
        return lastResult;
    } as T;
}
