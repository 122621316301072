import { Dispatch } from "redux";
import { loginHandle } from "../../../api/signin";

export function initialAppSync(token: string) {
    return async (dispatch: Dispatch) => {
        const loginRes = await loginHandle({ token });
        // if (loginRes.error) {
        //     return loginRes;
        // }

        return loginRes;
    };
}