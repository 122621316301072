import { DeepPartial } from "@ramble/types";
import {
    FailedKey,
    REALTIME_ACTION_FAILED,
    REALTIME_ACTION_REQUEST,
    REALTIME_ACTION_SUCCESS,
    ErrorResponseAction,
    SuccessResponseAction,
    RequestKey,
    SuccessKey,
} from "..";
import { RealtimeActionsDescriptors } from "../client";
import { SerializableError, SerializedError } from "../common";

/**
 * Create request realtime action
 *
 * @export
 * @template TAction
 * @param type
 * @param [payload={} as any]
 * @param [meta={}]
 * @returns
 */
export function createRequestRealtimeAction<K extends keyof RealtimeActionsDescriptors>(
    type: K = "" as any,
    payload: DeepPartial<RealtimeActionsDescriptors[K][RequestKey]["payload"]> = {} as any,
    meta: object = {},
): RealtimeActionsDescriptors[K][RequestKey] {
    const action: RealtimeActionsDescriptors[K][RequestKey] = {
        type,
        flowType: REALTIME_ACTION_REQUEST,
        error: false,
        meta: meta as any,
        payload,
    } as any;
    action.meta.realtime = false;
    return action;
}

/**
 * Create success response realtime action
 *
 * @export
 * @template TAction
 * @param type
 * @param [payload={} as any]
 * @param [meta={}]
 * @param [requestPayload={} as any]
 * @returns
 */
export function createSuccessRealtimeAction<K extends keyof RealtimeActionsDescriptors>(
    type: K = "" as any,
    payload: DeepPartial<RealtimeActionsDescriptors[K][SuccessKey]["payload"]> = {} as any,
    meta: object = {},
    requestPayload: DeepPartial<RealtimeActionsDescriptors[K][SuccessKey]["requestPayload"]> = {} as any,
): RealtimeActionsDescriptors[K][SuccessKey] {
    const action: SuccessResponseAction<any, any, any> = {
        type,
        flowType: REALTIME_ACTION_SUCCESS,
        error: false,
        meta: meta as any,
        payload,
        requestPayload,
    };
    action.meta.realtime = false;
    return action as any;
}

/**
 * Create error response realtime action
 *
 * @export
 * @template TAction
 * @param type
 * @param [errorPayload]
 * @param [requestPayload={}]
 * @param [meta={}]
 * @returns
 */
export function createErrorRealtimeAction<K extends keyof RealtimeActionsDescriptors>(
    type: K = "" as any,
    errorPayload?: SerializedError | Error | string,
    requestPayload: DeepPartial<RealtimeActionsDescriptors[K][FailedKey]["requestPayload"]> = {} as any,
    meta: object = {},
): RealtimeActionsDescriptors[K][FailedKey] {
    const error = errorPayload instanceof Error
        ? new SerializableError(errorPayload.message).serialize()
        : typeof errorPayload === "string"
            ? new SerializableError(errorPayload).serialize()
            : errorPayload || new SerializableError("Error").serialize();

    const action: ErrorResponseAction<any, any> = {
        type,
        flowType: REALTIME_ACTION_FAILED,
        error: true,
        meta: meta as any,
        payload: error,
        requestPayload,
    };
    action.meta.realtime = false;
    return action as any;
}