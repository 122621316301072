import React, { FC } from "react";
import styled from "../../../theme";
import InfoIcon from '../../../assets/icons/info.svg'

const MoreDetails = styled.button`
    display: inline-flex;
    align-items: center;
    height: 40px;
    color: #3b87a0;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    margin-left: auto;
`;

const Content = styled.span`
    display: inline-flex;
    align-items: center;

    svg {
        width: 18px;  
        height: 18px; 
    }
`;

export interface MoreDetailsButtonProps {
    isDetailsDisplayed: boolean;
    onToggleClientDetails(): void;
}

const MoreDetailsButton: FC<MoreDetailsButtonProps> = (props) => (
    <MoreDetails onClick={props.onToggleClientDetails}>
        <Content>
            <InfoIcon/>
        </Content>
    </MoreDetails>
);

export default MoreDetailsButton;
