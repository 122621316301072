import {
    CalculatorCheckbox,
    CalculatorFieldInput,
    CalculatorFormLabel,
    CalculatorFormWrapper,
    CalculatorRadio,
    CalculatorRadioGroup,
    CalculatorRadioWrapper,
    FabricButton,
    FabricButtonContainer,
    FabricImageBox,
    FabricImageSubBox,
    FabricImageView,
    PrependAppendInput,
    PrependAppendInputGroup,
    RegularInputGroupLabel,
    TooltipCell,
    TooltipContainer,
    TooltipHeader,
    TooltipImgContainer,
    TooltipPopup,
    TooltipRailroad,
    TooltipRow,
    TooltipText,
    TooltipUpTheRoll,
    TooltipWidePopup,
} from "./styles";
import React, { ChangeEvent, PureComponent } from "react";
import SearchSelect, {
    SearchSelectProps,
} from "../UI/search_select/search_select";
import styled, { css } from "../../theme";

import { Config } from "../../utils";
import { DraperyModule } from "../../redux";
import TooltipNoPleatToPattern from "../../assets/images/tooltip/no_pleat_to_pattern.png";
import TooltipPleatToPattern from "../../assets/images/tooltip/pleat_to_pattern.png";
import { highlightStyle } from "./calculator";
import { WorkroomSetting } from "../../redux/modules/workroom_setting";
import _ from "lodash";
interface FormContainerProps {
    uiDisabled?: boolean;
}

const AdditionalFormContainer = styled.div<FormContainerProps>`
    padding: 25px 20px;
    background-color: #d7d8d3;
    width: 100%;
    margin: 30px auto 0 auto;
    ${(props) =>
        props.uiDisabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

const AdditionalFormBlock = styled.div`
    background-color: #fff;
    width: 100%;
    padding: 15px 20px 60px;
    border-radius: 0.7em;
    margin-top: 25px;

    &:first-child {
        margin-top: 0;
    }
`;

const FabricWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    > div:first-child {
        width: 59%;
        margin-right: 1%;
    }

    > div:last-child {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-width: 30%;
    }
`;

const FormHeader = styled.div`
    padding: 10px 0;
    border-bottom: 1px solid black;
    font-size: 20px;
    color: #338daa;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 30px;
`;

const FabricDimensionInputGroup = styled(CalculatorFieldInput)`
    > div:first-child {
        width: 150px;
    }
`;

const CalculatorFabricFieldInput = styled(CalculatorFieldInput)`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
`;

const CalculatorFabricRadioGroup = styled(CalculatorRadioGroup)`
    padding-left: 0;
`;

const CalculatorFabricCheckbox = styled(CalculatorCheckbox)`
    margin-left: 0;
`;

const FabricTabFormLabel = styled.div`
    min-width: 72px;
`;

const AddPhotoLabel = styled(RegularInputGroupLabel)`
    color: #338daa;
    text-align: center;
`;

const SubFormHeader = styled.div`
    margin-bottom: 15px;
    width: 80%;
    padding: 10px 0;
    border-bottom: 1px solid black;
    font-size: 16px;
    font-weight: 600;
`;

const PatternMatchingFormHeader = styled(SubFormHeader)`
    min-width: 192px;
`;

export interface Props {
    mainFabric: DraperyModule.DraperyOrderFabric & { supplierName?: string };
    draperyOrder: DraperyModule.DraperyOrder & { clientName: string };
    draperyOrderDesign: DraperyModule.DraperyOrderDesign;
    disabled: boolean;
    disabledLeftover: boolean;
    highlightedSection: string | null | undefined;
    fabricsPatternItems: any;
    onSupplierSearch: SearchSelectProps["onSearch"];
    onAddSupplierByName: SearchSelectProps["onAddByName"];
    openSupplierModalClick(pathId: string, pathName: string): void;
    openFabricsPatternModalClick(pathId: string, pathName: string): void;
    openImageModal(name: string, option: "add" | "delete"): void;

    handleInputChangeForNumber(e: ChangeEvent<HTMLInputElement>): void;
    handleInputChangeForString(
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void;
    handleInputChangeForBoolean(e: ChangeEvent<HTMLInputElement>): void;
    handleSearchSelectChange(
        id: number,
        name: string,
        pathId: string,
        pathName: string,
        itemId?: number
    ): void;
    handleSearchSelectClear(pathId: string, pathName: string): void;
    onFabricsPatternSearch: SearchSelectProps["onSearch"];
    workroomSetting?: WorkroomSetting;
    orderOriginState?: number;

    updateStateByName?(
        name: string,
        value: string | number | boolean | number[] | undefined | object,
        isSaving?: boolean
    ): void;

    clearHighlightSection?(name: any): void;
    openEditFabricsPatternModalClick(
        pathName: string,
        itemId?: number | string | null
    ): void;
    workOrderEditMode: boolean;
    isCopy: boolean;
    orderType: string | undefined;
}

// tslint:disable-next-line:no-empty-interface
export interface FabricState {
    isFabricFromMyShop?: boolean;
}

class FabricsMainComponent extends PureComponent<Props, FabricState> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            isFabricFromMyShop:
                (props.fabricsPatternItems &&
                    props.fabricsPatternItems.some(
                        (item: any) => item.name === props.mainFabric.pattern
                    )) ||
                false,
        };
    }

    componentWillReceiveProps(nextProps: Readonly<Props>) {
        if (
            this.props.orderOriginState !== nextProps.orderOriginState && nextProps.orderOriginState! > 0 &&
            this.props.updateStateByName
        ) {
            this.props.updateStateByName(
                "mainFabric.orderOrigin",
                nextProps.orderOriginState
            );
        }

        if (nextProps.mainFabric) {
            const isFabricFromMyShop =
                (this.props.fabricsPatternItems &&
                    this.props.fabricsPatternItems.some(
                        (item: any) =>
                            item.name === nextProps.mainFabric.pattern
                    )) ||
                false;
            this.setState({ isFabricFromMyShop });
        }
    }

    public render() {
        const { mainFabric, workOrderEditMode, draperyOrderDesign, orderType } = this.props;
            this.props;
        const checkedPleatToPattern =
            draperyOrderDesign.headingType === "ripplefold"
                ? false
                : mainFabric.values.horizontalRepeat === 0 ||
                  mainFabric.values === undefined
                ? false
                : mainFabric.isPleatToPattern
                ? true
                : false;

        return (
            <>
                <AdditionalFormContainer uiDisabled={false} className="w-full">
                    <AdditionalFormBlock>
                        <FabricWrapper className="flex-col lg:flex-row">
                            <div className="w-full lg:w-3/5">
                                <FormHeader>MAIN FABRIC</FormHeader>
                                {this.renderInputGroup()}
                                {this.renderWhoWillOrder()}
                                <SubFormHeader>Dimensions</SubFormHeader>
                                {this.renderDimensions()}
                            </div>

                            <div className="w-full lg:w-2/6">
                                {this.renderUploadImageBox()}
                                <PatternMatchingFormHeader className="flex">
                                    Pattern Matching
                                    <TooltipWidePopup
                                        text={
                                            <>
                                                <br />
                                                <TooltipContainer>
                                                    <TooltipRow>
                                                        <TooltipCell>
                                                            <TooltipImgContainer>
                                                                <img
                                                                    src={
                                                                        TooltipPleatToPattern
                                                                    }
                                                                />
                                                            </TooltipImgContainer>
                                                        </TooltipCell>
                                                        <TooltipCell>
                                                            <TooltipImgContainer>
                                                                <img
                                                                    src={
                                                                        TooltipNoPleatToPattern
                                                                    }
                                                                />
                                                            </TooltipImgContainer>
                                                        </TooltipCell>
                                                    </TooltipRow>
                                                    <TooltipRow>
                                                        <TooltipCell>
                                                            <TooltipHeader>
                                                                PLEAT TO PATTERN
                                                            </TooltipHeader>
                                                            <TooltipText>
                                                                Positioning the
                                                                pleats on the
                                                                drapery in
                                                                alignment with a
                                                                particular
                                                                feature of the
                                                                fabric pattern.
                                                                This typically
                                                                increases the
                                                                yardage required
                                                                both
                                                                horizontally and
                                                                vertically.
                                                            </TooltipText>
                                                        </TooltipCell>
                                                        <TooltipCell>
                                                            <TooltipHeader>
                                                                STANDARD
                                                                PLEATING
                                                            </TooltipHeader>
                                                            <TooltipText>
                                                                Positioning the
                                                                pleats based on
                                                                a mathematical
                                                                calculation
                                                                without regard
                                                                to the fabric
                                                                pattern.
                                                            </TooltipText>
                                                        </TooltipCell>
                                                    </TooltipRow>
                                                    {/*<TooltipRow>
                                                        <TooltipCell><TooltipImgContainer><img src={TooltipMatchRepeat}/></TooltipImgContainer></TooltipCell>
                                                        <TooltipCell><TooltipImgContainer><img src={TooltipNoMatch}/></TooltipImgContainer></TooltipCell>
                                                    </TooltipRow>*/}
                                                    <TooltipRow>
                                                        <TooltipCell>
                                                            <TooltipHeader>
                                                                MATCH REPEAT
                                                            </TooltipHeader>
                                                            <TooltipText>
                                                                Sewing two
                                                                pieces of fabric
                                                                together so the
                                                                pattern aligns
                                                                and continues
                                                                across the seam.
                                                            </TooltipText>
                                                        </TooltipCell>
                                                        <TooltipCell>
                                                            <TooltipHeader>
                                                                NO MATCH REPEAT
                                                            </TooltipHeader>
                                                            <TooltipText>
                                                                Where matching
                                                                the pattern at
                                                                the seams is not
                                                                necessary or
                                                                undesired in
                                                                order to use
                                                                less yardage.
                                                            </TooltipText>
                                                        </TooltipCell>
                                                    </TooltipRow>
                                                </TooltipContainer>
                                            </>
                                        }
                                        position="left center"
                                        className="floatRight"
                                        size="200"
                                    />
                                </PatternMatchingFormHeader>
                                <CalculatorFormLabel className="isMatchRepeatAtSeams">
                                    <CalculatorFabricCheckbox
                                        id="mainFabric.isMatchRepeatAtSeams"
                                        name="mainFabric.isMatchRepeatAtSeams"
                                        checked={
                                            mainFabric.isMatchRepeatAtSeams
                                        }
                                        onChange={
                                            this.props
                                                .handleInputChangeForBoolean
                                        }
                                        disabled={
                                            workOrderEditMode
                                                ? false
                                                : this.props.disabled
                                        }
                                        uiDisabled={
                                            workOrderEditMode
                                                ? false
                                                : this.props.disabled
                                        }
                                    />
                                    Match Repeat at Seams/Edges
                                </CalculatorFormLabel>
                                {/* @ts-ignore */}
                                {orderType !== 'roman-shade-order' && <div>
                                    <CalculatorFormLabel
                                        className={`isPleatToPattern ${
                                            (workOrderEditMode
                                                ? false
                                                : this.props.disabled) ||
                                            mainFabric.values
                                                .horizontalRepeat ===
                                                undefined ||
                                            mainFabric.values
                                                .horizontalRepeat === 0 ||
                                            mainFabric.values
                                                .horizontalRepeat === null ||
                                            draperyOrderDesign.headingType ===
                                                "ripplefold"
                                                ? "disabledColor"
                                                : ""
                                        }`}
                                    >
                                        <CalculatorFabricCheckbox
                                            className={
                                                this.props.disabled ||
                                                mainFabric.values
                                                    .horizontalRepeat ===
                                                    undefined ||
                                                mainFabric.values
                                                    .horizontalRepeat === 0 ||
                                                mainFabric.values
                                                    .horizontalRepeat ===
                                                    null ||
                                                draperyOrderDesign.headingType ===
                                                    "ripplefold"
                                                    ? "disabledColor"
                                                    : ""
                                            }
                                            id="mainFabric.isPleatToPattern"
                                            name="mainFabric.isPleatToPattern"
                                            checked={checkedPleatToPattern}
                                            onChange={
                                                this.props
                                                    .handleInputChangeForBoolean
                                            }
                                            disabled={
                                                (workOrderEditMode
                                                    ? false
                                                    : this.props.disabled) ||
                                                mainFabric.values
                                                    .horizontalRepeat ===
                                                    undefined ||
                                                mainFabric.values
                                                    .horizontalRepeat === 0 ||
                                                mainFabric.values
                                                    .horizontalRepeat ===
                                                    null ||
                                                draperyOrderDesign.headingType ===
                                                    "ripplefold"
                                            }
                                            uiDisabled={
                                                (workOrderEditMode
                                                    ? false
                                                    : this.props.disabled) ||
                                                mainFabric.values
                                                    .horizontalRepeat ===
                                                    undefined ||
                                                mainFabric.values
                                                    .horizontalRepeat === 0 ||
                                                mainFabric.values
                                                    .horizontalRepeat ===
                                                    null ||
                                                draperyOrderDesign.headingType ===
                                                    "ripplefold"
                                            }
                                        />
                                        Pleat to Pattern
                                    </CalculatorFormLabel>
                                </div>}
                                <PatternMatchingFormHeader className="flex">
                                    Direction of Use
                                    <TooltipPopup
                                        text={
                                            <>
                                                <br />
                                                <TooltipContainer>
                                                    <TooltipImgContainer>
                                                        <TooltipUpTheRoll />
                                                    </TooltipImgContainer>
                                                    <TooltipHeader>
                                                        UP THE ROLL
                                                    </TooltipHeader>
                                                    <TooltipText>
                                                        Using the fabric up the
                                                        roll where the selvage
                                                        edge runs vertically on
                                                        the
                                                    </TooltipText>
                                                    <TooltipImgContainer>
                                                        <TooltipRailroad />
                                                    </TooltipImgContainer>
                                                    <TooltipHeader>
                                                        RAILROAD
                                                    </TooltipHeader>
                                                    <TooltipText>
                                                        Using the fabric across
                                                        the roll where the
                                                        selvage edge runs
                                                        horizontally across the
                                                        treatment.
                                                    </TooltipText>
                                                </TooltipContainer>
                                            </>
                                        }
                                        position="top right"
                                        className="floatRight"
                                        size="250"
                                    />
                                </PatternMatchingFormHeader>
                                <CalculatorFabricRadioGroup>
                                    <CalculatorRadioWrapper>
                                        <CalculatorRadio
                                            type="radio"
                                            id="directionOfUse-workroom"
                                            name="mainFabric.directionOfUse"
                                            value={1}
                                            checked={
                                                mainFabric.directionOfUse === 1
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={
                                                workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                            uiDisabled={
                                                workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                        />
                                        Up the roll
                                    </CalculatorRadioWrapper>
                                    {orderType !== 'roman-shade-order' && <CalculatorRadioWrapper>
                                        <CalculatorRadio
                                            type="radio"
                                            id="directionOfUse-Railroad"
                                            name="mainFabric.directionOfUse"
                                            value={2}
                                            checked={
                                                mainFabric.directionOfUse === 2
                                            }
                                            onChange={
                                                this.props
                                                    .handleInputChangeForNumber
                                            }
                                            disabled={
                                                workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                            uiDisabled={
                                                workOrderEditMode
                                                    ? false
                                                    : this.props.disabled
                                            }
                                        />
                                        Railroad
                                    </CalculatorRadioWrapper>}
                                </CalculatorFabricRadioGroup>
                            </div>
                        </FabricWrapper>
                    </AdditionalFormBlock>
                </AdditionalFormContainer>
            </>
        );
    }

    private renderInputGroup = (): JSX.Element => {
        const {
            onSupplierSearch,
            onFabricsPatternSearch,
            mainFabric,
            highlightedSection,
            workOrderEditMode,
        } = this.props;

        const allowEdit =
            !workOrderEditMode &&
            !mainFabric.pattern &&
            mainFabric.orderOrigin === 1
                ? true
                : !workOrderEditMode && mainFabric.pattern
                ? false
                : workOrderEditMode && !mainFabric.pattern
                ? true
                : false;

        return (
            <>
                <CalculatorFabricFieldInput
                // style={
                //     highlightedSection === "mainFabric.pattern"
                //         ? highlightStyle
                //         : {}
                // }
                >
                    <FabricTabFormLabel>
                        <CalculatorFormLabel>
                            Pattern Name / #
                        </CalculatorFormLabel>
                    </FabricTabFormLabel>
                    <SearchSelect
                        name="mainFabric.pattern"
                        openModalClick={() =>
                            this.props.openFabricsPatternModalClick(
                                "mainFabric.patternId",
                                "mainFabric.pattern"
                            )
                        }
                        searchPage="calculator"
                        searchType="calculator"
                        onItemClick={(id, name, _, itemId) => {
                            this.props.handleSearchSelectChange(
                                id,
                                name,
                                "mainFabric.patternId",
                                "mainFabric.pattern",
                                itemId
                            );
                        }}
                        onSearch={onFabricsPatternSearch}
                        onAddByName={this.props.onAddSupplierByName}
                        addButtonText="Add New Fabric to My Shop"
                        placeholder="Please select"
                        keepKeyword
                        initialValue={mainFabric.pattern}
                        onKeywordCleared={() =>
                            this.props.handleSearchSelectClear(
                                "mainFabric.patternId",
                                "mainFabric.pattern"
                            )
                        }
                        disabled={
                            workOrderEditMode ? false : this.props.disabled
                        }
                        required={true}
                        highlightedSection={highlightedSection}
                    />
                </CalculatorFabricFieldInput>
                <CalculatorFabricFieldInput
                    style={
                        highlightedSection === "mainFabric.supplierId"
                            ? highlightStyle
                            : {}
                    }
                >
                    <FabricTabFormLabel>
                        <CalculatorFormLabel>Supplier</CalculatorFormLabel>
                    </FabricTabFormLabel>
                    <SearchSelect
                        name="mainFabric.supplierId"
                        openModalClick={() =>
                            this.props.openSupplierModalClick(
                                "mainFabric.supplierId",
                                "mainFabric.supplierName"
                            )
                        }
                        searchPage="calculator"
                        searchItem="supplier"
                        searchType="calculator"
                        onItemClick={async (id, name) => {
                            this.props.handleSearchSelectChange(
                                id,
                                name,
                                "mainFabric.supplierId",
                                (await onSupplierSearch(name)).length > 0
                                    ? "mainFabric.supplierName"
                                    : "mainFabric.customSupplier"
                            );
                            if ((await onSupplierSearch(name)).length === 0) {
                                this.props.handleSearchSelectClear(
                                    "mainFabric.supplierId",
                                    "mainFabric.supplierName"
                                );
                            }
                        }}
                        onSearch={onSupplierSearch}
                        onAddByName={this.props.onAddSupplierByName}
                        placeholder="Please select"
                        keepKeyword
                        initialValue={
                            !mainFabric.customSupplier
                                ? mainFabric.supplierName
                                : mainFabric.customSupplier
                        }
                        onKeywordCleared={() =>
                            this.props.handleSearchSelectClear(
                                "mainFabric.supplierId",
                                !mainFabric.customSupplier
                                    ? "mainFabric.supplierName"
                                    : "mainFabric.customSupplier"
                            )
                        }
                        disabled={
                            allowEdit
                                ? false
                                : this.props.disabled ||
                                  this.state.isFabricFromMyShop
                        }
                        required={true}
                    />
                </CalculatorFabricFieldInput>
            </>
        );
    };

    private renderWhoWillOrder = (): JSX.Element => {
        const { mainFabric, highlightedSection, workOrderEditMode } =
            this.props;

        const allowEdit =
            !workOrderEditMode &&
            !mainFabric.pattern &&
            mainFabric.orderOrigin === 1
                ? true
                : !workOrderEditMode && mainFabric.pattern
                ? false
                : workOrderEditMode && !mainFabric.pattern
                ? true
                : false;
        return (
            <>
                <CalculatorFormLabel>Who will order?</CalculatorFormLabel>
                <CalculatorFabricRadioGroup
                    style={
                        highlightedSection === "mainFabric.orderOrigin"
                            ? highlightStyle
                            : {}
                    }
                >
                    <CalculatorRadioWrapper>
                        <CalculatorRadio
                            type="radio"
                            id="orderer-workroom"
                            name="mainFabric.orderOrigin"
                            value={1}
                            checked={
                                mainFabric.orderOrigin === 1 ||
                                (this.props.workroomSetting &&
                                mainFabric.orderOrigin === -1 &&
                                this.props.workroomSetting.fabricPurchaser ===
                                    "Workroom"
                                    ? true
                                    : undefined)
                            }
                            onChange={this.props.handleInputChangeForNumber}
                            disabled={
                                workOrderEditMode ? false : this.props.disabled
                            }
                            uiDisabled={
                                workOrderEditMode ? false : this.props.disabled
                            }
                        />
                        Workroom
                    </CalculatorRadioWrapper>
                    <CalculatorRadioWrapper>
                        <CalculatorRadio
                            type="radio"
                            id="orderer-COM"
                            name="mainFabric.orderOrigin"
                            value={2}
                            checked={
                                mainFabric.orderOrigin === 2 ||
                                (this.props.workroomSetting &&
                                mainFabric.orderOrigin === -1 &&
                                this.props.workroomSetting.fabricPurchaser ===
                                    "Installer"
                                    ? true
                                    : undefined)
                            }
                            onChange={this.props.handleInputChangeForNumber}
                            disabled={
                                workOrderEditMode ? false : this.props.disabled
                            }
                            uiDisabled={
                                workOrderEditMode ? false : this.props.disabled
                            }
                        />
                        Client (COM)
                    </CalculatorRadioWrapper>
                </CalculatorFabricRadioGroup>
                <CalculatorFormWrapper>
                    <CalculatorFormLabel
                        className="isSpecialtyFabricHandling flex items-center"
                        style={
                            highlightedSection ===
                            "mainFabric.isSpecialtyFabricHandling"
                                ? highlightStyle
                                : {}
                        }
                    >
                        <CalculatorFabricCheckbox
                            name="mainFabric.isSpecialtyFabricHandling"
                            checked={!!mainFabric.isSpecialtyFabricHandling}
                            onChange={this.props.handleInputChangeForBoolean}
                            disabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                            uiDisabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                        />
                        Specialty fabric handling
                    </CalculatorFormLabel>
                    <TooltipPopup
                        text={
                            <>
                                This is an upcharge for fabric that is difficult
                                to work with.
                            </>
                        }
                        position="top right"
                        className="floatRight"
                        size="250"
                    />
                </CalculatorFormWrapper>
            </>
        );
    };

    private renderDimensions = (): JSX.Element => {
        const {
            mainFabric,
            highlightedSection,
            draperyOrder,
            disabledLeftover,
            handleInputChangeForBoolean,
            workOrderEditMode,
        } = this.props;

        const allowEdit =
            !workOrderEditMode &&
            !mainFabric.pattern &&
            mainFabric.orderOrigin === 1
                ? true
                : !workOrderEditMode && mainFabric.pattern
                ? false
                : workOrderEditMode && !mainFabric.pattern
                ? true
                : false;

        return (
            <>
                <FabricDimensionInputGroup
                    style={
                        highlightedSection === "mainFabric.values.fabricWidth"
                            ? highlightStyle
                            : {}
                    }
                >
                    <div>
                        <CalculatorFormLabel>Fabric Width</CalculatorFormLabel>
                    </div>
                    <PrependAppendInputGroup uiSmall>
                        <PrependAppendInput
                            id="mainFabric.values.fabricWidth"
                            name="mainFabric.values.fabricWidth"
                            autoComplete="off"
                            type="number"
                            value={
                                mainFabric.values.fabricWidth !== undefined
                                    ? mainFabric.values.fabricWidth
                                    : ""
                            }
                            onChange={this.props.handleInputChangeForNumber}
                            disabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                            uiDisabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                            onBlur={this.props.clearHighlightSection}
                        />
                        <RegularInputGroupLabel>inches</RegularInputGroupLabel>
                    </PrependAppendInputGroup>
                </FabricDimensionInputGroup>
                <FabricDimensionInputGroup
                    style={
                        highlightedSection ===
                        "mainFabric.values.verticalRepeat"
                            ? highlightStyle
                            : {}
                    }
                >
                    <div>
                        <CalculatorFormLabel>
                            Vertical Repeat
                        </CalculatorFormLabel>
                    </div>
                    <PrependAppendInputGroup uiSmall>
                        <PrependAppendInput
                            id="mainFabric.values.verticalRepeat"
                            name="mainFabric.values.verticalRepeat"
                            autoComplete="off"
                            type="number"
                            value={
                                mainFabric.values.verticalRepeat !== undefined
                                    ? mainFabric.values.verticalRepeat
                                    : ""
                            }
                            onChange={this.props.handleInputChangeForNumber}
                            disabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                            uiDisabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                        />
                        <RegularInputGroupLabel>inches</RegularInputGroupLabel>
                    </PrependAppendInputGroup>
                </FabricDimensionInputGroup>
                <FabricDimensionInputGroup
                    style={
                        highlightedSection ===
                        "mainFabric.values.horizontalRepeat"
                            ? highlightStyle
                            : {}
                    }
                >
                    <div>
                        <CalculatorFormLabel>
                            Horizontal Repeat
                        </CalculatorFormLabel>
                    </div>
                    <PrependAppendInputGroup uiSmall>
                        <PrependAppendInput
                            id="mainFabric.values.horizontalRepeat"
                            name="mainFabric.values.horizontalRepeat"
                            autoComplete="off"
                            type="number"
                            value={
                                mainFabric.values.horizontalRepeat !== undefined
                                    ? mainFabric.values.horizontalRepeat
                                    : ""
                            }
                            onChange={this.props.handleInputChangeForNumber}
                            disabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                            uiDisabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                        />
                        <RegularInputGroupLabel>inches</RegularInputGroupLabel>
                    </PrependAppendInputGroup>
                </FabricDimensionInputGroup>
                <div className="flex items-center">
                    <CalculatorFormLabel className="isDropRepeat flex items-center">
                        <CalculatorFabricCheckbox
                            id="mainFabric.isDropRepeat"
                            name="mainFabric.isDropRepeat"
                            checked={mainFabric.isDropRepeat}
                            onChange={this.props.handleInputChangeForBoolean}
                            disabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                            uiDisabled={
                                allowEdit
                                    ? false
                                    : this.props.disabled ||
                                      this.state.isFabricFromMyShop
                            }
                        />
                        Drop Repeat
                    </CalculatorFormLabel>
                    <TooltipWidePopup
                        text={
                            <p className="font-normal">
                                We use the Full Drop Repeat formula to calculate
                                additional yardage needed for half and full drop
                                repeats. This formula adds one full vertical
                                repeat to the total yardage to accommodate
                                placement adjustment of each cut.
                                <br />
                                <br />
                                <span
                                    className="mb-0 font-bold"
                                    style={{ fontSize: 14 }}
                                >
                                    FULL DROP REPEAT
                                </span>
                                <br />
                                Where the motif is repeated along both the
                                horizontal and vertical lines creating a
                                square-like repeat.
                                <br />
                                <br />
                                <span
                                    className="mb-0 font-bold"
                                    style={{ fontSize: 14 }}
                                >
                                    HALF DROP REPEAT
                                </span>
                                <br />
                                Where the motif is repeated vertically, but is
                                staggered by half of the vertical repeat as you
                                move horizontally across the fabric.
                            </p>
                        }
                        position="top right"
                        className="floatRight"
                        size="250"
                    />
                </div>
                <CalculatorFormLabel className="isMatchRepeatAtSeams">
                    <CalculatorFabricCheckbox
                        id="draperyOrder.isReturnLeftoverMaterials"
                        name="draperyOrder.isReturnLeftoverMaterials"
                        checked={draperyOrder.isReturnLeftoverMaterials}
                        onChange={handleInputChangeForBoolean}
                        disabled={workOrderEditMode ? false : disabledLeftover}
                        uiDisabled={
                            workOrderEditMode ? false : disabledLeftover
                        }
                    />
                    Return Leftover Materials
                </CalculatorFormLabel>
            </>
        );
    };

    private renderUploadImageBox = (): JSX.Element => {
        const {
            fabricsPatternItems,
            mainFabric,
            workOrderEditMode,
            draperyOrder,
        } = this.props;
        const img =
            fabricsPatternItems &&
            fabricsPatternItems.filter(
                (item: any) => item.name === mainFabric.pattern
            );
        // const pathId = "mainFabric.patternId";
        // const pathName = "mainFabric.pattern";

        const allowEdit =
            !workOrderEditMode && !mainFabric.pattern
                ? true
                : !workOrderEditMode && mainFabric.pattern
                ? false
                : workOrderEditMode && !mainFabric.pattern
                ? true
                : false;

        return (
            <>
                <FabricImageBox
                    style={
                        !this.props.disabled && this.state.isFabricFromMyShop
                            ? { pointerEvents: "none", opacity: 0.5 }
                            : {}
                    }
                >
                    <FabricImageSubBox>
                        {(draperyOrder.id || this.props.isCopy) && this.props.mainFabric.imageId ? (
                            <FabricImageView
                                src={
                                    Config.fileStoreUrl +
                                    this.props.mainFabric.imageId
                                }
                            />
                        ) : !draperyOrder.id && !this.props.isCopy && (img && img[0] && img[0].avatarId && img[0].avatarId.length > 0) ? (
                            <>
                                <FabricImageView
                                    src={
                                        Config.fileStoreUrl +
                                        (img[0].avatarId && img[0].avatarId)
                                    }
                                />
                            </>
                            ) : !draperyOrder.id && !this.props.isCopy && this.props.mainFabric.imageId ? (
                                <>
                                    <FabricImageView
                                        src={Config.fileStoreUrl + this.props.mainFabric.imageId}
                                    />
                                </>
                            ) : (
                            <>
                                <AddPhotoLabel>ADD OR UPLOAD</AddPhotoLabel>
                                <AddPhotoLabel>
                                    YOUR OWN IMAGE HERE
                                </AddPhotoLabel>
                            </>
                        )}
                    </FabricImageSubBox>
                </FabricImageBox>
                <FabricButtonContainer>
                    {(workOrderEditMode ? true : !this.props.disabled) &&
                        (allowEdit || !this.state.isFabricFromMyShop ? (
                            <FabricButton
                                type="button"
                                onClick={() =>
                                    this.props.openImageModal(
                                        "mainFabric.imageId",
                                        this.props.mainFabric.imageId
                                            ? "delete"
                                            : "add"
                                    )
                                }
                            >
                                {this.props.mainFabric.imageId
                                    ? "Delete Image"
                                    : "Add Image"}
                            </FabricButton>
                        ) : (
                            <FabricButton
                                type="button"
                                onClick={() => {
                                    if (mainFabric) {
                                        this.props.openEditFabricsPatternModalClick(
                                            "mainFabric",
                                            mainFabric.pattern
                                        );
                                    }
                                }}
                            >
                                Edit Item in My Shop
                            </FabricButton>
                        ))}
                </FabricButtonContainer>
            </>
        );
    };
}

export default FabricsMainComponent;
