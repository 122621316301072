import {
    Archive,
    ClientProject,
    clientProjectGet,
    addClientInterface,
    editClientInterface,
    copyDraperyOrder,
    clientGetAll,
    clientGet,
    clientsSearch,
} from "../interfaces/client";

import axiosInstance from "./axios-instance";

const archiveClientProjectItem = async (payload: Archive) => {
    try {
        const response = await axiosInstance.put<Archive>(
            "/client/clientProjectItem/archive",
            {
                ...payload,
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error with archive client project item:", error);
        throw error;
    }
};

const editClientProject = async (payload: ClientProject) => {
    try {
        const response = await axiosInstance.put<ClientProject>(
            "/client/clientProject/edit",
            {
                ...payload,
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error with edit client project:", error);
        throw error;
    }
};

const getClientProjects = async (payload: clientProjectGet) => {
    try {
        const response: any = await axiosInstance.post<clientProjectGet>(
            `/client/clientProject/gets`,
            payload,
            {
                params: {
                    accountId: payload.accountId,
                    clientId: payload.clientId,
                    includeArchived: payload.includeArchived,
                },
            }
        );
        return { payload: response.data, error: response.error };
    } catch (error) {
        console.error("Error with get client projects:", error);
        throw error;
    }
};

const addClient = async (payload: addClientInterface) => {
    try {
        const response: any = await axiosInstance.post<addClientInterface>(
            `/client/add`,
            { ...payload }
        );

        return response.data;
    } catch (error) {
        console.error("Error with add client:", error);
        throw error;
    }
};

const editClient = async (payload: editClientInterface) => {
    try {
        const response: any = await axiosInstance.post<editClientInterface>(
            `/client/edit`,
            { ...payload.client }
        );

        return response.data;
    } catch (error) {
        console.error("Error with edit client:", error);
        throw error;
    }
};

const getAllClients = async (payload: clientGetAll) => {
    try {
        const response: any = await axiosInstance.get<clientGetAll>(
            `/client/get_all?accountId=${payload.accountId}&sort=${payload.sort}&includeInactive=${payload.includeInactive}`
        );

        return response.data;
    } catch (error) {
        console.error("Error with get all clients:", error);
        throw error;
    }
};

const getClientApi = async (payload: clientGet) => {
    try {
        const response: any = await axiosInstance.get<clientGet>(
            `/client/get?clientId=${payload.clientId}${payload.accountId ? `&accountId=${payload.accountId}` : ""}`
        );

        return response.data;
    } catch (error) {
        console.error("Error with get clients:", error);
        throw error;
    }
};

const searchClients = async (payload: clientsSearch) => {
    try {
        const response: any = await axiosInstance.post<clientsSearch>(
            `/client/search_clients?accountId=${payload.accountId}&excludeId=${payload.excludeId}&includeInactive=${payload.includeInactive}`,
            { text: payload.text }
        );

        return response.data;
    } catch (error) {
        console.error("Error with search clients:", error);
        throw error;
    }
};

const draperyOrderCopy = async (payload: copyDraperyOrder) => {
    try {
        const response = await axiosInstance.post<copyDraperyOrder>(
            `/client/drapery_order_copy`,
            {
                ...payload,
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error with copy drapery order:", error);
        throw error;
    }
};

const deleteClientProject = async (payload: ClientProject) => {
    try {
        const response = await axiosInstance.post(
            "/client/clientProject/delete",
            { ...payload }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getAllProjects = async (
    accountId: number,
    includeArchived: boolean = false,
    sort: { field: string; direction: "ASC" | "DESC" },
    limit: number,
    pageNumber: number,
    searchTerm: any
) => {
    try {
        const response = await axiosInstance.post("/client/get-all-projects", {
            accountId,
            includeArchived,
            sort,
            limit,
            pageNumber,
            searchTerm,
        });
        return response.data;
    } catch (error) {
        console.error("Error with get all projects:", error);
        throw error;
    }
};

export {
    archiveClientProjectItem,
    editClientProject,
    draperyOrderCopy,
    getClientProjects,
    searchClients,
    getClientApi,
    editClient,
    getAllClients,
    addClient,
    deleteClientProject,
    getAllProjects,
};
