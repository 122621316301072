import axiosInstance from "./axios-instance";
const getAllInfo = async (draperyOrderId: number) => {
    try {
        const response = await axiosInstance.get(
            `/draperyOrder/getAllInfo?id=${draperyOrderId}`
        );

        return response.data;
    } catch (error) {
        console.error("Error getting all info for drapery order:", error);
        throw error;
    }
};
const addDraperyOrder = async (payload: any) => {
    try {
        const response = await axiosInstance.post(`/draperyOrder/add`, payload);

        return response.data;
    } catch (error) {
        console.error("Error adding new drapery order:", error);
        throw error;
    }
};
const editDraperyOrder = async (payload: any) => {
    try {
        const response = await axiosInstance.put(`/draperyOrder/edit`, payload);

        return response.data;
    } catch (error) {
        console.error("Error with edit drapery order:", error);
        throw error;
    }
};
const addDraperyOrderItem = async (payload: any) => {
    try {
        const response = await axiosInstance.post(
            `/draperyOrder/addItem`,
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error adding new drapery order item:", error);
        throw error;
    }
};
export { getAllInfo, addDraperyOrder, addDraperyOrderItem, editDraperyOrder };
