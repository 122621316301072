import "lato-font";

import { Redirect, Route, Router, Switch } from "react-router-dom";
import { ThemeProvider, createGlobalStyle, theme } from "./theme";
const { version } = require("../package.json")
import { seedItemsOnLogin } from './api/item'
import { ApplicationContainer } from "./containers/application";
import { ApplicationStore } from "./redux/modules";
import { Crisp } from "crisp-sdk-web";
import { AuthContainer } from "./containers/auth";
// import { CreditCardContainer } from "./pages/creditcard";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/error-fallback";
import { History } from "history";
import { LoginContainer } from "./pages/login";
import { PasswordResetContainer } from "./pages/password_reset";
import { Provider } from "react-redux";
import React, { useEffect, useState } from "react";
import { RequestResetPasswordContainer } from "./pages/request_reset_password";
import { SignupContainer } from "./pages/signup";
import { hot } from "react-hot-loader";
// tslint:disable-next-line:no-submodule-imports
import { normalize } from "@ramble/ramble-ui";
import { ProceedToCheckout } from "./components/stripe/proceedToCheckout";
import { EmailVerificationIssue } from "./pages/email_verification_issue";
import { getEmailVerificationList } from "./api/email-verification-list";
// import { initialAppSync } from "./redux/modules/application";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import { createPaymentIntent } from "./api/stripe";
//test commit
// tslint:disable-next-line:no-unused-expression
const GlobalStyle = createGlobalStyle`
    /* From semantic-ui global styles */
    /* stylelint-disable */
    /* Border-Box */
    *,
    *::before,
    *::after {
        -webkit-box-sizing: inherit;
        box-sizing: inherit;
    }

    html {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    ${normalize};

    html,
    body {
        height: 100%;
    }

    html {
        font-size: 15px;
    }

    body {
        margin: 0px;
        padding: 0px;
        min-width: 320px;
        background: #FFFFFF;
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        font-size: 15px;
        line-height: 1.4285em;
        color: ${theme.colors.grayRGBA};
        font-weight: 400;
        font-smoothing: antialiased;
    }

    /*******************************
                Headers
    *******************************/

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: 'Poppins', Arial, Helvetica, sans-serif;
        line-height: 1.28571429em;
        margin: 10px 0;
        font-weight: 200;
        padding: 0em;
    }

    h1 {
        min-height: 1rem;
        font-size: 2rem;
    }

    h2 {
        font-size: 1.71428571rem;
    }

    h3 {
        font-size: 1.28571429rem;
    }

    h4 {
        font-size: 1.07142857rem;
    }

    h5 {
        font-size: 1rem;
    }

    /*******************************
                Text
    *******************************/

    p {
        margin: 0em 0em 1em;
        line-height: 1.4285em;
    }

    p:first-child {
        margin-top: 0em;
    }

    p:last-child {
        margin-bottom: 0em;
    }

    /*-------------------
            Links
    --------------------*/

    a {
        color: #4183C4;
        text-decoration: none;
    }

    a:hover {
        color: #1e70bf;
        text-decoration: none;
    }

    /*******************************
            Scrollbars
    *******************************/

    /*******************************
            Highlighting
    *******************************/

    /* Site */

    ::-webkit-selection {
        background-color: #CCE2FF;
        color: rgba(0, 0, 0, 0.87);
    }

    ::-moz-selection {
        background-color: #CCE2FF;
        color: rgba(0, 0, 0, 0.87);
    }

    ::selection {
        background-color: #CCE2FF;
        color: rgba(0, 0, 0, 0.87);
    }

    /* Form */

    button,
    input,
    optgroup,
    select,
    textarea {
        line-height: inherit; /* 1 */
    }

    textarea::-webkit-selection,
    input::-webkit-selection {
        background-color: rgba(100, 100, 100, 0.4);
        color: rgba(0, 0, 0, 0.87);
    }

    textarea::-moz-selection,
    input::-moz-selection {
        background-color: rgba(100, 100, 100, 0.4);
        color: rgba(0, 0, 0, 0.87);
    }

    textarea::selection,
    input::selection {
        background-color: rgba(100, 100, 100, 0.4);
        color: rgba(0, 0, 0, 0.87);
    }

    /* Force Simple Scrollbars */
    body input[type="number"]::-webkit-outer-spin-button,
    body input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    body input[type="number"] {
        -moz-appearance: textfield;
    }

    body ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
    }

    body ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 0px;
    }

    body ::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.25);
        -webkit-transition: color 0.2s ease;
        transition: color 0.2s ease;
    }

    body ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(0, 0, 0, 0.15);
    }

    body ::-webkit-scrollbar-thumb:hover {
        background: rgba(128, 135, 139, 0.8);
    }
    /* stylelint-enable */
`;

export interface RootProps {
    /**
     * Store
     */
    store: ApplicationStore;
    /**
     * History
     */
    history: History;
}

/**
 * Return token from store
 */
function getTokenFromStore(store: ApplicationStore): string | undefined {
    return store.getState().user.token;
}

function isLoaded(): boolean {
    return (
        localStorage.getItem("status") === "subscribed" ||
        !localStorage.getItem("status")
    );
}

// function getRefreshTokenFromStore(store: ApplicationStore): string | undefined {
//     return store.getState().user.refreshToken;
// }

/**
 * Return redirect location descriptor for cases where token is not available
 */
function getRedirect(
    store: ApplicationStore,
    pathname: string,
    search: string | undefined
): History.LocationDescriptor {
    const isSubscribed = store.getState().account.type === "subscribed";
    const isBeta = store.getState().account.isBeta;
    return {
        pathname: isSubscribed || isBeta ? "/login" : "/card",
        state: {
            redirectAfterLogin: pathname,
            redirectAfterLoginSearch: search,
        },
    };
}

function applicationAllowed(store: ApplicationStore): boolean {
    const user = localStorage.getItem("quipa-store");
    const hasToken = user ? JSON.parse(user).user.token : "";

    // if (hasToken && hasToken !== "") {
    //     const res: any = await initialAppSync(hasToken);

    //     localStorage.setItem("accountId", res.account.id);
    //     localStorage.setItem("firstName", res.user.firstName);
    //     localStorage.setItem("lastName", res.user.lastName);
    //     localStorage.setItem("role", res.user.role);
    //     localStorage.setItem("avatarId", res.account.avatarId);
    //     localStorage.setItem("status", res.account.type);
    //     if (res.user.logoId) {
    //         localStorage.setItem("logoId", res.user.logoId);
    //     }
    // }

    const isSubscribed = localStorage.getItem("status") === "subscribed";

    // const loaded = store.getState().application.loaded;
    // const isSubscribed = store.getState().account.type === "subscribed";
    const isBeta = store.getState().account.isBeta;

    if ((isSubscribed || isBeta) && hasToken) {
        return true;
    }
    // application will display loading page and will redirect to login / card when needed
    if (hasToken && hasToken !== "" && isSubscribed) {
        return true;
    }

    return false;
}

/**
 * Root component
 *
 * @param props
 */
function Root(props: RootProps): JSX.Element {
    const { history, store } = props;
    const token = getTokenFromStore(store);
    Crisp.configure("2ae9d129-4921-4224-a351-bd47db293d11");
    const [isVerified, setIsVerified] = useState<boolean>(false);
    if (token !== "") {
        Crisp.setTokenId(token ? token : "");
    }

    if (!localStorage.getItem('reloadDone') || localStorage.getItem('version') !== version) {
        // Set the flag to prevent future reloads
        localStorage.setItem('reloadDone', 'true');
        localStorage.setItem('version', version);
           // add random ? to refresh server side
           const url = new URL(window.location.href);
           url.searchParams.set('reloadTime', Date.now().toString());
           window.location.href = url.toString();
           // reload
           window.location.reload();
       }

    // const [options, setOptions] = useState({ clientSecret: "" });
    // const [fetched, setFetched] = useState(false);

    // const stripePromise = loadStripe("pk_test_1SiljWdVwCkxpoBGYxDSagtF");

    // useEffect(() => {
    //     if (!fetched) {
    //         createPaymentIntent().then((data) => {
    //             if (data) {
    //                 setFetched(true);
    //                 setOptions({ clientSecret: data.client_secret });
    //             }
    //         });
    //     }
    // }, []);

    // tslint:disable:jsx-alignment

    const handleSeedOtherItems = async () => {
        if (typeof window !== 'undefined') {
            const alreadySeeded = window.localStorage.getItem('seed-other-item') || 'null'
            const parsedAlreadySeeded = JSON.parse(alreadySeeded)
            if (!parsedAlreadySeeded) {
                await seedItemsOnLogin()
                window.localStorage.setItem('seed-other-item', JSON.stringify(true))
            }
        }
    }

    useEffect(() => {
        handleSeedOtherItems()
        window.addEventListener("storage", (event) => {
            if (event && event.key && event.key.startsWith("logout-event")) {
                localStorage.setItem("showModal", "true");
                window.location.href = "/login";
            }
            if (event && event.key && event.key.startsWith("login-event")) {
                window.location.href = "/";
            }
        });
    }, []);

    useEffect(() => {
        const email = store.getState().user.email;
        if (email) {
            getEmailVerificationList(email).then((res) => {
                setIsVerified(res && res.isVerified);
            });
        }
    }, []);

    return (
        <Provider store={store}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ThemeProvider theme={theme}>
                    <>
                        <GlobalStyle />
                        <Router history={history}>
                            <Switch>
                                <Route
                                    path="/quickbook_oauth"
                                    render={(props) => {
                                        const {
                                            location: { query },
                                        } = props;
                                        if (query && window.opener) {
                                            const code = query.code;
                                            const realmid = query.realmId;
                                            const error = query.error;

                                            const payload = error
                                                ? {
                                                      error,
                                                      error_description:
                                                          error ===
                                                          "access_denied"
                                                              ? "The user did not authorize the request."
                                                              : undefined,
                                                  }
                                                : {
                                                      code,
                                                      realmid,
                                                  };
                                            const ev = new CustomEvent(
                                                "oauthResult",
                                                {
                                                    detail: payload,
                                                }
                                            );

                                            (
                                                window.opener as Window
                                            ).dispatchEvent(ev);
                                            window.close();
                                        }
                                        return null;
                                    }}
                                />

                                <Route
                                    path="/card"
                                    render={(props) => {
                                        if (isLoaded()) {
                                            return getTokenFromStore(store) ? (
                                                <ApplicationContainer
                                                    {...props}
                                                />
                                            ) : (
                                                <Redirect to="/login" />
                                            );
                                        }
                                        return (
                                            <AuthContainer>
                                                <ProceedToCheckout />
                                                {/* {options.clientSecret ? (
                                                    <Elements
                                                        stripe={stripePromise}
                                                        options={options}
                                                    >
                                                        <CheckoutForm
                                                            clientSecret={
                                                                options.clientSecret
                                                            }
                                                        />
                                                    </Elements>
                                                ) : undefined} */}
                                            </AuthContainer>
                                        );
                                    }}
                                />

                                <Route
                                    path="/login"
                                    render={(props) =>
                                        getTokenFromStore(store) ? (
                                            <Redirect to="/" />
                                        ) : (
                                            <AuthContainer>
                                                <LoginContainer {...props} />
                                            </AuthContainer>
                                        )
                                    }
                                />
                                <Route
                                    path="/signup"
                                    render={(props) =>
                                        getTokenFromStore(store) ? (
                                            <Redirect to="/" />
                                        ) : (
                                            <AuthContainer>
                                                <SignupContainer {...props} />
                                            </AuthContainer>
                                        )
                                    }
                                />
                                <Route
                                    path="/request_reset"
                                    render={(props) =>
                                        getTokenFromStore(store) ? (
                                            <Redirect to="/" />
                                        ) : (
                                            <AuthContainer>
                                                <RequestResetPasswordContainer
                                                    {...props}
                                                />
                                            </AuthContainer>
                                        )
                                    }
                                />
                                <Route
                                    path="/password_reset"
                                    render={(props) =>
                                        getTokenFromStore(store) ? (
                                            <Redirect to="/" />
                                        ) : (
                                            <AuthContainer>
                                                <PasswordResetContainer
                                                    {...props}
                                                />
                                            </AuthContainer>
                                        )
                                    }
                                />
                                <Route
                                    path="/email-verification-issue"
                                    render={(props) =>
                                        getTokenFromStore(store) &&
                                        isVerified ? (
                                            <Redirect to="/" />
                                        ) : (
                                            <AuthContainer>
                                                <EmailVerificationIssue
                                                    {...props}
                                                />
                                            </AuthContainer>
                                        )
                                    }
                                />
                                <Route
                                    path="/"
                                    render={(props) =>
                                        applicationAllowed(store) ? (
                                            <ApplicationContainer {...props} />
                                        ) : (
                                            <Redirect
                                                to={getRedirect(
                                                    store,
                                                    props.location.pathname,
                                                    props.location.search
                                                )}
                                            />
                                        )
                                    }
                                />
                            </Switch>
                        </Router>
                    </>
                </ThemeProvider>
            </ErrorBoundary>
        </Provider>
    );
    // tslint:enable:jsx-alignment
}
(Root as any).displayName = "Root";
export default hot(module)(Root);
