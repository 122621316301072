import React from "react";

export const poppinsFont = () => {
    const base64 = `
        d09GRgABAAAAAFVIABMAAAAAoEwAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABqAAAABwAAAAchHo0qUdERUYAAAHEAAAAUQAAAGwHFgeNR1BPUwAAAhgAAAAxAAAAQB9mJ35HU1VCAAACTAAAARIAAAQEYBhbuU9TLzIAAANgAAAATgAAAGDp74B/Y21hcAAAA7AAAAGIAAAB4uXMQihjdnQgAAAFOAAAADwAAAA8DlgR6GZwZ20AAAV0AAABsQAAAmVTtC+nZ2FzcAAABygAAAAIAAAACAAAABBn
        bHlmAAAHMAAARAoAAITsViT842hlYWQAAEs8AAAANgAAADYTEPdvaGhlYQAAS3QAAAAgAAAAJBEuBllobXR4AABLlAAAAlYAAAOe+ldbgGxvY2EAAE3sAAAByAAAAdJm3UcabWF4cAAAT7QAAAAgAAAAIAIFAX9uYW1lAABP1AAAAtcAAAaYUNR1PHBvc3QAAFKsAAAB5AAAAslAoRyVcHJlcAAAVJAAAACtAAABCwh5HcN3ZWJmAABVQAAAAAYAAAAG+wxdlQAAAAEAAAAA2SyH9gAAAADRkgEoAAAAANm7
        q4p42h3MSw5EABQF0eIZikXRSzHBBEm3NNvzWQm2oSKVm5zRJQFyV7uUgoTSVRZ8aHRrQUevBwtGvvpnwcRfzxYsrHpj1wenvrh9zd5vHnyxDqQAAAB42mNgZGBg4GKwYbBjYHZx8wlhEElJLTNikAOSiQwaDCxAWYb//4EENhYQAABPZwskAAAAeNpjYGRgYOBiiGCYwcDs4uYTwiCSklpmxKABJBMZbBhYgLIM//8zsAIpRgYmBmagCD4xEA0RYU1MKitmUEjMzshj0MjJTE9k0CsoSi1mMCkoLilmsIKr
        A5nBCCbZoOaBSFYGdgYBBjkGNYYYhhSGHIYSsCwLkNUEdOk6qNptYFqC4RTDA4ZvjDyMSowWjH6MKYxVjBMYlzHuYbzE+ILxH5MIkxaTA1MYUw5TE9MMpnVMRyC6mZZA6W1Q+hTENUw3gO4Cmc4HxEJgFgRTR5QJ6C8RIE8CSZx6po+KDidRJqCIGJSPLMMETEESQLYAijhEDmQKyDRUGSkg5gDqY2PgYXgOpP0ZngFJH3De8wQAreIyYgAAeNpjYGHJZ5zAwMrAwmrMcpaBgWEWhGY6y5DGtAhIMyBAAzsS
        h8EzxM2fsYGB9zcTW9q/NAYGjjnMqxUYGCaD5FhSWLuBlAIDMwCKGg3NAAB42mNgYGBmgGAZBkYGELgD5DGC+SwMB4C0DoMCkMUDZPEy1DH8ZwxmrGA6xnRHgUtBREFKQU5BSUFNQV/BSiFeYY2ikuqf30z//4PN4QXqW8AYBFXNoCCgIKEgA1VtCVfNCFTN+P/r/8f/D/0v+O/z9//fVw+OPzj0YP+DfQ92P9jxYMOD5Q+aH5jfP3TrJetTqAuJBoxsDHAtjExAggldAdDrLKxs7BycXNw8vHz8AoJCwiKi
        YuISklLSMrJy8gqKSsoqqmrqGppa2jq6evoGhkbGJqZm5haWVtY2tnb2Do5Ozi6ubu4enl7ePr5+/gGBQcEhoWHhEZFR0TGxcfEJiQxt7Z3dk2fMW7xoybKly1euXrVm7fp1GzZu3rpl247te3bv3cdQlJKaebdiYUH2k7Isho5ZDMUMDOnlYNfl1DCs2NWYnAdi59beS2pqnX7o8NVrt25fv7GT4eARhscPHj57zlB58w5DS09zb1f/hIl9U6cxTJkzdzbD0WOFQE1VQAwAG5yKswAABGQFiwCTAP4AjwCZ
        AJ0AogCmAMMAhwC6ALYAugC+AMUAzwD8AK8AsgCgAJYAtACLALgAhQCJAEQFEXjaXVG7TltBEN0NDwOBxNggOdoUs5mQxnuhBQnE1Y1iZDuF5QhpN3KRi3EBH0CBRA3arxmgoaRImwYhF0h8Qj4hEjNriKI0Ozuzc86ZM0vKkap36WvPU+ckkMLdBs02/U5ItbMA96Tr642MtIMHWmxm9Mp1+/4LBpvRlDtqAOU9bykPGU07gVq0p/7R/AqG+/wf8zsYtDTT9NQ6CekhBOabcUuD7xnNussP+oLV4WIwMKSY
        puIuP6ZS/rc052rLsLWR0byDMxH5yTRAU2ttBJr+1CHV83EUS5DLprE2mJiy/iQTwYXJdFVTtcz42sFdsrPoYIMqzYEH2MNWeQweDg8mFNK3JMosDRH2YqvECBGTHAo55dzJ/qRA+UgSxrxJSjvjhrUGxpHXwKA2T7P/PJtNbW8dwvhZHMF3vxlLOvjIhtoYEWI7YimACURCRlX5hhrPvSwG5FL7z0CUgOXxj3+dCLTu2EQ8l7V1DjFWCHp+29zyy4q7VrnOi0J3b6pqqNIpzftezr7HA54eC8NBY8Gbz/v+
        SoH6PCyuNGgOBEN6N3r/orXqiKu8Fz6yJ9O/sVoAAAAAAQAB//8AD3ja3b0LfBTXlSdct179flQ/1Xp2qyW1sQxtdSO0CgbbBAgmDktYhmWJBjOYYIyNHUIIw7D6WD6GEEwIBgxmCCYsJgzL8iNVrQZjgTEPY0IISxjWMCxh/CMehsgmBBMGY6G+fOfcW91qCeHHZHd2fp+N1F3Vrap7zz33nP95liAKwwVBnKb8mSAJFmGAQYTkIxmLXP77lKEqv3kkI4nwVjAkPK3g6YxFreh6JEPwfFqLabUxLTZcjNIa
        sp7OUP6s878Pl08KcEkyGH7VK11wVYcwRshYBKHekOQOojuTOjlrKL4O/GmzKsRab9i8HW12m9Van3W4hIRcrzuSWTt7Z7hIvWF3aL42SbWINeFm4eGGmoGDUqGAWi2lpTgZPHLayJHTGg8H1rM3I5WuOzvh1sIw+ZhoVSezeY0QdCmpq+msIAsOuLqcMt8R3ZrUpbNZ0SWUw3nRa1hIfVZlR4YN7mwRNZ9B5Ga8rR9uhz/DMpFFXZmSRepk+ix5lT4r4HyFJwVBHadcFcqEKvI1IVMK880EQ5F0Oq0LybZA
        uKSsJpw2iKWjTdTKK2rCKV1Otkneyio8rVg72lSb3YWnLcmM1eGEvyN6NKmXnjUirg49wkZmWF0dGYvVXt/2mEW21etWrxGCs0E4Gwzh2aAfzga9hgPOOl0dRozU64NK24e+cuMVIVhvbx+65sZqfKOXetvEUou/vk1iv1X8DTdps0Ws8CbkbbOHHH68VJsr6IQveNlvjf0O4G/8Tph9B/6qhP0VXLMsf53y/HUq8DttlflvVuF56TGvKOF0vRrSpbyismpAr//0x0qR4o0xfwx+0hL7CcbYT9yPP03w0ZNk
        6lh6hEyemJ1IduKvKnrw35MSenTi7v9E/wOcOEd2jqXjyaafkrmbyQY6HX820+U/pVPJJvyB8wJwyIK7Y+QmdYhQIzwoPCy8LWSqYfX0qrQhqx16fSpTLSNxq2O2+oxbBD7uZ+nQS1IZdz887fbAaQ3+IPtwJOZx1RsPW4HLG5K646wupIw6WAbi7dDrvLgaGdn9QCqVykZdgibXZ7wl9XCkR73GAFixCljoCq8eiJ7VjKDWoQeTRkDrMFKkXjDkamDEynhzs/6wlhHKK5rhXcSnK816Py3jCDyAx26f7mpG
        Pq0kYa0uAf83DhzU1JgOwnF8AElocjjkVS3BeOMA4guEwprqH0oG1iUWHFOff/bMky1h76VD3935nv+k+uxEIoyd+P7x7+79cM/8Oc9dnL2odS6ZOv67LnIr+cjo/9A0ctt5z89+5t63cfgLfhponDCZnrrm3bbNfUTac3JajJyx33nNs+TwtysFRZh896pSoWxmcsAjhGF/7BYyVtwdYaRZqYMRwgZkbSuzSe76rJOdyZb4wzYXvPAjt4UdudkR0ctRhmRdXFa4vIYHdq3iElJwpHgNDY4i/DPYN2VwFGSf
        GRVAZI9L82UlVbQ4QJgYZRE48vlDYRuKFqPECoIGuDKInzndcEBkRYCDhxt83kFRoF5UImkieqPe/DG+n7yNqNu3064R0pD2riOLSOnu7UTcsZ12OkiZdIUEt26lH/2s64CyvHOuUk+Xk7l3ppHgtm30o9dJBx6i/Bhz97rSDPKjHLhwqZApQQqVo9CMAg/WJA1J6tD9ScOuAnPVMhFaCexS6TWsKAE8HbjtNdz2ng6jDk9Z2TzLq2pg+LqmvWGze5SyaCyOh06f4Y6AUDNqopovI5AIso+kITv5fW2q0x1k
        cx7kHZROhRN1Ikw1GLDEBw2sq1YDYRKX3CQqBkIpPDHmox3hqnEryDEy7rhnv3PapmmTxcsfnKu9QA+OWHlyzapTF8isDt+QvSR45O3F9enaBdOnb2/taiZVS3aIgUsbN76/EeQnQXkt3GTyOorSuiCg5SIBbSiwFbggRiGMAhj/ljTRpXKZpUNwCX6B6G5GHRCVyBbwdcErNKWDMrwowXgdaSKJW8foZXqVBEjZsVvkgHhLXJT1XcytyU3Irb7gzwpsPNPoUmlU8TWls4a9cE1/DVyutjEdghdLfBqJkBD9
        kF6ByyXoebj8hgviC+IOcdZFXza3MGfPtWbxmvPES9IM5X0BpIW/P2kKgwJrCruIJeGP+y3zSJxenNBZu73frW/S35Cab97qt722U7rxwotr98MMj7evefGFdfvoL8mgt/Fa14QPFEG+DbtqnAAaRreAcrF26EoKlhPFkmAHsUQEfEskWz3Tu/azupgybMA3cipjs+NnNgt8zW7Dt3bBxtQtF7waaPdgTItr10hmF8nQMbvEja+TN+nXXqdfI29yGo2lF8j3hGWCTUgKsFBZhW1PGI1B7MCmdlwIuJUhAk+i
        ShKJ5tOtqEvDjWlfrDE1aAgZe2A6/W062XiAjpvUMCjJrntI3CTdkIYAL4QFdjVLB/4gM+RZoDEWPCQ1iJt++lP4/mz4o8nCdZAx/bqRRhHksBZDDtTrcIVBJoiYPeLpESOe5tgB772dbpVWWpbBvb8hMO4TzhqSs0OXvAbBKTg7cAhMqz5w/QAqU1kXBujiAF3wGlLgUwQRJPCp2CYQUTJVGcERg9zYLib35k7TrdbLn5Thnl8qiPJy5TzwUFwYLQCTGWFbB2h9Q7XBqGvYvTVYLc1rlCMMAvarhVdNADKS
        Zr1cy6iKuxn3cTQM+xhWGw5QVA2VoqFwSPNa1HhU8gUqpdRQ0jTIO0CsdotLy742ew/RTjVNe7X1Gn1y2A5HvxGtO1tH+WKhx1Ycah3RT5xJbGfeWTRm9syjdDed0LaSUDL+5Notzzz++DPjFpMns5mWx5/ZgrSaCb+OK7tAyj8oZGTUmbhYOHY1qctANwAmsoS8JSNvWQq8FddmkqfeUKzbOscpVs5Lc4SAPEq5BPphBlvzCOhYR9LQLB0Zh4aXcLhtXPK7mV51AV1A8iNI88Nt/IyF/WU2LuVdILt1T7Nu
        0wBjaREm3CMm2RyaIfmRTk2NQ2WgSl1UBdFWgyIOMKZqiQXnNJyZvn1icuIS+o/r1YyVyGRz17UJycXrxDcDP6jPtmyaOXIxETb+fOXl2x+duElpS/3C1k0whxl3b8vNym1Yzf/KMYQhw/Dl6jwFYEcYDpiXBgtt7choYfxAC+C8+GITmBRT/zAlnXgNFaYShbdRrx7B0254D1wCiDDjjjDwAbuc8YQ/AJMTAQNob8hSKFxeEatmk7bLgBuqos1s2ggbdPhexEQJ0UGNA+vqSWO6Bl67qQAQoZKAaquZYdh3
        kdjfnPy71ECyaDXtXO/NeomVbLy+9LnWlZ/Qr9MPyea5a/9m/palG+U/zl48f1rmxPnt8/fE7PHjVO+4yHBxiyDIp5Ujgh0k6XghY+NcAmDccFiZHHIwGeVgMgrErPOsbksZMkzUksrITkY6FWSUk2EwJ/IRimDDwVcT+YmktXQwDkwFxkiLeOj3x45lc++LCfLkYulmV7/NNEue3CzVw/qsg7Gsgv1WLiwXMmWF9SkrrI8VEBcsjDWAZ6xOOONNGi7EcxU4MtyMGY2NSQPUB/tSJ7gsAowWOZ9vVVy0Etiq
        lbhVUeI5mnVVy0rWQGkZWxMrrIle0qwHNF0rrIS3Ll4NK8CE7lCSgu0bDAjRmnWZigyp2bbt9Rc3J3ZI83KN/d/86EjjNEppkt4my1/8f94hT2a++7Q0ZgutHz5p4pNr3rpCL5wHum+FuW5WOkGCVgnPC5kgzhZ0pF6VNLxAeOBECeZuZ7vTbkHiR3uBqgq+tRC2AjQFNGGRvMEShA9+TVdhHlVlAJAEn9+FaMnuNdfD5/XFYPgAOOPVojSUpFM+r4hiyBvCWUa3UuI8esW/vWzx/L26pel/LTlJOz+ky2cs
        0MkjO7bQc+T34g/JkjMbZz5Db9D902bT2/Qx2kEGk6fowf0Z0ryHy4txML/+yhlBBbyQUfKyB5WEJYmYSABbFkYkNXNlQeJknHQht+dXYOw2blreuVdpxOtIwqK75+RVqiwEGPKaIyDIKgE6lSeNqMSQl9XCIRfszxCssIIrjtgr5DVKgTReeFuN51A8I/KqrtR8QFKry+dH60Zia15eAvvQK8D+i2qGS4LXGp+h2FEM+QGkI1LnW6+SBANytCZPLUBZUUBZ0UUr573fRU5++ldPz11Jf0v700v6PjJ47356
        asMO4t7xX+kHZNOKE4mZV3d9cHnXvEx94sSKU7dvk+n0H7duI6XrRN/vV6/+/TLgix2gc5YCXwSFCuEvBeR4N/CBmxmVbgcwdQDkCzBIBWMQolcy3RmGSYa9uhPn6XAxyexkpxjYVjm8rkKGd2q+3cDqJaUVbN4BFMRqs1ERQV4himaCaSEdBbaIVdcl0qFYapDmRm4x57yDiJeJ88Nz9MapD0M7kkTY+mrjgRdPTNlGGjZuoxelJHmbOOmqrut06fmtQJXB4yaI8+mF7E6SzDCZswsYZJM8EfCAR+jfjQh0
        D4hfxANeBkysKYQDupO94DyKUIFovu7iwID/Y78kferIkVNHsvscAPzze7kF7qOh/nYmsy6OgICqNnsHciLDH74eLg8bujx0Z9qwAyzyo163MahuEb3cv+HL+zcKIOnASLzpYA6VpMy3R4z49oiuyd2QCfbDByQsHZCPgC4WAGrYSPwDcv0jqpE/fCReI+/RetqP+SmISA4o9XIF84uUcnSFcjiJPgjCBs4IQXDP4I8o97tzTu5HDmzZQvZt3crwthvudYXfyybCveDEder9kPwB7hcm58zbicLxu9dBp18H
        BF0mLDIlblhmdHE4AKA5mCfDhpLU5LZyts1Q+/m5bgcY3xaxBd31RgTWyuo13HBSgZ2G0ikShE0las3NhhVI2BYKMwELOtCQvIiKNPQbgQHnZkznr2MWmwUURTqEmq9aDeZJfUK8TdbcvHlLH3fq+Mt0VSlba3nc/t/+lrYcFukNskacu7N1+ZIFdz5gxOc+n9m5LutpdZTwgPCwMFfI2FEKedIMutSnMg+gCRcgAvMIZFT8sC5p1HIZLFu4kwBmWw3GWzVHeBYPM/eNcrD294Dqrq17cABX47WwjWqajYdh
        G+22CCFXeTWfk9dKYlFBAwRZXdek1lYLPpCHFkkFqdvoFfhHiqXGSkTYW7666GzSKNaTSWSrKFL99PzvyBvpzXO06wkyi5wgQm3aPXne39Pn6Kt0bO4UOU4WJejI3DnxvUHbSexG43b6PtlGRouV5Cs0SufTI3T0ypmdhHwDEOIj9MBHdM8cMqmLjCRLcrvok3QlnWXfaCfXxD/QjTdvkmk3kGaTBEHprywH7rMLDwm4X+t1KZ0hogByxCoQV72hSkAblDBMioMdq9thqmkA0HEpJvljZJIonxTFgy/mlr3w
        uuiinWhYo4lETogXUaZvAN3QCvdwMSk3i9/FcEsd7DZGhdSRDQXZrUISF3GwDG4A+aGU7vYaPtyTcGT3cmFfCu9LYYMA9EcRV4oizQKLEnSzoekhDQ71CvTGwDA10H7BgIJyXI3XxtBGjscazdcNpOv87+n1Zdu7ztBtZOzSDduW0QOkedHq1YvoMWX5mW3Xvh7+xeIt76354UdL17WeW2jaWXdvKjuVDUJIGGFqcjdwlDvIZLYPwJGE07LZOjI2ps9tKurzMJM66A8oQULa3OjKFLiBIMRScjheF6sG2yOd
        GtSUVuV4dCzZQhoukknjLi2k567QvyP9f7fi4tBL4+i2i/QUWSHOJetPH71OT1ErPfmf5x79NdsDQGvlUbaeHuErJqWtiklpj4VLWyCv1cm2rxO3r4eLW8MDa2socjMnWxTGEqtlL/3IBnKQTCQr6By6nXYSlThJGdjsN3Gt6cLcs3TFSpIQNZJg9IExSMtgDA7ha+YIbPm1lmGtFc5WisS9zzAYm5ObwAx62lDriSluC5sGMDd++c8G8Q+5E+Q2tYqNyvI1tHwV9a4RCvfdC/e1CY/x+/Z9T3sf9wS4a97Q
        0euGG6TK3EbyAa1Qlq+llWtyRTxwBXggLPx7gbnOGA/0sfBZzReW4NYa3rqkwAMRJLiGPOAEHjBsPuQGx73cEK1pAm4NikIf7EAOEW84t0dyV9A5ud29OIKeX7OGXqOvcrrIF9h6PGrucAvf4bqSzkp2RhmpezUcQBkxpTuYLwv9BRbYafl1wCBDGkQ2EKZdPLF/fw4WIbdMnNc5V1yUpw3cj4yH+0lCrGgdiqx39qMUrrihHSUG/9vpd29IC5mseFzIOJlNAHQ1DQ+wCTIWdik3t888BbMMlhMNEXgPhHQ4
        zQhBJUkNaoQ7BNRo3fS3xWlnnmk/Tzf+hsyZuqB1ilxy53f66dOFMcsr4b520B09aSSnUfrh7eyMMKYcBDRrSDa+V9IaU/EEaTLqj7lnxVc/ye0Hurworso15IaLC3JL8vw5Eu6h5CWtIZk7k1nonDYZiXGkpBQsdEb04IZ2ckNZfkdeWRivOgyu5RSGmddSbemiEbvY9Zx8KZ0sXIFLqQLrufPjz0hWR7M5AxvBVWVzOCCGxcDbb9Ha+TQOd5wrw+LI9ju38KdAqy62z0aatFKL7ty9vWxeQ8IlcXJ3k03g
        NrGkMXHNKWg170+QqxgBr7W353xw3w3ydLjv9DsbmFwbe/eGchP2G8q10SaGdNjMHeex5VFk/r6ubrlmuGxF4SrD4+jpRhZQwEVF8xU2mTiVbKZTcpv+iSTJKVJP36NJvrXojNwy+ox0GncXKL/bpIHLW3kco4UmDC+WOrDfs1a+t6wSB50mWewpHCEDTk6GOEEQ2IEWstKcV1heNR6LEFBSjd5aUFK/ut518za9Rnwb1tFf02vK8g665qPcHvHiioUgiTl96E3VCfRxg1YaJ2RcSB9fnj4hpA/XP14YgNdr
        BEz6gCoyAl7Eu6LdZWUE8oFtaSBjgCLNEEExfVhAHy0gWoBD1Hhvcu1+jcz8Z3pp6hx6qg+q0aMn6P7JdAMpr+xBvLy+kmcwPg4I/9HkJivnJiMAJHS4GAkdSMJggaddjKc1k4Qh09AxbBaEmS4HAwRgz/ckKY493pOqe98kSzvo8j3FlN11qSK3SxxdTF7g+QV3b8hNQN8aEhcycSaXVJBL8YJc8jJCg+0W8uK5kB91PpipLuYeKzHDTiU9nEg+5lvSWCTBpzFzFT2ZVx8/UoWeTDdCHstBo9T6qV5xsP3j
        +KGDeNoAYxY+cetW+KjCapQGP3Xr5QcNuxVPt1ntFn99+7W/PDwHvuxos+Eh/gm8sxZ9wOODFfAZXAPelfvr28rxWHjDYrXZS8vKK/LRP3LPGXSiAucC+JU93lB1nDFOCH1brmhzwYcycIDSOLAmypwPAO4DlQpgsGjNgmPWZcS9ecLSbduWTiAC2fhH/ynrj/+wnp+gdCzg+6lT5v2ofVpDvOHJdS9kf/P29vHz5s8dM72hNjl6zaxt//wuXxPArrLIsA4gHbWw8wSpQ5dSzM2snkUGySgqLokCSj6jKvhW
        Rb+VreC6Rg/oJGk7XdYu31q79o5d5nJuPaz5Sbi+R2gWMm4mY61cXuu2dB5Imc5oDPuA4mQ8aZWQEG6ug1D7ANZnWgg2zfr97WTU/n2go+vpaXJD+puuZ/Sf/1zH105EVnjfBhPDqag/2bwEkcHybmeKoDJZipS2IRBvOEYOkCPv5v7hOEjOR+R3QJ3Cde5Syza2t2YLGYe5s/BSBhgkBR2BbGkqUYuXaQfkwSE/uvZdFpqWBrh1EZgr9KmiOw5KgiHagSPaRMnuMDmB61wr6lxDwL2n5MfFhmYjloajZDCZ
        eJIMIWOO0nGn6UG643/AOEfLe+6MkTMg5RfcWSKa+h/WdCvDKnXd0kDkWAUBCgMihoi6Q2buGvM+NrzRdPIGfeIUmFMjT9HRJHuSbqPt4jHxndwpsSH3ldxgMZk7DfeognvMhXtYUddbetDXltQtZ5nGsqPmsjAqw7QsPcgN7FJ1kkwmLadoGrBL7iviu10/zl0RI4U5SNeZTsjreQvgX5kRngNQhjINi+kBl9B/BzNpJAj1SCw4Xdyd+7r0Uu4bYtsa+WngyNfWoJ0/im4Rt1qWAb83Mk+BYmGeAqLymIpw
        NmvxCU5MnEihTZ4/klJ5JwLACDC2g2QUadF1usV65Se3v/kaGzN5lG6V5/eKsxAn4wypZ5zl8F9c290rzkLCLM4ihe+NsyAHkEf3iQv3WpZhlAXvlYB5LGHzGCogRwswDymJApXNw3IWhpxV+eBVL/PjEtjL3vyELHmviAKYoRFgOknoOplMtxyy/Pwnn0TYGowhLfIhtoc0wYRY5t7hsR/gljHkg53kH4/S5XQ5fDl557Q0sqsdx1d7V1Wm393F410wMMHWgT9F8S4FZAaplR+/8/a47XzNA3KGHFeHF+Kl
        pDteCusi+gR7d7y0EWxRsltZv5PSlzm2uSYfkeexePwE7rfg4sYP6sRC4EhCt0XYWrAgNA9zbTMeDaRgfblFYdg1xFQoeYywH3GOoDoKHr5UKNyIBCv27Y0lDVdW/n3TW281nXv58tzfkqb336fHSJMokqFLZ126s/Hyiz+U7HTxtX8irZe5vr57TXEqV9lYW0y848iPVTWHmVFZKEUlqANLGC95gIFEbyqle7xGEMGgOeKgB0asOgojttok7h5C1IveaoH5UhLBEsJHvKG9+e9XXiEN9OTllX/f/D75Coz4
        XeVq7tVZS+nhHKVvLHvhsnidLvndVbLwYt5Wuya/CPTtttc9oLs9zF73MHsdx21Xe/rfOV5y5e11u6fYXk+jhYYuddUbSqeYvV49liRvnBDuDjwe2/y9zUeObL4+4nia3nn3Bukv2sljb+2a+uL7dMb7p7e9U1jzbjr2XPP8cmckRkfJYtLxT1h57UutvPhsZ6DX0ptjXgd09AsR4btCxscwOFDN4WMhI0+ekiVqRzZg9aHVG3AAz5byDAAYeSCFTgdcfwVUTZmZF9EmiW4fegoVzbB4cApWVCMCC8kozXpJ
        PpIBeysYsMQqSd5HUi3FBpCoOhY29Iekn61rkfXI+jmv1R5vpF1Hb7woHhGP03PvAP2dZCg98+Hg9PYjz4xfc0hcvuXwAeCLJExqhhoHCQGI2YsrgMalocKUCMxDd6RBzyLYZOjdy6L1HgbgMx4G8jwudBt4MB7m9eAJL+IKv7nL040DB6WD1WoF4eGw5Op3ziyY8tFH+6WTrz61d/v6lueferUrLZ3ktM2dYzIgAjp/Ac83MSp6bP+YtQP1nw/YwsfMYR+LwFYz4pYBccu8uqcoKulx4SmG9NVKk1PiCPc9
        GBJwOEvKYwy2xSo4uwR7CQoMdyV4VFLri3EA4jfSM8E3yt4h8cN9i4+FV+iF93ZOWXhgJxF7cBO3IecB/2ugb7iet6UzCjH9KIqbATYmP7jhpHFL1mR9m9N01WsMbgiGOx9cQrExCGYQBIJjEDUIJuWcrvbON1+n728DMXH4+O3Luenigh26vgOtcqT9AhjQPDXMcqIeNbOhbMgA+YBBXzmSDu4UMRzWHsmQhWABjGPB6BmjR8/s165MfW706Oee6LwiT7uzURDvHshdZfdDm+frAps1rizyWiBpeGVu6shn
        0fGSkS3MxMDgp+xti8huNxh2Pm70WABxt3m8/gC/t5Tm3g5vrLqe5AcybMyYx9rp+4TMeYsNhy557AllQucO+g+W77Nhdfs/rLAedozQ5P0fMkG9ea/zQ+7l/IiZzg+yi6i5a6Kvi45SrnYtJR/nTudOkGvUV+wXugo6spzf4zNdQlc7A/xvLBtgX1QJM0ws5QmjnwO3hWJu0nLYGf4Ui5jCxao4p1R5mXEbdrKwmBdTzZw8eFpSxZhGD2u6tVn3YkBYMMp7eCOCyEJhVD9FjFTMUIt+PWjImaUH7ry5jf52
        G+Mtedqe463z5iwqZq8Cm5n8DvPQ8j5R5PfCLJDp/wWcHrwvp+NoejE6l98HYAw9fCiqqW48arcPxXMfH4poUbt9KIKskF4+lLyA+Cr5Hfkq/TktmXeSPHLlIj0MIthKhtEDuU76tuSmz1/6gKy+xPFE7qriNLHPeKEbSjDS5PEEgN1/MY4AAyxYhCO0z8QRuavqX17qBhI5iyIXkATK6KsFnDZeuA9EA3T/vwOlaV8cpX36Q/HTXrqa8Zu4A8bqAGvS9EoVmM1i7+Xr5UaVpSdnBQHcIE9Nu9Gwcp087cDp
        I2vfzOEWFmbfvSYdYXvyn4RMpcBd7Bm5suAL8eBdgnAuyLRi0GfmMziZLwS3JEnhrkSbsxyOyr26hprLBwTzJZFu+VTpjy8cWstdITbmCikp+VQvPQgHsMCY21wCIrGk1F+fgd/Rl6IvxVW35mvOwIfwIuy2WG0lkVLTi9HjiHkwwk6YcVWz7tOysttTUYmJExpa1l4MJ6E7w1med2ekBg0cILO0AO7OCFRKXjVaN/ug3Hqhdeoru9ZM/YjMe89+UJ53Zv6yI4dfukqXfkDGTp49efWEaP+BC6esOPL2tslz
        pm2akEivoF2Z/dzOF7cqCwQf4g8P0tGZhx4Khx6WVD5nUEYS+pO6xnMGzeCFVsgZ1FjClYb4I5DHH2bOYCA0mATjUb/WsHo/JjUOGbPhLXqBAAohYdrxam78k0PWk5i4k/HNSpARs+RpgPG+xv3vLJcaLGFzYOj4CBQ7PkAhYOge1SHuR6cE5NPQvYt0dDX39IDEG9V4NWzDlW9uI5Xb9u37zh0itt8hx8Wf5BZvz2S2i613Nmbf/QTGAVacPAnGUeQDIZ/jA4m8SyaSScfpqDPytK7R0h7Qtew66mS4jlOY
        Y8YTrA7QH3ixnl6Q7lAChjvyXpCfXPtvPbwgyhfzgjiLvSDM3HTA+A4SmVgPdRJxP910iNLDMMwJ0o6u8dLOOxul1q7FAh+v1A7j7en/IJ/t/yB+Fn6QImQmXXeGODt/QdeRGWfordtif7GJ/jl5PXcqd5JsoZPZGkdyN+QWuIcF8Ec3WQu5BiY5/ZFfAT2fOpibdPB9GOkcacWnPxaj+PcTYIyj4O+L/BvoC+Uk/SL+jQliVe6SeAN+qtZLja++2nViPdrd/ehwaYVFFx4QviXoFclsOc/ecCXNJHSi90vq
        NWd1JWWUArj1pDI1pcj1NZgrVurVwyhDEvBJIgkzYkkyWsp4EIfiKkf1UIMEI42DGgeKA4dKmIUMnIn5dZjqE/QqwfgAEbP0fY0DSb9T8X7psQ2xdtoZe+75czGitscaxpYMj4unJvR7fyQR9uycOqbCHZtKZ026sf2VkPxQx5Abk+isp6Puim+kFv5yNBFGmvbnubsJskptYf6EviEPmmfn2s9bGm+fgO+fBzoMATo8CFpGfzCZFTgdtGTWZ9KhnqVdSilWjBJPZSIML0eqgA5ur64CHRAXxeDD8pTxEIsw
        IMeUMwL4h5LGgTDLpsYBZCAmXiQa05ViOGQBGgTCQTUc8AXckiV+vnV59L1ZM6LLW9+KPTy2bBip/tVJUo1Eib5FpyW7Tky6MaTjITn0yvaPv3XiTjLmrhjTsPDE6LsjLyWQQneBQk9/o8ItmP6YLumilADdOUKAFQX0iNKtTZFcVsy4ARWfMj2qWQ+v/vHw6h8rTzXXWDoG5pfYuV8Vk/LQChRBnFSrljGlG48enjXOurzx1rjDz423iuIrqxY//gi9sHh4M6t/ogvEeoZ9WP0T09aWe7E9SwYya53QmZ6w
        OwBvW70duD0cXg757bYekF/IQ34/bHWz/im0PiCdZAlKnTfl8Xe4bF11d7TcKmNO7f/LK5L0cBr0TUemlMnx0oiN2QGSpUNXUxmJGXmSbMsXWDCLDqgCEAIdZCWcTEoKgwoAEcEsNQLwDVuKJeOURFB9aSzvphT2YahZd2pZItiYtcDyAvO1KHUFN4abYDlK3aoblxbsuhDcNeTaliNHtlwbsivwm10LLpFh5G/Jw4Fs1nto6f5f0td+uX/pIfeePQE2t5a7VG5RdgES3GRmFmHWnpDU/WmDOHAj4qpbQI24
        mEHjsuG8YszM8WBesiePHtqqPDLwRAhOhqryoRScN4PDrg6jGl6rUDmLmEfr0TB3M+TLqFoAyybsmmFx4axLymDW5c26oOmVzbBucCSz7MN0nanGGWoOhEFHhsIBC7wkhpKBiZZMspNsOC9uX3hX2DCQrKVrfGS5+FHX3MS4sZo4b/GihUc+vr53/qrla6esWzy64dhPNj4/xTj4CNqRpEU6Lessx/O/CMyEzFbxvRtMZsvMvRtlFRTdBTGwWMjyWFyH1kkEFGiWqKGwjB6RCi1j91twXh5fm8Pl9TH8XRYE
        BtQEfwi/UqW1ycSLSZ+6zdem2J0WjnubBpCmRBMYMeGmsCUUsIQtCbU6YfGZdSIL5jeToa0trS3XJi5saR08ZGHLwpabE+bPn5td1Jole+dPujlpQUtrM3lkIX4H3i98tHm+WPHhokUfLi7yoZcI3+PYJe9Dz2o+j+ACbgTWVjCdL1sSZCcsaaNEwooIokeSuucsmGwsyceWyrjZ4rvRxgXG9zBh5tHgKJRiOZ1uj+mHLwn2UPmxoGIJ8vANaJbGRG1jLO+Xz5II/UkjFpi1Y53ZILqRRLinPrdkhTx91gp6
        iVStmDVdXsF9AN0y2iJUCKY/HSPmCHNU9mKqR5TVEpPX7e3nJbWrU07ib8ytphPlwcpKoR8gu58JrIgKTKWOjIdhMw/mtVQmjRo4U8Owck0U90CK7e16wB/1JgxWYRerSVZqEGOGIAsXJuFzIwnUCMGICpmfSa9eh+/LYGOkMQ6KoKhfs+GMar43wAgqqajp/zDyhkPTA2i61QDrqGUPPZyvogI24cmuzJ0ZDIRkDJWydKloHSgGMJ1ARdTkE19b1i2Z8eIVMr8jq8554fZisuEXq5bOePF39Ae/y6hzZt9e
        TKc5d5LQtl20g0wjqpFomD912d4da8ZPz4yatHQXpXo9nnlzxyvjn8EzZPORZUuPmX6YgHxLHKycYfZpI0YGdDfLT0miLE6Z7/JGqqJ1YHEZJiW6tHy+aN7pkS9JDQybNGzYJGJ9bPKjj05WmvDg8cfZb8xLm3r3mnLNzCmoFd7gFnHWwUvgMHCe9fByt5pKCzoza5SOTGUNrlxlzFaf9UfYWT+cjfiZ/g3jetaxvG0bqyhEMxo1VzlqYq8RJkyu4aqGmH2NVXLwNSOB3wGrsM1i96BW0cNaxh+twX0fguVy
        uHnWcoSlMqPhbXO6rJq3h/ktFMxwHxqR8TpLt1SPV08lPxK/T16i38+9RH9Nxoj/jnyDtuV+sYW+vfFCv7bkgflr187PNrfVX1x/gCwRF5J1dGZuIX1OupN7g/6U/IX4BHmqayfZRtRNyzeRv6XZHa8t/hmu2XDxmDRY2cls4mlYM5P1c4kXTmYlU+IxazircYlXZBAjkVDs9bKJ/ZgVZbc1IxXytrEu+QyLlTvfB/WMZJjybPg7L09ZHl+7Nr58ysoHluxbsmSftIk8emrc40tz6aXDvkmufPDjly4vNWsg
        bkmjJcyXdQhCGUlL/jj/3XJ0z4kTe3YdfvPEiTfFFvIM/Ru6gUznr6jjyBGlVZoHf/cwz9QFC9es7mJa2qyycd63yqZFie0VL62jT5LKL19XJTN+7TL5tVyoFn59f44tq0DeNHVOpqwCh1PmBQFUkspq1ewzzVydeC9+9QJblnNsUZFCro3yik08EezBtTWYbg9YyLCIzVj+ZHjsmFDvMyJheA1qgJHcbPFcWF8BagvdRopNcHLNpsPvat9umbgCQWufrCxh4kYAEzekeAQEkbeeTCU/Bu5cQWfnltBfkXHi
        UDKO7sodlkl6y44FrUTZcHvh3pMn95LF4qIiHn6TvsZ5+E5Wym68tDZI55CldD7pIM9h7E6JiJeVXd3rIHXgT691CIinlcjatcA/g4HnveY6jBeK6Q4yy1wNU0yZLG6605BwCRPMfrZHzSxmNXl78EnyyK/eo0eTczOLWjPScjLq2DHa/i55/9rCRR8uYfjrOrErstwFI/qPhSxyFxa1oyGgWJmaVVjNjsKiSTA68SwqNizcsqYyLpGhMiyjsacyoguPRLSrQAVqBRaOFQoRY8HrWIVIMjp/IfatZNxWMpFu
        30p3beWYv0E8LTYBXQPCTAETiK0OdO+YYiHjY3vEJ+BoWGaP7khlA5xcgR41xIBo0dsdMPPHPMxvq6CvyowUOdGFY8ge07pBnvEAOIg1NqF5E6+2NMiLP1w5YdpqMi7XdXrlkP7j3fIHk2Yv37Fg9PTWmdvmPtf4+HgYb0I8Jk6A8caEtULGjujNxlc1Whi0FGWgXGClSNkQP+llxf5evxmOAROG8O3CHQqwDtlKPpOSFAvBsNS3mL8ZC5F223zBUFUUt0SlTy+FKdkwfaSikmPZ0rJm5qMuYaAnFEZ+wBIx
        lr9cbYnjbIMBrBADdVeXWOV7sXnk174yYXzzyMe/Mrl0tXXjxa2Lmkdv6lwpbZxK/t2UxmfSk5ufmDBzwcQF/WTf+NXf+06P2PUDyDtZsUfsWirErg2Jr0B3DFvOvEzQcUTQjyvPk6cJPoxoML+Rp5ffiDlx/cz49XEXt6+H9wjdVobqZO4jnWh9+Y4S1Qltw95tpOpn+/bNzq2fhu7aIsfRMTIhtx3G8iRg0yTLIR1uVgEoaZ4IxzLx3Fh5y8pubd4s8QkWXKkkblHVLGZgFW1We6GCalAapTf7v/rJT64R
        umv5rvANKUnmdHTOlW/dsYujkQZzxVFkqtQEcqFaMGNH9y94zUOVuazgVVxRqGOA9chdFZ2CG/4fw6pQBdg3StJQ4XJWjKd6MLHJNIwzqjWf0IShVczVAFiIQlwwBDerdjOsipmz70+nGPME8c71JPCbOeS7sxfPW7Nqzk06a8cnC75z6Pt4/37iaZJQtoCee5TxA+Fs3kvVwTqKPlhHb1bmmxbYRfQago8V31kKTsi41o+uUxpm55LiFI7zEDM0A2ZA+TlO6JaZMLECZvjfKD2HXyBfPXeJvj1ofvtLi9+S
        1pDRp07SN06Rjz7+wQ8+XgrjmQ54YHweDzT5pXT+3/QTJ9545539x4/vOXr0Vh4IFAAB/CcLw+DXMUYrG8i5sNDOvZW6L82CyW3lFuKuNz04RRQ07Ko3ejCpe9PZIJ+7O5UJshLwoMuM9suw9/j8pTxZ8R2IQjCdsm4uYjSwnpj4cYN2z4SY6RTCKlQEVRin01XmgLMxG9niDLLOFChBBSNo59WQAqri/GJhQ4Vas4YzGG9MpLVhubVDJOfOrpvkq2TYu08/vfb8RXM5O09h4vaKCeQGrfj2ihXfJpNZDd+w
        u1S+atKkUtjLcy6wxNSHVUafQRXAHUELQBPu+MuUMy1Ubkd6VN2XHoAm9SCTsPhRJfOPmAY1mpjR3nQwKjHZ1uLEjh2a4bMy2xqkjWD4gtxPUq7p7mJ62EhPndLERC+SRfxoZy5IfiX/dQdol1WgXe6cXkmGgnrpQSDR21vXyGDfCEoz0zW1gCbTxG12ORmQzsSRQqXxEFDI21vBFKulbF1DtR0M6wfT2TrOQw+lMg11+NWGfkBMKYof36u5iD7wvkoqwZQUUjEF55Kp7IN8z9X0T6UyD6bwEg8+BMNIPYhv
        U3XAaI29FNobXKFV1xZUmp7SMvEHGlhlfEMd0DiBZZd6uvlPUXX+e/i0cTUqv1HNqPyGNU8uyyu/1zpf3i6N2tHVDsv4+LGpU9f+5h/upwy7jt/L0KLQTufJzSzvpQyRQRh9h2Uyy9p1oUOLGX8uRNco9IHHJUtv153dhfVLPB0erXtvW0SNuOuNoI/1lcFiOpVhHqxfZf473JyG08+51OVrbjZUqbuoTjAsrqIWAyGw41FF1qlBFHqN6ZAazSuY9kIZ3S7xE/r8aeag3E62HpaImz4vfm9n649+8Ff7LykT
        zJ4LEq8Js8xkNWHuz6wK8yS5pumrKkwCiNC7MuwqyRYXh4mhnZSuFP5V70kaVjLIUrhnI9zTBdbGPfd0d99TS2IVGtzTzYKDRfdsrFWCtaRHDdwqUkbK6T/ST4pr4ZRrr+3Y8Zp+pz+WxOXvrZ6De4eFUmFe73uX5O+NiMmaNlyOjragy495Jw6MfBC9DM6fzYa52RbmeNnL7TIsXQxbeVG7R8sSu+grxc3o9RkKazNDSjTcl0XTwL3FXQXMmy71mNBfuuf8eHHtWOv33HNWLB6dlEcXT0wVp784acL02S39
        +905jpOTzbl1Mv3ug9lN7z07b2F2AGUi6ayD+3iCfFq2s+jRwZJ3zF0q0TrYhFDjG8TbzJLCfJgUxkpNYTJY6e4rmkwhBSdWSIIpmsyrzBc0EKfQ9Pi3Hn/8W4WJTGaOoTu7gEf2mD4icy4WnfElzmXufTkTTRtuEOilyUJrpDKMeqNZo3GzpqTnMpUENN8eImJ/DDvb1iZXG6Uh2OwOj9fJkyG7WTxfCp1HN73Z/cxfvbFgwRveoS1DhrT04PvTB+fNPTyXqJOHDJn8CMNhZYKg7mD57T5hIkftupDuWazn
        9jpxfm5WQ+cs1ND5WTBf645EO+8t3eNxaPTSxrrL9yQS08qkftL0fA1fzpv7jUFEcqZQzMdHDMYdy93MXWW1ljXCj3jdhR5M36/UDpsEVMD2iCWNkKPQl8np6cBiEbC7siEOmeNMncYr0MHM3BsYRmO9AkJxEKo2t8RUVSjGtpDhhpN6gDUvQT2X91TcW7JpMXE1qKyB8LvmPgWcY+jJ4/NeWT3njxfmzJlNr5DN4tQelZx7TrmfBiS+aMf7kUKNjLQMZCN27Prm51VUej+volIzS7EMp4tF0oprKwnIz6L6
        ytzLKDvNZVEPcGH9b2U8ynkuyPPjaUQ4+fnj8X3eePyfOR4U98UjmgLCvoJ+0D0qLucFs+5ZWgZy0MGQw3/57JHhlipNZz1cFIZS+eL7+49UF7y6C33/msbytTD9IqJx9GDDIi43ogdNMzy+eyYi9HKUF0/p2cfzTvPHHpvcvfyTe3jPke5gWpMDwAc8ZsI6DmUUlmpvzacvFopsSBAr/OwkS3/TzjmpM8GrPv/l1+Ec8Okw8zp3L8AvH/AB2h0JM2uEl4vaWSmQLV+IqGKds8iIIrEVxXhOLVfbLe3mEn46
        lV9XuvsBXNfO9BmuZEs+Q1RkGaJg6eVjFO4Uy42xsrSqNsVhBXVNNCxewGhFm4udgMViK2XFdBFJBVyHbRHYWsFw8tIdHR/59xUsiDG3PcE0l7kOn36AozMPgIYwJGUKq5sJY7WPnWUTKaaY9Fs6smBlSWAsBNJYysD4qyTfgQwr6vypjJOZn84QWhnOfAcyFJ1esxUCq1nG3BaWpc3qvwzV22yaSmaluObPvwFOs2bIoULd+KP7iErsuFvoLdpJltF54szt4nxePy7OpLW8hJzWbDfrArsAH9mEiLDknipT
        dBja0xhcawt7gkBVFwsxs1Tz7uJTO288qPE4K3ZG8fEjX3dhapkZz9dFWAgWMg/A1CSf2VOgzyLV3mgpX7Q6EvER4KS5iJeeeFguLmItgklMNrB6VoZ3PcBTT/ZZ0Rroq6I1aGKh3axDie8L1LQiKO67rlUm2fuVtpJfmtD8X3esiX/JWC8zRZAfZyMbZ/A+4wz1Nc5wj3H6v9A4UWz0PdI0FyT3G22nqSDM8TIbwCNUCS/3MV6Ej960EQQ+rwiWAZ/7gc/DKbM5VH4aGLGp4pxdxYKMmBuCRyXdU8TUgiov
        RhhFu4dHGA1faTPi6O744ufwUS+273v2TxRtgiVfe1i+L4O5izaEbNKC2wyYP7G0L2qEk3plOuvn0ra0NxkQSLA+rcwFZFRo2L61BwG0QrAqyINVrA+S4cZiZ5cGIL75c4kg9lKcfROhnulOYmcK874UmNlDoxJSSxrlDfI29ELaxCYbCduIxYanv0qPzCVDyLAF9AAZNpceoPuIToaRR+fSg+Sx+fQQ3T+XDKf7kaea795gdeCaUAID/i7PUkZUXwaEtIqmZsXGhiz7Rjpr+J2su0+l2awUs2v86JYhYF3p
        ldpui9urBCNmm1IrZl8ZZdwMBh7aLRGnPcCiF1aUmCAmm+oQG4eb0pYQvMGME2+Y1MWrJeAdoXFgovnkHsTFXYcX6L/M0CmAeg8tiM8Wr/94f8dfLDt0hdS/cOGE2ELWr1584XhuK31mzRLpCO2/Wqp/rss9k6S7Tq9FzMBqc0EmoUR6pK/q3EBf1blBszq3zeXm4ujeAl2EnD2LdPuD5Lm3UFd51xSQ/wbGIl8yfRr5sTQyCdjnWEJ9jSVcNBZ/32Phno4eo6GkFLV5XwO6xYWcxMcD+5rv6mfvHVGfm7p7
        hH1v6vy4Y+xT3MJKfjO71L7qrq2k177tSVa1GPL2tdjTe4Jfkec/wZpbBee9VcSuQhWx+7OriFE1d1cSM+umu5hYOshWtbvHBKLPrxf1mOBZjH4wI+xOAVNR7FJHntmQOqIzxdq4eM2k5iDiNz/iN9WSr93p2WyiH9mwlwR6d5ugF67QNR/R86T9x63EzOfEOlAREDazy4VCvC9oLST9Y/4WWOKytdCurwaWtKYAxFIciKHcUT3M/Rku6pes1SDwijWzyC7zDFf62mQPFzWqZliDmB/i091YwYZ9x1QnnxJv
        3GcJF2UwwcwGJmp5MJjVNHxEWs+0n1/VeoMevfy6Ovv47F10//BFrYeWyLPaz59a0f70skn7Fu/4H/u3TPh268SlL56aGh++cvLCxTPvrDDx0DX5iDrc7J/9nz6nYrf8vrUgFWbeS5vkLillCviLFe1yQNdHSQhKh75rd8kRVmz8f3/siS8/9os8xpwfu5uNveJzx15537FXFY297EuNnYO+Pkb/59wNcJ8Z3Oj2CfC6X53NISGs+8w5YGpOSRpdWm21FXFAf2WA/qpAQj5QPDX0KCY43kt4cW5mN3tsY99j
        2v3gIIF9L32YnlOl7XZL/lAp70sbtn8hxrsHAfZBijnuOSsX145BALj8hyMa5PtxpPrMCz3wH9LljKknEsKyz6ZMVVKvS2fLuNKo7k2SIk2BGqIW3tb2QQzWBNPtCyP4NWpjQJmQv/kLE+NeJNgHMcb3UCz3ocTUHsrFpIW6rECL7Z9Hi2yc+50T3VnGvemRjXGmiHmxP3G2gh9V9EGVGGrRsA9YpBZYpLSsskpi+Vp9UcdIxOGgpLwi8oVIlfdZ90mq8II9Cxbs8Q5pGTq05X6Uau3lw2b7yTpZnifEhXqh
        Qfg5r0fLRBD0VqcNv9qh16Uyftac2Y+eDSyAY0FUXUxnH/JG7KA2H0JnMU/DrfUwVkGlJD8ARKlwdmRU70P4VAigVX+0Ez080bayFmYuViceRDL0r9B8GXuQ9e0HQ8ITQkJ5QahkhfKq2geQfg9hRouu+PYQ2VLT78FkwboAcvECxXxpO0/YrEsEexKRmKXuTaRrYfuYI4s7SK21a4733IrL3/hFY9eB1tND2x8999cFcooXxPfEI/Tce0BLmTxyfM+eWS301D8NTm87um/dge9MOXBoypwCYenWrQffyfvb
        Wa+EGrRH8/52j9TRV4+Evv3tQCCM3Xxxf7vdg/52IxIFvtMsxZ537LnQw+feu+0C1isUfO4YLK7rbsLw07lmE4aG3+7CFJc/XsBsl/fAbvrnom4MGw66/5w52y+EC/J5HehH7HFQgbntX7jLQeV9uhxUmV0OdmOXg9LyP6XPgZ8pz8/qdTATtGif7Q7E+bzbyP+f50jWcKTQPUc3m2PVl5pj9D5zjPWYY8WfNkcOJD5rloNNL1LfM93OEQXXFetAb/pZ54h+wn//wjNF8/+BdLaC69Aa0KEP9po5+karQHdW
        sd1sJOBtopse9fBahh1DbREGJwyxBF7jAC/QnZLQDIllAXOI/iUJ5LtHt34Wqb7KHOPEzsK592H/kh461ow5sJzHopgD+QIxB9a1584Y1srhT7gOz5zsnGFeh8cugF97xC7Il45dyMcYY3Qu49c1YxespqJH7IL8X4ldmBUYd+w4OvPA7EvQrtzKewhYN9WyfF+CKhXbnuS3ZcTZYdZMGQHTqYcobo9kVTy+UBg3ZUAzNNZuGvvhZ4gQ6H6YjtOXtVg9xeokXJdQGpuw63m4NmjB7sxFbQwevXF07ZCFtHNn
        +pmTTQkSxjbnh6h9/inyyOWL9B3e12f5upcmzm9u+LP+icnzH6OnaCvdT/9brx4HrNeJLmiwQxfdp/sDPtHAlzbCgPWrwhVA6yDs0EjKTCguagrh64HlzBYRbaU2u7U72JHMlvIUA9y2EVCmWZc7oJRzx869fSPujWcU+khY3XNW/HUN8+ay1I/efSXkC8VhDVPu4n7oFSvo0WUi0FeXie5YgWhRi2MF9+0zwc3ge3pNPMHUw73tJsg+rgH/VceY+HJjfO/lfG4SH6O7dyyjxxhDfY0x3GOM/i80Rq6N7hnl
        903/Xl8j/TBv0ubHquhmHGNlH2P9vDiG50vHMbgbP6zttrs9PgdLbyrxFXvw7z/de4zYeya+sjuGwbi+TwrQe+zXA2btGsqx1r6ocL/4hecLxi+CWncAh1uvkupwf4ElvtdWvWfO33k0H7JAg7XPDTSpV/3cJeGIfFOJwDvULQNYjYdF7siXn/iThkfmW0k5i891sPk6dG+KuSF5SY3Q6/EOl2R/buEIlgnP/okbX3+dPo/PWhgh7TTTE+Fug4HWZ1gvnRKO7YSCdYxqw1qQq2G1w6y35a1FuOJwmtT0FaIc
        FX1HOYSeUY6q3lGOdD7KEa9OWPyBMAFp6kef88DE4PWLyVdvHnt1wvqNS+nPbx1ZOW7wY2LtNPo/3/thC6m9Mmz7JvIHMmz25J2baJC+/fwksWHjDHHShEu5C2M/OLZ+Bs+vWHn39/IskFMaSIAn7lNV0cuhX1RPETa7cRi+QPNn9ONIVNdhmCHfkWN2rv0OsYF46tmT4xj5RG7L9yv8tzUu6eTLZh4QH5cbxhX+jHGV3GdckcK4gp8zLg7fi0d23nT/3Tu43xbJSTY+kBEaw+pz71cnE0nq0XQ2yKVEeQEC
        mCPG9KSwxppaFeHzonmgzkd/JgAeBoHYs4E+Yza9sXbxvH7bw311L0uMuycniPUWYbrV0aO7iNm5pLu7CGrvog4jS0i2u8mIvPnlfDyL9Ycy+ySOE+5tNYlFAORP6g6lfYnuUFahuM9kj/ZQsL5s7gUdMLIwe8xyDRWyXH089aY7y9WU+2ygLMtVsbC8LbPSyqRXbxFeRLudjz716KNPEQdbh24qKrW9bJ6xdz+2xpUN7CkcPxRYYx/MB3JL7PGpqmgmp7Fwe4WtI+uya2gruqTu5w7xbmr4RA6lNNVdv88M
        TbcV+zSkeCE/cwn4whqv0sP81jbBFy5lios5EJVQWSGTMurXipNExdqiBuNbYXVb9ojXcifJP1OHmM759tJtvfuMzyPr6GXixAc0UN8aYqdXSI9W49xPOBx0RqVQJzwkbOEdFvWKtOFTsR474yth1YhB01aGfWjUwScPprIJTwlSIYG+gf6MClEndu9m5a8eL9jEjN0eMDULPjkWH+NpVEisO4WhYpbFA76MLVCClohTM9xMwHh8SJPSKHsCaELDIsY6oEtVjVrsS+3lGcTngkh9eQW/enPNrdEjP954Da3k
        xdYjS26POT6Idu2i2VVP0ldv5G0W8R/Ew2Azv2d2ZFsyf/4SZirvOLTvlYNEmL+0G95sxCaZjK9ZrFPtFKywIiXCiz2jnZjgGU5nnZy5/by7A49+trlVC3Z30djzzdxgRvrYiRAwO7Z1sLASB0wl87l5BlYIrVt/SXOfwdLeO6A7cPoGz7Mwy/yLQqjyiJ4CCmsdj4lPMju7nj2nU05juaNLzte0ZWWfYJV52TZrs8T7ODGJH+BS3rSx831/3xPXs3jOCIF1SkwbCqBcF1jQ9VgLoluK+9poPfraaPfra1OE
        TgOeopwyNMAuFMHOHjXkQnGx+J/6mXzr8+vS5UbzeZ/wfXXz539fed/8foO8mcigI+zCYEG3scdrYstk0QxdOJhwtHKLV4ie1fCBZkgr1ntSUpknCZ/T1hSsZr1V6xpOT53WHlFarpBvzUzq+BhMIQ33sLB74PMxUKIRG3bVyFq670JYAbSIN/LqMt4oXwWfxJrG/C1ZemPIEog3ppoa0/si5PRUoqwxZo29Mmlm8v/cc1dFpJMYYXMICn+BlMJmOkgsLYUPUHenTZKxDrWh+1ANHXlwU2y/wEoQ2CMH2CMU
        EIn1Tc9afGsJdpOWBGHO397XTeJxBVLDOIHWYoU5zlZObaw6R4I7U7ittLRJdqb8Ql+E8lhKiqBGSxkuHC3vbVsopEiau4nPgckKT3PPhYK3QXw7hJhrlmmPnJ5aWLj/4+tHhJNEF49K2wAPDGBVrpIs2OR688WsCs4qPnaSv/SqDT656swrr5wRh/3d6tV/t5rL4tkgi/mzdRFjjDCtTHconWblxnZfKlX0pN2SfOFxoVWKrxty+HhGb/dT9mp6vfJn8ZJH+MtXip7MW/yPy4wW4RY5x2poo0KRKHVwUco7
        xooSE3HFvTbyXTbMvvm3yCV2jVj+GsDMvS+jE7xIU1GB7q2iHh3Nd2+qacA4CUQ4tUiIKmtHpqoWtXtVNWh3B8oCr60j42WPu/O6bPVZgdQ6XPV6JG0ISodelspaVHYimDYsDt5b9gE0Yo2QB51ymRB7iESoDB/PmMooLLylCKx7EQuhhhTNl7GGE81cpDem6wY2xRvTg4QoLxmRsdmUyjtPyekoqvWokGjeqqpbj4wi3ycnzZqRHZQOJldyN61W0Ukjg4m8kTQuxAeA0TR9aRR5b3nDs43LLhDL6aPXt61b
        Vj+/fvm6v/346K+JeoHty/7iaWW/soX1+fs+03ZS2izmNmzOVOHR1Ni/2urWUqkU68SgnjUrrft+bgaykhUESoCXj5qtGng5MuvT4Alg/xbzEUwg+OP+uEa0dBl7wpAUl7T+5PxWcS3Zk/3dR/rKC8+eVxpmz85tFqfAz2nsCZhL4vMLsRqB8xYWWk+XpwP3J4p6mRb1J7DgrmIvpr5p0GIa/gk+PEjEPt9yRI2zKowVPD6iu9KFZt/4mDnVixWdPdp+e3gJho+lyGu87bfGgK6GBPNp+fYV6JXFPC+QU20R
        t/lsxMJjj4sqNsEYMwIRViRrBEvMvPlCk3DWxQp+8k4R3i5cXLCPPrZfnPzEs6NGPWc2Dv/WrKfubJROYu9wsrbQw5nNU7rN+pkHsebOW1SH3kdT8za/RbXWM3H8RVubI3ZB7B/kZnyfXc4xxFHodC7+533dY2bNzrvSrLGzIPx/Onl0AQAAAAEAAAACAAARW1RJXw889QAfCAAAAAAA0ZIBKAAAAADZu6uK/7791wjRB+kAAAAIAAIAAAAAAAB42mNgZGDgmPMnlIGB0/H/vv+3OS4yAEVQwHMApI0HmXja
        bVNNSFRRGD33vvu9mVxIxBBMBbUxFYlhiGGQYQgnwWJCxxYSMgwyyCAuRM0sUoJW0ioeYj+zEamgQiJcSLSQ3FlYCzdDSIuQCKnJojYFYee+pzCYA4fz5uP77vneOe/qr2gHf7oUwOlQKX0eGXcFFySJCbmGglxCp0ogYyZV0txAv7OFq1LFNzWJnFnAsnqHEdOFx3IYU9rDoJnDZbOIATmEPHFHTuKhuY6LEsFN4qkexDOdwBL7N9wWpc2aqheN1wfaMCJT6DWfUHaTyImHsqlD2fmy838MZVVlbRAls8L6
        EOvrKMsMuY27zJI7yFvsWcCEKaBXirgnw4iHKohLmHPTOG56UHLeqHMyrs6QG6Uene6oalCbiEieOnnOr/qcMxvI6QeI+XXX159Q1e0lcyTYJdxEfdZ5hj9n+/QPzicwoq2mi9tmFFF3HFFnDVEzRp5FjympZj2AiunDe9OETt/7fpWSF/DkGPImy/25q99zF3ndhYjbjaJzH+3Oc/r6mT303tb0b0ScLFLyE9/1NOJ6Do3qA9/lIPdgjqqCK/oEIuoWmp1Jf75EjQzzzcgoEvT+pfV9P4RacNR6b3OoBd+9
        LsD2OrEhCwjv5rAX8ko1kFv9LGrgZ8HMJB34th/C89ReDXKoxX/6dUE+fg578QQfySmbRS1sFn7W5PAjank823reh4iep6e/gNAmc9lhfgdQf4juAPhLniEPAzaLXcgilkMF3osk4sRpe090lrlkg2fHw1vDO2NndZrZplGy57pFtLpnccrwWzAeYoj9A691y2YAAHjaY2Bg0IHCHIYljE1MBkxHmF2Yk5h7mLcx32GRY/FiSWLpYNnHKsfqwrqMzYCtjO0Newx7A/sPjiyOeRxPOH5wSnHqcc7jsuDK4rrE
        rcCdxD2P+wqPFk8STwPPHp53vCK8Qbw9vDf4ZPgq+M7wT+M/JsAkoCbQILBJ4JEgn6CNYJHgEsFDgncEfwkFCK0S+iLsJ7xN+I9IicgukU+iNqJ5oh2ib8T0xCrEzomriAeJf5NwkGiRWCHJIGkkGSRZJ/lKikkqR2oNEL6TjpG+JxMis0fmnmyd7Da5CLlNcufkvsnzye+Sv6Qgo5CmsE/RSjFGcZHiPSUmJSelIqVdSjeUk5S/qJxSdVKtUd2m+kUtSe2Eupl6hwaXRpDGLI1nmmGaVZrzNE9pyWi1aH3R
        9tKep/1Np0bnn66C7hTdW3oKehl6V/S59EP0LxkYGPQZfDCsMWIymmHsYHzAxMhkiamE6RbTG6Z/zDTMqsxemSeYb7JgsYiyuGMZZjnJis0qyGqe1TvrBOsO63s2ejZrbL1sN9jesJPAAdXsTOyc7KLsiuyW2N2yZ7OPst9l/8xBwCEGCOscFjgscFRz7HJc5STnVAYAqNOPJgABAAAA6AA0AAUAAAAAAAIAAQACABYAAAEAAUcAAAAAeNqlVNtOE1EUXdN21BO1MeGiMYScB6KtkaHcKSQVogGVYtESfSEm
        pTcqpa3tFGLis19gjPHRDzA++gle3k1890NcZ8+hMPVB1E7mnHX2Xvty9t5TAENOBA7MbwBxrlE4McX9FU8BdoheWxwhfm9xFDP4aHEMo/hpsYu39BngMxh13ll8FtvOJ4vP4Vpk0GKF7ciixeexHHlj8QXi7xZfdHR02OI4ZmJzFg/gQ+yZxZ8x7F6x+AtSrmfxV8TdpxZ/wyW3HuAfUVx1X+rQD7fRRAsv0EYNVezCh0YCRSS5TyGFSd5Z4x4aKJFR4K6xRX4LZaJVWndF16bMWNZ4qlC6LOjIxrcWlRDf
        Y5wm9hlrU7JoiU0Hj8isklenbZtZeMzDPEvMY4sxc0Rhi/E+m7BW92kf89SmvEZWQ+55HGETeUomRbLEejSZe1F4Bz25h3mkqd2ntz36MpwKpXV63BFvs3znyE5jWvxoMgynwBx88ncls6DWB+J1+rcq/OlONVkL4rvNvUT2vjD3KGsy2mn75p2S90Bim0ga69zrwkngDvcDsS0w34LMUhI3qbsvlWvIbGyKtaloW6IkkCXXF2lSJs9n1EVM8DmUx/uLGfpf+y3pScfeOehNXqro05O5U7lXc9PnIs+mD2Wp
        RuDPTJXpxq5w86xqlntOojZCnrMhD6ZS/TNpZsK8+kRm4bjHE2BqX5Mp2bE9OeTZ5BHMxypW8FCwz/rovlp16NN0rEVZhxE74suTPlWpz9E++y82akGNq2Vo5amEWlHzakNNqHUVoWRIrXO9rLIqpWaJBlWKq6uuq1vUZSg94q2JfYbvlMVrohmhzw2eRqgb43mBp1k1JtIxvimumZ7nBfGRsLIbos/IbmzT6q4q4QkrtyPTEfQo+NZN9bvSxaJINb9UTXmalZghXuSb7v1XpmUKgu+8LrUpSk3MV9vl6eRs
        PaekRp2ZrPovJCD/2gB42m3QR0xUYRDA8f/AsgtL79Xe+3tvWYp9F3j23rsosLuKgIurYkNjr9GY6Aliu6ix12jUgxp7iyXqwbM9HtSrLrzPm3P5ZSaZycwQQWv8gSr+Fx9BIiSSSGxEYcdBNDE4iSWOeBJIJIlkUkgljXQyyCSLbHLIJY82tKUd7elARzrRmS50pRvd6UFPetGbPvSlHxo6Bi7ycVNAIUUU058BDGQQgxnCUDx4KaGUMkyGMZwRjGQUoxnDWMYxnglMZBKTmcJUpjGdGcxkFrOZw1zmMZ9y
        sXGUTWzmBgfCF21hDztp4jjHJIodvGcj+8UuDnZzkG3c5oNE08wJfvGT3xzhFA+4x2kWsJC9VPCISu7zkGc85glP+RT+3kue84Iz+PjBPt7witf4+cI3trOIAItZQjU1HKKWpdQRpJ4Qy1jOCj6zklU0sJq1rOEqh2lkHevZwFe+c42znOM6b3knMeKUWImTeEmQREmSZEmRVEmTdMngPBe4zBXucJFL3GUrJyWTm9ySLMlml+RIruTZfdUNdX7dEaoJaJpWaunRlCr3GkqXsrhFI9yg1JWG0qXMV7qVBcpC
        ZZHy3zyPpa7m6rqzKuALBSsryuv9VskwLd2mrSwUrG1N3GZJi6bX2iOs8ReQfJgNeNpFzbsOgkAQheEdFpaLyMVgYYwJxnI7awsLoKExVmzCA5jY22pjqc8yWBlfDkdcoJvvL868ob0jPFiJzqFqAJ6qKYSs1hipEpMjHTe1QiHriiFPc+QyQzPNXzw2ZAeLYM41BME6a9gEUWs4BHuv4RKcrYZHcPuBCcFb/gHo64fTNP8wHwJmyIYXFypBV67tWMJfCWAzloi2wtPAmBjtBs6I8aKnwkR+AaHiSTAAAAAA
        AV2V+wsAAA==
    `;
    const str = `
        <defs>
            <style type="text/css">
                @font-face {
                    font-family: 'Poppins';
                    src: url(data:application/font-woff;charset=utf-8;base64,${base64.replace(/\s/g, "")}) format('woff');
                    font-weight: normal;
                    font-style: normal;
                }
            </style>
        </defs>
    `;
    return str;
};

interface BasicSvgProps {
    className?: string;
    isPdf?: boolean;

    category: number;
    rodWidth?: number;
    finishedLengthOfPanels?: number;

    height?: number;
    hideBorder?: boolean;
    viewBox?: {x: number, y: number, width: number, height: number};
}

interface SingleSvgProps extends BasicSvgProps {
    panelWidth?: number;
}

export const SingleSvg = (props: SingleSvgProps) => {
    return (
        <svg
            id="bkSvg"
            className={props.className}
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={props.viewBox ? `${props.viewBox.x} ${props.viewBox.y} ${props.viewBox.width} ${props.viewBox.height}` : "0 0 500 300"}
            style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
        >

            <rect x="149.25" y="42.62" width="195.81" height="3.01" />
            <rect x="343.58" y="38.72" width="2.59" height="10.79" />
            <rect x="149.42" y="38.72" width="2.59" height="10.79" />
            <circle cx="145.15" cy="44.02" r="4.1" />
            <circle cx="349.99" cy="44.02" r="4.1" />

            <rect x="158.41" y={52} width="178.93" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2px"}} />

            {!props.hideBorder &&
                <path d="M499,1V299H1V1H499m1-1H0V300H500V0Z" />
            }

            {props.category === 0 && props.rodWidth &&
                <>
                    <path d="M239.21,19.6s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46h16.72s3.54,0,3.54-3.46V23.06a3.41,3.41,0,0,0-3.54-3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    <line x1="215.5" y1="25.5" x2="151.5" y2="25.5" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1={344} y1="25.5" x2={278} y2="25.5" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <path d="M240,10.93c1.32,0,1.94.73,1.94,1.64a1.57,1.57,0,0,1-1.33,1.6l1.4,2.34h-.87l-1.33-2.28h-.88v2.28h-.72V10.93Zm0,.6H238.9v2.11H240c.83,0,1.19-.44,1.19-1.07S240.81,11.53,240,11.53Z" />
                    <path d="M245.57,16.56a2.74,2.74,0,0,1-2.8-2.84,2.8,2.8,0,1,1,5.59,0A2.74,2.74,0,0,1,245.57,16.56Zm0-.63a2,2,0,0,0,2.05-2.21,2.06,2.06,0,1,0-4.11,0A2,2,0,0,0,245.57,15.93Z" />
                    <path d="M254,13.73a2.66,2.66,0,0,1-3,2.78h-1.73V10.93h1.73A2.68,2.68,0,0,1,254,13.73Zm-3,2.18a2,2,0,0,0,2.22-2.18,2,2,0,0,0-2.22-2.2h-1v4.38Z" />
                    <line x1="344.5" y1={28} x2="344.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="151.5" y1={28} x2="151.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="rod-text" x="247" y={29} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.rodWidth}“</text>
                </>
            }

            {props.isPdf && props.finishedLengthOfPanels &&
                <>
                    <path d="M371.36,164.33s3.54,0,3.54-3.46v-7.38a3.42,3.42,0,0,0-3.54-3.47H354.64s-3.54,0-3.54,3.47v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M379.53,151.58h3.15v.59h-2.42v1.88h2v.59h-2v2.51h-.73Z" />
                    <path d="M384.29,151.58v5h1.95v.59h-2.68v-5.57Z" /> */}
                    <line x1="362.16" y1="54.75" x2="362.16" y2={144} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="359.16" y1="54.39" x2="365.16" y2="54.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="362.16" y1="261.87" x2="362.16" y2="170.62" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="365.16" y1="262.23" x2="359.16" y2="262.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="fl-text" x="373" y={160} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.finishedLengthOfPanels}“
                        <tspan style={{fill: "#000", fontSize: "10px", fontWeight: "200"}}> FL</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth &&
                <>
                    <path d="M242.19,286.85s3.54,0,3.54-3.46V276a3.41,3.41,0,0,0-3.54-3.46H225.47s-3.54,0-3.54,3.46v7.39a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M248.82,275.27h.78l1.21,4.72,1.34-4.72H253l1.28,4.71,1.22-4.71h.79l-1.62,5.57h-.81l-1.3-4.48-1.34,4.48h-.81Z" />
                    <path d="M256.94,275.25a.49.49,0,1,1,.5.5A.5.5,0,0,1,256.94,275.25Zm.12,1.21h.73v4.38h-.73Z" />
                    <path d="M260.84,276.39a1.93,1.93,0,0,1,1.63.85v-2.32h.73v5.92h-.73V280a1.81,1.81,0,0,1-1.64.89,2.11,2.11,0,0,1-2.07-2.28A2.07,2.07,0,0,1,260.84,276.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61A1.49,1.49,0,1,0,261,277Z" />
                    <path d="M264.6,277.06H264v-.6h.57v-1.1h.72v1.1h1.15v.6h-1.15v2.58c0,.43.16.59.61.59h.54v.61h-.66c-.77,0-1.21-.32-1.21-1.2Z" />
                    <path d="M267.35,274.92h.73v2.17a1.64,1.64,0,0,1,1.44-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48c0-.89-.48-1.35-1.22-1.35s-1.25.47-1.25,1.41v2.42h-.73Z" />
                    <path d="M274,280.91a1.6,1.6,0,0,1-1.79-1.36H273c0,.43.42.75,1,.75s.9-.29.9-.65c0-1-2.59-.41-2.59-2,0-.67.63-1.22,1.6-1.22a1.47,1.47,0,0,1,1.66,1.37h-.73a.84.84,0,0,0-1-.76c-.54,0-.86.26-.86.61,0,1,2.55.47,2.58,2C275.62,280.37,275,280.91,274,280.91Z" /> */}
                    <text id="panel-width-text" x="247.5" y={283} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }
        </svg>
    );
};

interface LeftSvgProps extends BasicSvgProps {
    panelWidth?: number;
}

export const LeftSvg = (props: LeftSvgProps) => {

    return (
        <svg
            id="bkSvg"
            className={props.className}
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={props.viewBox ? `${props.viewBox.x} ${props.viewBox.y} ${props.viewBox.width} ${props.viewBox.height}` : "0 0 500 300"}
            style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
        >
            <defs/>

            <rect x="149.25" y="42.62" width="195.81" height="3.01" />
            <rect x="343.58" y="38.72" width="2.59" height="10.79" />
            <rect x="149.42" y="38.72" width="2.59" height="10.79" />
            <circle cx="145.15" cy="44.02" r="4.1" />
            <circle cx="349.99" cy="44.02" r="4.1" />

            <rect x="158.67" y={54} width="81.82" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />

            {!props.hideBorder &&
                <path d="M499,1V299H1V1H499m1-1H0V300H500V0Z" />
            }

            {props.category === 0 && props.rodWidth &&
                <>
                    <path d="M239.21,19.6s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46h16.72s3.54,0,3.54-3.46V23.06a3.41,3.41,0,0,0-3.54-3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    <line x1="215.5" y1="25.5" x2="151.5" y2="25.5" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1={344} y1="25.5" x2={278} y2="25.5" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <path d="M240,10.93c1.32,0,1.94.73,1.94,1.64a1.57,1.57,0,0,1-1.33,1.6l1.4,2.34h-.87l-1.33-2.28h-.88v2.28h-.72V10.93Zm0,.6H238.9v2.11H240c.83,0,1.19-.44,1.19-1.07S240.81,11.53,240,11.53Z" />
                    <path d="M245.57,16.56a2.74,2.74,0,0,1-2.8-2.84,2.8,2.8,0,1,1,5.59,0A2.74,2.74,0,0,1,245.57,16.56Zm0-.63a2,2,0,0,0,2.05-2.21,2.06,2.06,0,1,0-4.11,0A2,2,0,0,0,245.57,15.93Z" />
                    <path d="M254,13.73a2.66,2.66,0,0,1-3,2.78h-1.73V10.93h1.73A2.68,2.68,0,0,1,254,13.73Zm-3,2.18a2,2,0,0,0,2.22-2.18,2,2,0,0,0-2.22-2.2h-1v4.38Z" />
                    <line x1="344.5" y1={28} x2="344.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="151.5" y1={28} x2="151.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="rod-text" x="247" y={29} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.rodWidth}“</text>
                </>
            }

            {props.isPdf && props.finishedLengthOfPanels &&
                <>
                    <path d="M371.36,164.33s3.54,0,3.54-3.46v-7.38a3.42,3.42,0,0,0-3.54-3.47H354.64s-3.54,0-3.54,3.47v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M379.53,151.58h3.15v.59h-2.42v1.88h2v.59h-2v2.51h-.73Z" />
                    <path d="M384.29,151.58v5h1.95v.59h-2.68v-5.57Z" /> */}
                    <line x1="362.16" y1="54.75" x2="362.16" y2={144} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="359.16" y1="54.39" x2="365.16" y2="54.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="362.16" y1="261.87" x2="362.16" y2="170.62" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="365.16" y1="262.23" x2="359.16" y2="262.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="fl-text" x="373" y={160} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.finishedLengthOfPanels}“
                        <tspan style={{fill: "#000", fontSize: "10px", fontWeight: "200"}}> FL</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth &&
                <>
                    <path d="M192.36,287.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H175.64s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M199,275.58h.77L201,280.3l1.34-4.72h.82l1.28,4.71,1.22-4.71h.78l-1.61,5.57H204l-1.29-4.49-1.35,4.49h-.8Z" />
                    <path d="M207.11,275.56a.49.49,0,1,1,.49.5A.49.49,0,0,1,207.11,275.56Zm.12,1.21H208v4.38h-.73Z" />
                    <path d="M211,276.7a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74v-.82a1.8,1.8,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.07,2.07,0,0,1,211,276.7Zm.14.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M214.76,277.37h-.56v-.6h.56v-1.11h.73v1.11h1.14v.6h-1.14V280c0,.43.16.58.61.58h.53v.62H216c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M217.52,275.23h.72v2.17a1.64,1.64,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48c0-.9-.49-1.35-1.22-1.35s-1.26.46-1.26,1.41v2.42h-.72Z" />
                    <path d="M224.19,281.22c-1,0-1.75-.56-1.79-1.36h.75c0,.43.41.74,1,.74s.89-.28.89-.64c0-1-2.59-.41-2.59-2,0-.67.64-1.22,1.61-1.22a1.47,1.47,0,0,1,1.66,1.37H225a.84.84,0,0,0-1-.76c-.54,0-.86.26-.86.61,0,1,2.54.47,2.58,2C225.79,280.68,225.15,281.22,224.19,281.22Z" /> */}
                    <text id="panel-width-text" x="198" y={283} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }
        </svg>
    );
};

interface RightSvgProps extends BasicSvgProps {
    panelWidth?: number;
}

export const RightSvg = (props: RightSvgProps) => {

    return (
        <svg
            id="bkSvg"
            className={props.className}
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={props.viewBox ? `${props.viewBox.x} ${props.viewBox.y} ${props.viewBox.width} ${props.viewBox.height}` : "0 0 500 300"}
            style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
        >
            <defs/>

            <rect x="149.25" y="42.62" width="195.81" height="3.01" />
            <rect x="343.58" y="38.72" width="2.59" height="10.79" />
            <rect x="149.42" y="38.72" width="2.59" height="10.79" />
            <circle cx="145.15" cy="44.02" r="4.1" />
            <circle cx="349.99" cy="44.02" r="4.1" />

            <rect x="251.94" y="53.73" width="86.06" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1.0255852937698364px"}} />

            {!props.hideBorder &&
                <path d="M499,1V299H1V1H499m1-1H0V300H500V0Z" />
            }

            {props.category === 0 && props.rodWidth &&
                <>
                    <path d="M239.21,19.6s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46h16.72s3.54,0,3.54-3.46V23.06a3.41,3.41,0,0,0-3.54-3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    <line x1="215.5" y1="25.5" x2="151.5" y2="25.5" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1={344} y1="25.5" x2={278} y2="25.5" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <path d="M240,10.93c1.32,0,1.94.73,1.94,1.64a1.57,1.57,0,0,1-1.33,1.6l1.4,2.34h-.87l-1.33-2.28h-.88v2.28h-.72V10.93Zm0,.6H238.9v2.11H240c.83,0,1.19-.44,1.19-1.07S240.81,11.53,240,11.53Z" />
                    <path d="M245.57,16.56a2.74,2.74,0,0,1-2.8-2.84,2.8,2.8,0,1,1,5.59,0A2.74,2.74,0,0,1,245.57,16.56Zm0-.63a2,2,0,0,0,2.05-2.21,2.06,2.06,0,1,0-4.11,0A2,2,0,0,0,245.57,15.93Z" />
                    <path d="M254,13.73a2.66,2.66,0,0,1-3,2.78h-1.73V10.93h1.73A2.68,2.68,0,0,1,254,13.73Zm-3,2.18a2,2,0,0,0,2.22-2.18,2,2,0,0,0-2.22-2.2h-1v4.38Z" />
                    <line x1="344.5" y1={28} x2="344.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="151.5" y1={28} x2="151.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="rod-text" x="247" y={29} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.rodWidth}“</text>
                </>
            }

            {props.isPdf && props.finishedLengthOfPanels &&
                <>
                    <path d="M371.36,164.33s3.54,0,3.54-3.46v-7.38a3.42,3.42,0,0,0-3.54-3.47H354.64s-3.54,0-3.54,3.47v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M379.53,151.58h3.15v.59h-2.42v1.88h2v.59h-2v2.51h-.73Z" />
                    <path d="M384.29,151.58v5h1.95v.59h-2.68v-5.57Z" /> */}
                    <line x1="362.16" y1="54.75" x2="362.16" y2={144} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="359.16" y1="54.39" x2="365.16" y2="54.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="362.16" y1="261.87" x2="362.16" y2="170.62" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="365.16" y1="262.23" x2="359.16" y2="262.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="fl-text" x="373" y={160} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.finishedLengthOfPanels}“
                        <tspan style={{fill: "#000", fontSize: "10px", fontWeight: "200"}}> FL</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth &&
                <>
                    <path d="M285.16,286.85s3.54,0,3.54-3.46V276a3.41,3.41,0,0,0-3.54-3.46H268.44s-3.54,0-3.54,3.46v7.39a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M291.79,275.27h.77l1.22,4.72,1.34-4.72h.82l1.28,4.71,1.22-4.71h.78l-1.61,5.57h-.82l-1.29-4.48-1.35,4.48h-.8Z" />
                    <path d="M299.91,275.25a.49.49,0,1,1,1,0,.49.49,0,1,1-1,0Zm.12,1.21h.72v4.38H300Z" />
                    <path d="M303.81,276.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V280a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.08,2.08,0,0,1,303.81,276.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61A1.49,1.49,0,1,0,304,277Z" />
                    <path d="M307.56,277.06H307v-.6h.56v-1.1h.73v1.1h1.14v.6h-1.14v2.58c0,.43.16.59.61.59h.53v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M310.31,274.92H311v2.17a1.66,1.66,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48A1.18,1.18,0,0,0,312.3,277c-.75,0-1.26.47-1.26,1.41v2.42h-.73Z" />
                    <path d="M317,280.91c-1,0-1.75-.56-1.79-1.36H316c0,.43.41.75,1,.75s.89-.29.89-.65c0-1-2.59-.41-2.59-2,0-.67.64-1.22,1.61-1.22a1.46,1.46,0,0,1,1.65,1.37h-.72a.84.84,0,0,0-.95-.76c-.54,0-.86.26-.86.61,0,1,2.54.47,2.57,2C318.58,280.37,318,280.91,317,280.91Z" /> */}
                    <text id="panel-width-text" x="291" y={283} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }
        </svg>
    );
};

interface PairSvgProps extends BasicSvgProps {
    panelWidthLeft?: number;
    panelWidthRight?: number;
}

export const PairSvg = (props: PairSvgProps)  => {

    return (
        <svg
            id="bkSvg"
            className={props.className}
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={props.viewBox ? `${props.viewBox.x} ${props.viewBox.y} ${props.viewBox.width} ${props.viewBox.height}` : "0 0 500 300"}
            style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
        >
            <defs/>

            <rect x="149.25" y="42.62" width="195.81" height="3.01" />
            <rect x="343.58" y="38.72" width="2.59" height="10.79" />
            <rect x="149.42" y="38.72" width="2.59" height="10.79" />
            <circle cx="145.15" cy="44.02" r="4.1" />
            <circle cx="349.99" cy="44.02" r="4.1" />

            <rect x="158.67" y={54} width="81.82" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x="251.94" y="53.73" width="86.06" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "1.0255852937698364px"}} />

            {!props.hideBorder &&
                <path d="M499,1V299H1V1H499m1-1H0V300H500V0Z" />
            }

            {props.category === 0 && props.rodWidth &&
                <>
                    <path d="M239.21,19.6s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46h16.72s3.54,0,3.54-3.46V23.06a3.41,3.41,0,0,0-3.54-3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    <line x1="215.5" y1="25.5" x2="151.5" y2="25.5" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1={344} y1="25.5" x2={278} y2="25.5" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <path d="M240,10.93c1.32,0,1.94.73,1.94,1.64a1.57,1.57,0,0,1-1.33,1.6l1.4,2.34h-.87l-1.33-2.28h-.88v2.28h-.72V10.93Zm0,.6H238.9v2.11H240c.83,0,1.19-.44,1.19-1.07S240.81,11.53,240,11.53Z" />
                    <path d="M245.57,16.56a2.74,2.74,0,0,1-2.8-2.84,2.8,2.8,0,1,1,5.59,0A2.74,2.74,0,0,1,245.57,16.56Zm0-.63a2,2,0,0,0,2.05-2.21,2.06,2.06,0,1,0-4.11,0A2,2,0,0,0,245.57,15.93Z" />
                    <path d="M254,13.73a2.66,2.66,0,0,1-3,2.78h-1.73V10.93h1.73A2.68,2.68,0,0,1,254,13.73Zm-3,2.18a2,2,0,0,0,2.22-2.18,2,2,0,0,0-2.22-2.2h-1v4.38Z" />
                    <line x1="344.5" y1={28} x2="344.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="151.5" y1={28} x2="151.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="rod-text" x="247" y={29} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.rodWidth}“</text>
                </>
            }

            {props.isPdf && props.finishedLengthOfPanels &&
                <>
                    <path d="M371.36,164.33s3.54,0,3.54-3.46v-7.38a3.42,3.42,0,0,0-3.54-3.47H354.64s-3.54,0-3.54,3.47v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M379.53,151.58h3.15v.59h-2.42v1.88h2v.59h-2v2.51h-.73Z" />
                    <path d="M384.29,151.58v5h1.95v.59h-2.68v-5.57Z" /> */}
                    <line x1="362.16" y1="54.75" x2="362.16" y2={144} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="359.16" y1="54.39" x2="365.16" y2="54.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="362.16" y1="261.87" x2="362.16" y2="170.62" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="365.16" y1="262.23" x2="359.16" y2="262.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="fl-text" x="373" y={160} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.finishedLengthOfPanels}“
                        <tspan style={{fill: "#000", fontSize: "10px", fontWeight: "200"}}> FL</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidthLeft &&
                <>
                    <path d="M192.36,287.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H175.64s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M199,275.58h.77L201,280.3l1.34-4.72h.82l1.28,4.71,1.22-4.71h.78l-1.61,5.57H204l-1.29-4.49-1.35,4.49h-.8Z" />
                    <path d="M207.11,275.56a.49.49,0,1,1,.49.5A.49.49,0,0,1,207.11,275.56Zm.12,1.21H208v4.38h-.73Z" />
                    <path d="M211,276.7a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74v-.82a1.8,1.8,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.07,2.07,0,0,1,211,276.7Zm.14.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M214.76,277.37h-.56v-.6h.56v-1.11h.73v1.11h1.14v.6h-1.14V280c0,.43.16.58.61.58h.53v.62H216c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M217.52,275.23h.72v2.17a1.64,1.64,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48c0-.9-.49-1.35-1.22-1.35s-1.26.46-1.26,1.41v2.42h-.72Z" />
                    <path d="M224.19,281.22c-1,0-1.75-.56-1.79-1.36h.75c0,.43.41.74,1,.74s.89-.28.89-.64c0-1-2.59-.41-2.59-2,0-.67.64-1.22,1.61-1.22a1.47,1.47,0,0,1,1.66,1.37H225a.84.84,0,0,0-1-.76c-.54,0-.86.26-.86.61,0,1,2.54.47,2.58,2C225.79,280.68,225.15,281.22,224.19,281.22Z" /> */}
                    <text id="panel-width-text" x="200" y={283} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidthLeft}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidthRight &&
                <>
                    <path d="M285.16,286.85s3.54,0,3.54-3.46V276a3.41,3.41,0,0,0-3.54-3.46H268.44s-3.54,0-3.54,3.46v7.39a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M291.79,275.27h.77l1.22,4.72,1.34-4.72h.82l1.28,4.71,1.22-4.71h.78l-1.61,5.57h-.82l-1.29-4.48-1.35,4.48h-.8Z" />
                    <path d="M299.91,275.25a.49.49,0,1,1,1,0,.49.49,0,1,1-1,0Zm.12,1.21h.72v4.38H300Z" />
                    <path d="M303.81,276.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V280a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.08,2.08,0,0,1,303.81,276.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61A1.49,1.49,0,1,0,304,277Z" />
                    <path d="M307.56,277.06H307v-.6h.56v-1.1h.73v1.1h1.14v.6h-1.14v2.58c0,.43.16.59.61.59h.53v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M310.31,274.92H311v2.17a1.66,1.66,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48A1.18,1.18,0,0,0,312.3,277c-.75,0-1.26.47-1.26,1.41v2.42h-.73Z" />
                    <path d="M317,280.91c-1,0-1.75-.56-1.79-1.36H316c0,.43.41.75,1,.75s.89-.29.89-.65c0-1-2.59-.41-2.59-2,0-.67.64-1.22,1.61-1.22a1.46,1.46,0,0,1,1.65,1.37h-.72a.84.84,0,0,0-.95-.76c-.54,0-.86.26-.86.61,0,1,2.54.47,2.57,2C318.58,280.37,318,280.91,317,280.91Z" /> */}
                    <text id="panel-width-text" x="292" y={283} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidthRight}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }
        </svg>
    );
};

interface ThreePanelsSvgProps extends BasicSvgProps {
    panelWidthLeft?: number;
    panelWidthMiddle?: number;
    panelWidthRight?: number;
}

export const ThreePanelsSvg = (props: ThreePanelsSvgProps) => {

    return (
        <svg
            id="bkSvg"
            className={props.className}
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={props.viewBox ? `${props.viewBox.x} ${props.viewBox.y} ${props.viewBox.width} ${props.viewBox.height}` : "0 0 500 300"}
            style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
        >
            <defs/>

            <rect x="109.25" y="37.62" width="271.81" height="3.01" />
            <rect x="113.42" y="33.72" width="2.59" height="10.79" />
            <circle cx="109.15" cy="39.02" r="4.1" />
            <rect x="374.58" y="33.72" width="2.59" height="10.79" />
            <circle cx="380.99" cy="39.02" r="4.1" />

            <rect x="204.34" y="53.69" width="79.38" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x="289.06" y="53.69" width="79.38" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x="119.63" y="53.69" width="79.38" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />

            {!props.hideBorder &&
                <path d="M499,1V299H1V1H499m1-1H0V300H500V0Z" />
            }

            {props.category === 0 && props.rodWidth &&
                <>
                    <path d="M116,27" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <path d="M185,27" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <path d="M237.86,19.6s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46h16.72s3.54,0,3.54-3.46V23.06a3.41,3.41,0,0,0-3.54-3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    <path d="M239.94,10.93c1.31,0,1.94.73,1.94,1.64a1.57,1.57,0,0,1-1.33,1.6l1.4,2.34h-.87l-1.33-2.28h-.88v2.28h-.73V10.93Zm0,.6h-1.07v2.11h1.07c.83,0,1.19-.44,1.19-1.07S240.78,11.53,239.94,11.53Z" />
                    <path d="M245.54,16.56a2.74,2.74,0,0,1-2.8-2.84,2.8,2.8,0,1,1,5.59,0A2.74,2.74,0,0,1,245.54,16.56Zm0-.63a2,2,0,0,0,2.05-2.21,2.06,2.06,0,1,0-4.11,0A2,2,0,0,0,245.54,15.93Z" />
                    <path d="M254,13.73a2.66,2.66,0,0,1-3,2.78H249.3V10.93H251A2.68,2.68,0,0,1,254,13.73Zm-3,2.18a2,2,0,0,0,2.22-2.18,2,2,0,0,0-2.22-2.2h-1v4.38Z" />
                    <line x1={374} y1={26} x2={278} y2={26} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1={214} y1="25.5" x2={116} y2="25.5" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="374.5" y1={29} x2="374.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="115.5" y1={29} x2="115.42" y2={23} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="rod-text" x="247" y={29} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.rodWidth}“</text>
                </>
            }

            {props.isPdf && props.finishedLengthOfPanels &&
                <>
                    <path d="M400.36,164.33s3.54,0,3.54-3.46v-7.38a3.42,3.42,0,0,0-3.54-3.47H383.64s-3.54,0-3.54,3.47v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M408.53,151.58h3.15v.59h-2.42v1.88h2v.59h-2v2.51h-.73Z" />
                    <path d="M413.29,151.58v5h1.95v.59h-2.68v-5.57Z" /> */}
                    <line x1="391.16" y1="54.75" x2="391.16" y2={144} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="388.16" y1="54.39" x2="394.16" y2="54.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="391.16" y1="261.87" x2="391.16" y2="170.62" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="394.16" y1="262.23" x2="388.16" y2="262.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="fl-text" x="402" y={158} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.finishedLengthOfPanels}“
                        <tspan style={{fill: "#000", fontSize: "10px", fontWeight: "200"}}> FL</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidthLeft &&
                <>
                    <path d="M151.63,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H134.91s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M158.26,271.27H159l1.22,4.72,1.34-4.72h.82l1.28,4.71,1.22-4.71h.78l-1.61,5.57h-.82L162,272.36l-1.35,4.48h-.8Z" />
                    <path d="M166.38,271.25a.49.49,0,1,1,1,0,.49.49,0,1,1-1,0Zm.12,1.21h.73v4.38h-.73Z" />
                    <path d="M170.28,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.08,2.08,0,0,1,170.28,272.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M174,273.06h-.56v-.6H174v-1.1h.73v1.1h1.14v.6h-1.14v2.58c0,.43.16.59.61.59h.53v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M176.79,270.92h.72v2.17a1.66,1.66,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58H180v-2.48a1.18,1.18,0,0,0-1.22-1.35c-.75,0-1.26.47-1.26,1.41v2.42h-.72Z" />
                    <path d="M183.46,276.91c-1,0-1.75-.56-1.79-1.36h.75c0,.43.41.75,1,.75s.89-.29.89-.65c0-1-2.59-.41-2.59-2,0-.67.64-1.22,1.61-1.22a1.47,1.47,0,0,1,1.66,1.37h-.73a.84.84,0,0,0-.95-.76c-.54,0-.86.26-.86.61,0,1,2.54.47,2.58,2C185.06,276.37,184.42,276.91,183.46,276.91Z" /> */}
                    <text id="panel-width-text" x="157" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidthLeft}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidthMiddle &&
                <>
                    <path d="M236.27,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H219.55s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M242.9,271.27h.77l1.22,4.72,1.34-4.72h.82l1.28,4.71,1.22-4.71h.78l-1.61,5.57h-.82l-1.29-4.48-1.34,4.48h-.81Z" />
                    <path d="M251,271.25a.49.49,0,1,1,1,0,.49.49,0,1,1-1,0Zm.12,1.21h.73v4.38h-.73Z" />
                    <path d="M254.92,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.08,2.08,0,0,1,254.92,272.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M258.67,273.06h-.56v-.6h.56v-1.1h.73v1.1h1.14v.6H259.4v2.58c0,.43.16.59.61.59h.53v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M261.43,270.92h.72v2.17a1.78,1.78,0,0,1,3.2,1.17v2.58h-.72v-2.48a1.18,1.18,0,0,0-1.22-1.35c-.74,0-1.26.47-1.26,1.41v2.42h-.72Z" />
                    <path d="M268.1,276.91c-1,0-1.75-.56-1.79-1.36h.75c0,.43.41.75,1,.75s.9-.29.9-.65c0-1-2.6-.41-2.6-2,0-.67.64-1.22,1.61-1.22a1.47,1.47,0,0,1,1.66,1.37h-.73A.84.84,0,0,0,268,273c-.54,0-.86.26-.86.61,0,1,2.55.47,2.58,2C269.7,276.37,269.07,276.91,268.1,276.91Z" /> */}
                    <text id="panel-width-text" x="242" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidthMiddle}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidthRight &&
                <>
                    <path d="M321.06,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H304.34s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M327.69,271.27h.77l1.22,4.72,1.34-4.72h.81l1.28,4.71,1.23-4.71h.78l-1.61,5.57h-.82l-1.29-4.48-1.35,4.48h-.81Z" />
                    <path d="M335.8,271.25a.49.49,0,1,1,.5.5A.5.5,0,0,1,335.8,271.25Zm.12,1.21h.73v4.38h-.73Z" />
                    <path d="M339.71,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.07,2.07,0,0,1,339.71,272.39Zm.13.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M343.46,273.06h-.57v-.6h.57v-1.1h.73v1.1h1.14v.6h-1.14v2.58c0,.43.16.59.61.59h.53v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M346.21,270.92h.73v2.17a1.66,1.66,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48A1.18,1.18,0,0,0,348.2,273c-.75,0-1.26.47-1.26,1.41v2.42h-.73Z" />
                    <path d="M352.88,276.91a1.6,1.6,0,0,1-1.79-1.36h.75c0,.43.42.75,1,.75s.89-.29.89-.65c0-1-2.59-.41-2.59-2,0-.67.63-1.22,1.61-1.22a1.46,1.46,0,0,1,1.65,1.37h-.72a.85.85,0,0,0-1-.76c-.53,0-.85.26-.85.61,0,1,2.54.47,2.57,2C354.48,276.37,353.85,276.91,352.88,276.91Z" /> */}
                    <text id="panel-width-text" x="327" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidthRight}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }
        </svg>
    );
};

interface FourPanelsSvgProps extends BasicSvgProps {
    panelWidth1?: number;
    panelWidth2?: number;
    panelWidth3?: number;
    panelWidth4?: number;
}

export const FourPanelsSvg = (props: FourPanelsSvgProps) => {

    return (
        <svg
            id="bkSvg"
            className={props.className}
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={props.viewBox ? `${props.viewBox.x} ${props.viewBox.y} ${props.viewBox.width} ${props.viewBox.height}` : "0 0 500 300"}
            style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
        >
            <defs/>

            <rect x="70.25" y="37.62" width="353.81" height="3.01" />
            <rect x="68.42" y="33.72" width="2.59" height="10.79" />
            <circle cx="64.15" cy="39.02" r="4.1" />
            <rect x="417.58" y="33.72" width="2.59" height="10.79" />
            <circle cx="423.99" cy="39.02" r="4.1" />

            <rect x="247.27" y="53.73" width="79.38" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x="332.06" y="54.31" width="79.38" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x="77.7" y="53.12" width="79.38" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x="162.63" y="53.69" width="79.38" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />

            {!props.hideBorder &&
                <path d="M499,1V299H1V1H499m1-1H0V300H500V0Z" />
            }

            {props.category === 0 && props.rodWidth &&
                <>
                    <path d="M227.93,19.6s-3.55,0-3.55,3.46v7.38a3.42,3.42,0,0,0,3.55,3.46h16.72s3.54,0,3.54-3.46V23.06a3.41,3.41,0,0,0-3.54-3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    <path d="M230,10.93c1.31,0,1.94.73,1.94,1.64a1.58,1.58,0,0,1-1.33,1.6l1.4,2.34h-.87l-1.33-2.28h-.88v2.28h-.73V10.93Zm0,.6h-1.07v2.11H230c.82,0,1.18-.44,1.18-1.07S230.84,11.53,230,11.53Z" />
                    <path d="M235.6,16.56a2.75,2.75,0,0,1-2.8-2.84,2.8,2.8,0,1,1,5.6,0A2.74,2.74,0,0,1,235.6,16.56Zm0-.63a2,2,0,0,0,2.06-2.21,2.06,2.06,0,1,0-4.11,0A2,2,0,0,0,235.6,15.93Z" />
                    <path d="M244.06,13.73a2.66,2.66,0,0,1-3,2.78h-1.74V10.93h1.74A2.68,2.68,0,0,1,244.06,13.73Zm-3,2.18a2,2,0,0,0,2.21-2.18,2,2,0,0,0-2.21-2.2h-1v4.38Z" />
                    <line x1="23.82" y1="25.68" x2="207.07" y2="25.68" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="23.46" y1="28.68" x2="23.38" y2="22.68" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="446.76" y1="25.56" x2="264.51" y2="25.56" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="447.11" y1="22.56" x2="447.2" y2="28.56" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="rod-text" x="237" y={29} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.rodWidth}“</text>
                </>
            }

            {props.isPdf && props.finishedLengthOfPanels &&
                <>
                    <path d="M442.36,164.33s3.54,0,3.54-3.46v-7.38a3.42,3.42,0,0,0-3.54-3.47H425.64s-3.54,0-3.54,3.47v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M450.53,151.58h3.15v.59h-2.42v1.88h2v.59h-2v2.51h-.73Z" />
                    <path d="M455.29,151.58v5h1.95v.59h-2.68v-5.57Z" /> */}
                    <line x1="433.16" y1="54.75" x2="433.16" y2={144} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="430.16" y1="54.39" x2="436.16" y2="54.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="433.16" y1="261.87" x2="433.16" y2="170.62" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="436.16" y1="262.23" x2="430.16" y2="262.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="fl-text" x="444" y={160} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.finishedLengthOfPanels}“
                        <tspan style={{fill: "#000", fontSize: "10px", fontWeight: "200"}}> FL</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth1 &&
                <>
                    <path d="M109.7,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H93s-3.54,0-3.54,3.46v7.38A3.41,3.41,0,0,0,93,282.15Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M116.33,271.27h.77l1.22,4.72,1.34-4.72h.82l1.27,4.71,1.23-4.71h.78l-1.61,5.57h-.82L120,272.36l-1.35,4.48h-.81Z" />
                    <path d="M124.45,271.25a.49.49,0,1,1,1,0,.49.49,0,1,1-1,0Zm.12,1.21h.72v4.38h-.72Z" />
                    <path d="M128.35,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92H130V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.07,2.07,0,0,1,128.35,272.39Zm.13.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M132.1,273.06h-.57v-.6h.57v-1.1h.73v1.1H134v.6h-1.14v2.58c0,.43.16.59.61.59H134v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M134.85,270.92h.73v2.17a1.66,1.66,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48a1.18,1.18,0,0,0-1.22-1.35c-.75,0-1.26.47-1.26,1.41v2.42h-.73Z" />
                    <path d="M141.52,276.91a1.6,1.6,0,0,1-1.79-1.36h.75c0,.43.42.75,1,.75s.89-.29.89-.65c0-1-2.59-.41-2.59-2,0-.67.63-1.22,1.61-1.22a1.46,1.46,0,0,1,1.65,1.37h-.72a.85.85,0,0,0-1-.76c-.53,0-.85.26-.85.61,0,1,2.54.47,2.57,2C143.12,276.37,142.49,276.91,141.52,276.91Z" /> */}
                    <text id="panel-width-text" x="111" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth1}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth2 &&
                <>
                    <path d="M194.63,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H177.91s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M201.26,271.27H202l1.22,4.72,1.34-4.72h.82l1.28,4.71,1.22-4.71h.78l-1.61,5.57h-.82L205,272.36l-1.35,4.48h-.8Z" />
                    <path d="M209.38,271.25a.49.49,0,1,1,1,0,.49.49,0,1,1-1,0Zm.12,1.21h.73v4.38h-.73Z" />
                    <path d="M213.28,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.08,2.08,0,0,1,213.28,272.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M217,273.06h-.56v-.6H217v-1.1h.73v1.1h1.14v.6h-1.14v2.58c0,.43.16.59.61.59h.53v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M219.79,270.92h.72v2.17a1.66,1.66,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58H223v-2.48a1.18,1.18,0,0,0-1.22-1.35c-.75,0-1.26.47-1.26,1.41v2.42h-.72Z" />
                    <path d="M226.46,276.91c-1,0-1.75-.56-1.79-1.36h.75c0,.43.41.75,1,.75s.89-.29.89-.65c0-1-2.59-.41-2.59-2,0-.67.64-1.22,1.61-1.22a1.47,1.47,0,0,1,1.66,1.37h-.73a.84.84,0,0,0-.95-.76c-.54,0-.86.26-.86.61,0,1,2.54.47,2.58,2C228.06,276.37,227.42,276.91,226.46,276.91Z" /> */}
                    <text id="panel-width-text" x="197" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth2}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                    
                </>
            }

            {props.isPdf && props.panelWidth3 &&
                <>
                    <path d="M279.27,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H262.55s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M285.9,271.27h.77l1.22,4.72,1.34-4.72h.82l1.28,4.71,1.22-4.71h.78l-1.61,5.57h-.82l-1.29-4.48-1.34,4.48h-.81Z" />
                    <path d="M294,271.25a.49.49,0,1,1,1,0,.49.49,0,1,1-1,0Zm.12,1.21h.73v4.38h-.73Z" />
                    <path d="M297.92,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.08,2.08,0,0,1,297.92,272.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M301.67,273.06h-.56v-.6h.56v-1.1h.73v1.1h1.14v.6H302.4v2.58c0,.43.16.59.61.59h.53v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M304.43,270.92h.72v2.17a1.78,1.78,0,0,1,3.2,1.17v2.58h-.72v-2.48a1.18,1.18,0,0,0-1.22-1.35c-.74,0-1.26.47-1.26,1.41v2.42h-.72Z" />
                    <path d="M311.1,276.91c-1,0-1.75-.56-1.79-1.36h.75c0,.43.41.75,1,.75s.9-.29.9-.65c0-1-2.6-.41-2.6-2,0-.67.64-1.22,1.61-1.22a1.47,1.47,0,0,1,1.66,1.37h-.73A.84.84,0,0,0,311,273c-.54,0-.86.26-.86.61,0,1,2.55.47,2.58,2C312.7,276.37,312.07,276.91,311.1,276.91Z" /> */}
                    <text id="panel-width-text" x="285" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth3}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth4 &&
                <>
                    <path d="M364.06,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H347.34s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}}/>
                    {/* <path d="M370.69,271.27h.77l1.22,4.72,1.34-4.72h.81l1.28,4.71,1.23-4.71h.78l-1.61,5.57h-.82l-1.29-4.48-1.35,4.48h-.81Z" />
                    <path d="M378.8,271.25a.49.49,0,1,1,.5.5A.5.5,0,0,1,378.8,271.25Zm.12,1.21h.73v4.38h-.73Z" />
                    <path d="M382.71,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.07,2.07,0,0,1,382.71,272.39Zm.13.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M386.46,273.06h-.57v-.6h.57v-1.1h.73v1.1h1.14v.6h-1.14v2.58c0,.43.16.59.61.59h.53v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M389.21,270.92h.73v2.17a1.66,1.66,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48A1.18,1.18,0,0,0,391.2,273c-.75,0-1.26.47-1.26,1.41v2.42h-.73Z" />
                    <path d="M395.88,276.91a1.6,1.6,0,0,1-1.79-1.36h.75c0,.43.42.75,1,.75s.89-.29.89-.65c0-1-2.59-.41-2.59-2,0-.67.63-1.22,1.61-1.22a1.46,1.46,0,0,1,1.65,1.37h-.72a.85.85,0,0,0-1-.76c-.53,0-.85.26-.85.61,0,1,2.54.47,2.57,2C397.48,276.37,396.85,276.91,395.88,276.91Z" /> */}
                    <text id="panel-width-text" x="370" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth4}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }
        </svg>
    );
};

interface FivePanelsSvgProps extends BasicSvgProps {
    panelWidth1?: number;
    panelWidth2?: number;
    panelWidth3?: number;
    panelWidth4?: number;
    panelWidth5?: number;
}

export const FivePanelsSvg = (props: FivePanelsSvgProps) => {

    return (
        <svg
            id="bkSvg"
            className={props.className}
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={props.viewBox ? `${props.viewBox.x} ${props.viewBox.y} ${props.viewBox.width} ${props.viewBox.height}` : "0 0 500 300"}
            style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
        >
            <defs/>

            <rect x="15.25" y="37.62" width="433.81" height="3.01"/>
            <rect x="20.42" y="33.72" width="2.59" height="10.79"/>
            <circle cx="16.15" cy="39.02" r="4.1"/>
            <rect x="448.58" y="33.72" width="2.59" height="10.79"/>
            <circle cx="454.99" cy="39.02" r="4.1"/>

            <rect x="281.03" y="53.73" width="77.96" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x="365.04" y="54.31" width="77.96" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x={29} y="53.12" width="77.96" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x="113.01" y="53.12" width="77.96" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />
            <rect x="197.31" y="53.69" width="77.96" height={props.height || 208} style={{fill: "none", stroke: "#9d9a9a", strokeLinecap: "round", strokeLinejoin: "round"}} />

            {!props.hideBorder &&
                <path d="M499,1V299H1V1H499m1-1H0V300H500V0Z" />
            }

            {props.category === 0 && props.rodWidth &&
                <>
                    <path d="M227.93,19.6s-3.55,0-3.55,3.46v7.38a3.42,3.42,0,0,0,3.55,3.46h16.72s3.54,0,3.54-3.46V23.06a3.41,3.41,0,0,0-3.54-3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    <path d="M230,10.93c1.31,0,1.94.73,1.94,1.64a1.58,1.58,0,0,1-1.33,1.6l1.4,2.34h-.87l-1.33-2.28h-.88v2.28h-.73V10.93Zm0,.6h-1.07v2.11H230c.82,0,1.18-.44,1.18-1.07S230.84,11.53,230,11.53Z" />
                    <path d="M235.6,16.56a2.75,2.75,0,0,1-2.8-2.84,2.8,2.8,0,1,1,5.6,0A2.74,2.74,0,0,1,235.6,16.56Zm0-.63a2,2,0,0,0,2.06-2.21,2.06,2.06,0,1,0-4.11,0A2,2,0,0,0,235.6,15.93Z" />
                    <path d="M244.06,13.73a2.66,2.66,0,0,1-3,2.78h-1.74V10.93h1.74A2.68,2.68,0,0,1,244.06,13.73Zm-3,2.18a2,2,0,0,0,2.21-2.18,2,2,0,0,0-2.21-2.2h-1v4.38Z" />
                    <line x1="23.82" y1="25.68" x2="207.07" y2="25.68" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="23.46" y1="28.68" x2="23.38" y2="22.68" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="446.76" y1="25.56" x2="263.51" y2="25.56" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="447.11" y1="22.56" x2="447.2" y2="28.56" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="rod-text" x="237" y={29} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.rodWidth}“</text>
                </>
            }

            {props.isPdf && props.finishedLengthOfPanels &&
                <>
                    <path d="M471.36,164.33s3.54,0,3.54-3.46v-7.38a3.42,3.42,0,0,0-3.54-3.47H454.64s-3.54,0-3.54,3.47v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M479.53,151.58h3.15v.59h-2.42v1.88h2v.59h-2v2.51h-.73Z" />
                    <path d="M484.29,151.58v5h1.95v.59h-2.68v-5.57Z" /> */}
                    <line x1="462.16" y1="54.75" x2="462.16" y2={144} style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="459.16" y1="54.39" x2="465.16" y2="54.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="462.16" y1="261.87" x2="462.16" y2="170.62" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <line x1="465.16" y1="262.23" x2="459.16" y2="262.31" style={{fill: "none", stroke: "#9d9a9a", strokeWidth: "0.5px"}} />
                    <text id="fl-text" x="472" y={160} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: `${props.finishedLengthOfPanels.toString().length >= 7 ? 9 : 12}px`, textAnchor: "middle"}}>
                        {props.finishedLengthOfPanels}“
                        <tspan style={{fill: "#000", fontSize: `${props.finishedLengthOfPanels.toString().length >= 7 ? 8 : 10}px`, fontWeight: "200"}}> FL</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth1 &&
                <>
                    <path d="M58.36,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H41.64s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M65,271.27h.77L67,276l1.34-4.72h.82L70.42,276l1.22-4.71h.78l-1.61,5.57H70l-1.29-4.48-1.35,4.48h-.8Z" />
                    <path d="M73.11,271.25a.49.49,0,1,1,1,0,.49.49,0,1,1-1,0Zm.12,1.21H74v4.38h-.73Z" />
                    <path d="M77,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.08,2.08,0,0,1,77,272.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61A1.49,1.49,0,1,0,77.15,273Z" />
                    <path d="M80.76,273.06H80.2v-.6h.56v-1.1h.73v1.1h1.14v.6H81.49v2.58c0,.43.16.59.61.59h.53v.61H82c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M83.52,270.92h.72v2.17a1.66,1.66,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48A1.18,1.18,0,0,0,85.5,273c-.75,0-1.26.47-1.26,1.41v2.42h-.72Z" />
                    <path d="M90.19,276.91c-1,0-1.75-.56-1.79-1.36h.75c0,.43.41.75,1,.75s.89-.29.89-.65c0-1-2.59-.41-2.59-2,0-.67.64-1.22,1.61-1.22a1.47,1.47,0,0,1,1.66,1.37H91a.84.84,0,0,0-1-.76c-.54,0-.86.26-.86.61,0,1,2.54.47,2.58,2C91.79,276.37,91.15,276.91,90.19,276.91Z" /> */}
                    <text id="panel-width-text" x="64" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth1}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth2 &&
                <>
                    <path d="M142.39,282.15s3.55,0,3.55-3.46v-7.38a3.42,3.42,0,0,0-3.55-3.46H125.67s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M149,271.27h.78L151,276l1.35-4.72h.81l1.28,4.71,1.22-4.71h.79l-1.62,5.57H154l-1.3-4.48-1.34,4.48h-.81Z"/>
                    <path d="M157.14,271.25a.49.49,0,1,1,.5.5A.5.5,0,0,1,157.14,271.25Zm.12,1.21H158v4.38h-.73Z"/>
                    <path d="M161,272.39a1.93,1.93,0,0,1,1.63.85v-2.32h.73v5.92h-.73V276a1.8,1.8,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.07,2.07,0,0,1,161,272.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z"/>
                    <path d="M164.8,273.06h-.57v-.6h.57v-1.1h.72v1.1h1.15v.6h-1.15v2.58c0,.43.16.59.61.59h.54v.61H166c-.77,0-1.21-.32-1.21-1.2Z"/>
                    <path d="M167.55,270.92h.73v2.17a1.64,1.64,0,0,1,1.44-.71,1.69,1.69,0,0,1,1.76,1.88v2.58h-.72v-2.48a1.18,1.18,0,0,0-1.23-1.35c-.74,0-1.25.47-1.25,1.41v2.42h-.73Z"/>
                    <path d="M174.22,276.91a1.6,1.6,0,0,1-1.79-1.36h.75c0,.43.42.75,1,.75s.9-.29.9-.65c0-1-2.59-.41-2.59-2,0-.67.63-1.22,1.6-1.22a1.47,1.47,0,0,1,1.66,1.37h-.73a.84.84,0,0,0-1-.76c-.54,0-.85.26-.85.61,0,1,2.54.47,2.57,2C175.82,276.37,175.19,276.91,174.22,276.91Z"/> */}
                    <text id="panel-width-text" x="148" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth2}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>

                    </text>
                </>
            }

            {props.isPdf && props.panelWidth3 &&
                <>
                    <path d="M226.43,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H209.71s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M233.06,271.27h.77l1.22,4.72,1.34-4.72h.81l1.28,4.71,1.23-4.71h.78l-1.61,5.57h-.82l-1.29-4.48-1.35,4.48h-.81Z"/>
                    <path d="M241.17,271.25a.49.49,0,1,1,.5.5A.5.5,0,0,1,241.17,271.25Zm.12,1.21H242v4.38h-.73Z"/>
                    <path d="M245.08,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.07,2.07,0,0,1,245.08,272.39Zm.13.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z"/>
                    <path d="M248.83,273.06h-.57v-.6h.57v-1.1h.73v1.1h1.14v.6h-1.14v2.58c0,.43.16.59.61.59h.53v.61h-.65c-.78,0-1.22-.32-1.22-1.2Z"/>
                    <path d="M251.58,270.92h.73v2.17a1.66,1.66,0,0,1,1.45-.71,1.69,1.69,0,0,1,1.75,1.88v2.58h-.72v-2.48a1.18,1.18,0,0,0-1.22-1.35c-.75,0-1.26.47-1.26,1.41v2.42h-.73Z"/>
                    <path d="M258.25,276.91a1.6,1.6,0,0,1-1.79-1.36h.75c0,.43.42.75,1,.75s.89-.29.89-.65c0-1-2.59-.41-2.59-2,0-.67.63-1.22,1.61-1.22a1.47,1.47,0,0,1,1.65,1.37h-.73a.84.84,0,0,0-.95-.76c-.53,0-.85.26-.85.61,0,1,2.54.47,2.57,2C259.85,276.37,259.22,276.91,258.25,276.91Z"/> */}
                    <text id="panel-width-text" x="232" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth3}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth4 &&
                <>
                    <path d="M310.46,282.15s3.54,0,3.54-3.46v-7.38a3.41,3.41,0,0,0-3.54-3.46H293.74s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M317.09,271.27h.77l1.22,4.72,1.34-4.72h.82l1.28,4.71,1.22-4.71h.78l-1.61,5.57h-.82l-1.29-4.48-1.34,4.48h-.81Z" />
                    <path d="M325.21,271.25a.49.49,0,1,1,1,0,.49.49,0,1,1-1,0Zm.12,1.21h.73v4.38h-.73Z" />
                    <path d="M329.11,272.39a1.9,1.9,0,0,1,1.62.85v-2.32h.74v5.92h-.74V276a1.78,1.78,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.08,2.08,0,0,1,329.11,272.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M332.86,273.06h-.56v-.6h.56v-1.1h.73v1.1h1.15v.6h-1.15v2.58c0,.43.16.59.61.59h.54v.61h-.66c-.78,0-1.22-.32-1.22-1.2Z" />
                    <path d="M335.62,270.92h.72v2.17a1.78,1.78,0,0,1,3.2,1.17v2.58h-.72v-2.48A1.18,1.18,0,0,0,337.6,273c-.74,0-1.26.47-1.26,1.41v2.42h-.72Z" />
                    <path d="M342.29,276.91c-1,0-1.75-.56-1.79-1.36h.75c0,.43.41.75,1,.75s.9-.29.9-.65c0-1-2.6-.41-2.6-2,0-.67.64-1.22,1.61-1.22a1.47,1.47,0,0,1,1.66,1.37h-.73a.84.84,0,0,0-.95-.76c-.54,0-.86.26-.86.61,0,1,2.55.47,2.58,2C343.89,276.37,343.26,276.91,342.29,276.91Z" /> */}
                    <text id="panel-width-text" x="316" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth4}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }

            {props.isPdf && props.panelWidth5 &&
                <>
                    <path d="M394.49,282.15s3.55,0,3.55-3.46v-7.38a3.42,3.42,0,0,0-3.55-3.46H377.77s-3.54,0-3.54,3.46v7.38a3.41,3.41,0,0,0,3.54,3.46Z" style={{fill: "none", strokeWidth: "0.5px"}} />
                    {/* <path d="M401.12,271.27h.78l1.21,4.72,1.35-4.72h.81l1.28,4.71,1.22-4.71h.79l-1.62,5.57h-.81l-1.3-4.48-1.34,4.48h-.81Z" />
                    <path d="M409.24,271.25a.49.49,0,1,1,.5.5A.5.5,0,0,1,409.24,271.25Zm.12,1.21h.73v4.38h-.73Z" />
                    <path d="M413.14,272.39a1.93,1.93,0,0,1,1.63.85v-2.32h.73v5.92h-.73V276a1.8,1.8,0,0,1-1.63.89,2.11,2.11,0,0,1-2.08-2.28A2.07,2.07,0,0,1,413.14,272.39Zm.14.63a1.48,1.48,0,0,0-1.48,1.61,1.49,1.49,0,1,0,1.48-1.61Z" />
                    <path d="M416.9,273.06h-.57v-.6h.57v-1.1h.72v1.1h1.15v.6h-1.15v2.58c0,.43.17.59.61.59h.54v.61h-.66c-.77,0-1.21-.32-1.21-1.2Z" />
                    <path d="M419.65,270.92h.73v2.17a1.65,1.65,0,0,1,1.44-.71,1.69,1.69,0,0,1,1.76,1.88v2.58h-.72v-2.48a1.18,1.18,0,0,0-1.23-1.35c-.74,0-1.25.47-1.25,1.41v2.42h-.73Z" />
                    <path d="M426.32,276.91a1.6,1.6,0,0,1-1.79-1.36h.75c0,.43.42.75,1,.75s.9-.29.9-.65c0-1-2.59-.41-2.59-2,0-.67.63-1.22,1.61-1.22a1.47,1.47,0,0,1,1.65,1.37h-.73a.84.84,0,0,0-.95-.76c-.53,0-.85.26-.85.61,0,1,2.54.47,2.57,2C427.92,276.37,427.29,276.91,426.32,276.91Z" /> */}
                    <text id="panel-width-text" x="400" y={278} width={0} height={0} style={{fill: "rgb(59, 151, 177)", fontFamily: "Poppins", fontSize: "12px", textAnchor: "middle"}}>{props.panelWidth5}
                        <tspan style={{fill: "#000", fontSize: "8px", fontWeight: "200" }}> Widths</tspan>
                    </text>
                </>
            }
        </svg>
    );
};

export const BlankSvg = (props: BasicSvgProps) => {

    return (
        <svg id="bkSvg" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 300" style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}>
            {!props.hideBorder &&
                <path d="M499,1V299H1V1H499m1-1H0V300H500V0Z" />
            }
        </svg>
    );
};

// Roman Sade 
export const NoValanceNoReturnsWire = (props: any) =>  { 
    return (
        <svg width="242" height="362" viewBox="0 0 242 362" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", width: "90%", height: "90%", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <path d="M11 21L1 11V1M1 1L11 11M1 1H231L241 11M11 11V361H241V11M11 11H241" stroke="black"/>
        </svg>
    )
}
export const ValanceNoReturnsWire = (props: any) => { 
    return ( 
        <svg width="242" height="362" viewBox="0 0 242 362" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", width: "90%", height: "90%", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <path d="M11 21L1 11V1M11 21V361H241V11M11 21V11M1 1L11 11M1 1H231L241 11M11 11H241M11 91H241" stroke="black" />
        </svg>
    )
}
export const ValanceReturnsWire = (props: any) => { 
    return ( 
        <svg width="242" height="362" viewBox="0 0 242 362" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", width: "90%", height: "90%", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <path d="M11 91L1 81V1M11 91V361H241V11M11 91V11M11 91H241M1 1L11 11M1 1H231L241 11M11 11H241" stroke="black"/>
        </svg>
    )
}
export const NoValanceReturnsWire = (props: any) => { 
    return ( 
        <svg width="242" height="362" viewBox="0 0 242 362" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", width: "90%", height: "90%", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
            <path d="M11 91L1 81L1 1M1 1L11 11M1 1H231L241 11M11 11V361H241V11M11 11H241" stroke="black"/>
        </svg>
    )
}
export const FlatRomanShade = () => { 
    return (
    <svg width="82" height="72" viewBox="0 0 82 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M80.4838 48.2537C80.4102 47.4977 80.2822 44.3197 80.2854 43.8577C80.3142 38.2156 80.3366 1 80.2854 1H1.60418C1.55298 1 1.57538 38.2156 1.60418 43.8752C1.60418 44.3372 1.50818 47.5852 1.40578 48.2712C1.20419 49.5907 0.647419 52.9052 1.32899 53.6647C1.71617 54.0777 3.07931 54.0777 3.07931 54.0777L40.9368 53.9622L79.5975 54.0777C80.0096 54.0779 80.4098 53.9263 80.7334 53.6472C81.4022 52.8877 80.6182 49.5627 80.4838 48.2537Z" stroke="#6B6B6B" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M79.876 54.0256C79.9912 54.5716 80.1064 55.1701 80.1575 55.5551C80.2855 56.5596 80.6311 59.0937 79.9688 59.6747C79.5976 60.0037 78.2888 60.0037 78.2888 60.0037L40.9209 59.9162L3.55619 60.0107C3.55619 60.0107 2.25064 60.0107 1.87626 59.6817C1.21709 59.1007 1.55628 56.5666 1.69067 55.5621C1.73867 55.1771 1.85706 54.5786 1.96906 54.0326" stroke="#6B6B6B" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M79.8756 59.7438C79.9908 60.2128 80.106 60.7238 80.1572 61.0528C80.2852 61.9068 80.6308 64.0698 79.9684 64.5528C79.5972 64.8363 78.2885 64.8363 78.2885 64.8363L40.9206 64.7593L3.55265 64.8363C3.55265 64.8363 2.2471 64.8363 1.87272 64.5528C1.21355 64.0593 1.55274 61.8963 1.68713 61.0528C1.73513 60.7238 1.85352 60.2128 1.96552 59.7438" stroke="#6B6B6B" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M79.876 64.581C79.9912 65.1655 80.1064 65.813 80.1575 66.226C80.2855 67.3005 80.6311 70.02 79.9688 70.6465C79.5976 70.9965 78.2888 70.9965 78.2888 70.9965L40.9209 70.902L3.55619 71C3.55619 71 2.25064 71 1.87626 70.65C1.21709 70.0235 1.55628 67.304 1.69067 66.2295C1.73867 65.8165 1.85706 65.1795 1.96906 64.5845" stroke="#6B6B6B" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
    )
}
export const RelaxedRomanShade = () => { 
    return (
        <svg width="82" height="72" viewBox="0 0 82 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M80.4883 43.1617C80.4147 42.4882 80.2867 39.6535 80.2899 39.2396C80.3187 34.2053 80.3411 1 80.2899 1H1.60421C1.55301 1 1.57541 34.1947 1.60421 39.2396C1.60421 39.6535 1.50821 42.5478 1.40581 43.1617C1.20421 44.337 0.647399 47.2944 1.32901 47.9715C1.71621 48.3574 3.06663 48.3574 3.06663 48.3574C3.06663 48.3574 31.4605 52.3743 40.9263 52.3743C50.5936 52.3743 79.5891 48.3574 79.5891 48.3574C79.9948 48.3589 80.3913 48.2243 80.7251 47.9715C81.4067 47.2944 80.6227 44.337 80.4883 43.1617Z" stroke="#6B6B6B" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M79.8803 48.3152C79.9955 48.9045 80.1107 49.5536 80.1619 49.9675C80.2899 51.0516 80.6355 53.7845 79.9731 54.4124C79.6019 54.7633 78.2931 54.7633 78.2931 54.7633C78.2931 54.7633 50.2672 58.8363 40.9231 58.8363C31.579 58.8363 3.55624 54.7703 3.55624 54.7703C3.55624 54.7703 2.25063 54.7703 1.87622 54.4195C1.21702 53.7915 1.55622 51.0586 1.69062 49.9745C1.73862 49.5606 1.85702 48.9221 1.96902 48.3222" stroke="#6B6B6B" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M79.8803 54.5882C79.9955 55.1776 80.1107 55.8266 80.1619 56.2441C80.2899 57.3246 80.6355 60.0575 79.9731 60.6855C79.6019 61.0363 78.2931 61.0363 78.2931 61.0363C78.2931 61.0363 49.528 64.8638 40.1999 64.8638C30.8718 64.8638 3.55624 61.0433 3.55624 61.0433C3.55624 61.0433 2.25063 61.0433 1.87622 60.6925C1.21702 60.0645 1.55622 57.3316 1.69062 56.2511C1.73862 55.8336 1.85702 55.1986 1.96902 54.5952" stroke="#6B6B6B" stroke-miterlimit="10" stroke-linecap="round"/>
        <path d="M79.8803 60.8613C79.9955 61.4506 80.1107 62.0997 80.1619 62.5171C80.2899 63.5977 80.6355 66.3341 79.9731 66.9621C79.6019 67.3129 78.2931 67.3129 78.2931 67.3129C78.2931 67.3129 50.2704 71 40.9263 71C31.5822 71 3.55624 67.3164 3.55624 67.3164C3.55624 67.3164 2.25063 67.3164 1.87622 66.9656C1.21702 66.3376 1.55622 63.6012 1.69062 62.5207C1.73862 62.1032 1.85702 61.4682 1.96902 60.8648" stroke="#6B6B6B" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
    )
}