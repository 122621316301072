import { Omit } from "../../../../types/index";
import { ActionDescriptor } from "../../common";
import {
  DraperyOrderUpload,
  ChangeableDraperyOrderUpload,
} from "./interfaces";

export const ADD_DRAPERY_ORDER_UPLOAD = "draperyOrderUpload/ADD";
export type AddDraperyOrderUploadAction = ActionDescriptor<
  typeof ADD_DRAPERY_ORDER_UPLOAD,
  Omit<DraperyOrderUpload, "id"> & { accountId: number },
  DraperyOrderUpload
>;

export const EDIT_DRAPERY_ORDER_UPLOAD = "draperyOrderUpload/EDIT";
export type EditDraperyOrderUploadAction = ActionDescriptor<
  typeof EDIT_DRAPERY_ORDER_UPLOAD,
  ChangeableDraperyOrderUpload & { id: number },
  {}
>;

export interface DraperyOrderUploadActionsMap {
  [ADD_DRAPERY_ORDER_UPLOAD]: AddDraperyOrderUploadAction;
  [EDIT_DRAPERY_ORDER_UPLOAD]: EditDraperyOrderUploadAction;
}
